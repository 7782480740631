export default {
  en: {
    'action.tags.text': 'Edit tags',
    'action.tags.tooltip': 'Edit tags in selected documents',

    'menu.header.about': 'About Us',
    'menu.header.help': 'Contact Us',
    'menu.header.contact': 'Contact',
    'menu.header.validation': 'Validation',
    'menu.header.know_pro': 'weetrust PRO',
    'menu.footer.privacy': 'Privacy Policy',
    'menu.footer.terms': 'Terms and conditions',
    forgot_password: 'Forgot Password',
    forgot_password_message: 'Enter your email to send an email of forgot password.',
    'forgot_password.success.message': 'We have sent an email to reset your password',
    'click_here.message': 'try again',
    forgot_password_email_sent: 'Email sent',
    'not_received.message': 'If you have not received the verification email yet ',
    'userTable.header.name': 'Name',
    'userTable.header.email': 'Email',
    'userTable.header.password': 'Password',
    login: 'Sign In',
    'login.or': 'or',
    'login.email': 'E-mail',
    'profile.subscription.text': 'Available with PRO',
    'login.fullName': 'User Name',
    'login.password': 'Password',
    'login.submit': 'Sign In',
    'login.forgot_password': 'Forgot your password?',
    'login.no_account': "Don't have an account yet? ",
    'login.sign_up': 'SIGN UP',
    'login.recover_password': 'Recover password?',
    'login.recover_password.send': 'Send',
    'login.weesign_pro.already_know': 'Get to know',
    'login.weesign_pro.imporve_experience': 'Improve your experience at weetrust, get PRO',
    'login.weesign_pro.blockchain_integration': '3 users',
    'login.weesign_pro.customize_with_your_logo': '100 documents',
    'login.weesign_pro.id_validation': 'e.signature (SAT)',
    'login.weesign_pro.unlimited_users': 'Advanced Electronic Signature',
    'login.weesign_pro.unlimited_documents': 'Official Identification',
    'login.weesign_pro.blockchain': 'Blockchain',
    'login.weesign_pro.NOM_151': '-NOM 151 Compliant',
    'login.weesign_pro.more_information': 'More Information about ',
    'login.weesign_pro.button.i_want_to_join': 'I want to join',
    'login.wrong.credencials': 'User or password are invalid',
    'login.invalid_password': 'The password is invalid.',
    'login.user_not_found': 'There is no user record corresponding to this identifier. The user may have been deleted.',
    'login.email_not_verified': 'To continue, please verify your email first. ',
    'login.fill_out_fields': 'Please fill out this field.',
    'login.too_many_request': 'Too many invalid attempt. Please verify re-captcha or try again later',
    register: 'Create your account',
    'register.full_name': 'Name and Surname',
    'register.first_name': 'First name',
    'register.last_name': 'Last name',
    'register.mothers_last_name': 'Second last name',
    'register.birth_date': 'Date of birth',
    'register.mob': 'Phone',
    'register.email': 'Email',
    'register.password': 'New Password',
    'register.confirm_password': 'Confirm Password',
    'register.submit': 'Create account',
    'register.i_accept': 'By registering up I accept the ',
    'register.terms_and_condition': 'terms and conditions ',
    'register.and': 'and ',
    'register.privacy_notice': 'Privacy Policy ',
    'register.from_weesign': 'from weetrust',
    'register.already_account': 'Do you already have an account?',
    'register.password_didnt_match': "Password and confirm password didn't match. Try again.",
    'register.password_didnt_empty': "Password can't be empty. Try again.",
    'profile.last_and_new_password_not_same': 'Last password and new password can not be same',
    'profile.change_password_fail': 'old password not matched, Please Try again! ',
    'profile.change_password_error': 'Must contain at least one numeric, one uppercase, and 8 characters',
    'login.password_error': 'Enter your password',
    'profile.fill_field_message': 'Please fill out this field',
    'register.success.heading': 'Welcome to weetrust',
    'register.success.message': 'We send an email to activate your account,',
    'register.success.message.welcome': 'Confirm Your Email Address',
    'register.success.message.welcome.description': 'We\'ve sent a confirmation email to you at:',
    'register.verify_email.success.heading': 'Mail verification',
    'register.verify_email.success.message': 'Your account has been activated!',
    'register.verify_email.failure.message': 'Link has been expired, or has already been used!',
    'register.verify_email.resend.message': 'Email has been sent to your email ID!',
    'register.password.strength.message': 'Must contain:',
    'register.password.strength.number': 'One number',
    'register.password.strength.uppercase': 'One uppercase',
    'register.password.strength.lowercase': 'One lowercase',
    'register.password.strength.min': 'At least 8 characters',
    'register.title': 'Easy registration, immediate reward',
    'register.promo': '5 free documents',

    'reset.password.heading': 'Reset Password',
    'reset.password.subheading': 'Enter a new',
    'reset.password.success': 'Congratulations, your password has been reset!',
    'change.password.success': 'Congratulations, your password has been changed!',
    'reset.payment.password.heading': 'Generate password',
    'reset.payment.password.subheading': 'Capture password',
    'reset.payment.password.success': 'Congratulations, your password has been saved!',
    'reset.button': 'Confirm',
    'reset.password.failure': 'Failed attempt. Contact with Weetrust Team',
    'recaptcha.required': 'Please check the box',

    'home.know_more': 'More information',
    'home.heading': 'The most secure and user-friendly advanced e-signature platform',
    'home.content': 'weetrust combines advanced electronic signature with blockchain technology to create unique, unforgeable and valid in court documents',
    'home.dragbox.heading': 'With the implementation of blockchain and advanced electronic signature, each document signed in weetrust becomes unique, unrepeatable, unforgeable and valid before courts',
    'home.box.select_file': 'Select a file',
    'home.box.drag_document': 'Drag and drop or select from your device any .pdf or .docx file to upload',
    'home.box.file_ready': 'Do you need to sign something today?',
    'home.button.sign_now': 'Sign document',

    'home.advice.header': 'Your browser is not supported',
    'home.advice.content': 'We recommend using',
    'home.advice.content.1': 'weetrust',
    'home.advice.content.2': 'with one of the following browsers:',

    'signing_authority.country': 'Country',
    'signing_authority.who_sign': 'Who signs?',
    'signing_authority.or': 'Or',
    'signing_authority.only_me': 'Only Me',
    'signing_authority.me_and_others': 'Others and me',
    'signing_authority.others': 'Others',
    'signing_authority.select_type_of_sign': 'Select the type of signature',
    'signing_authority.electronic_signature': 'Electronic signature',
    'signing_authority.e.firma': 'e.signature',
    'signing_authority.country.notice': 'Please select the country where you want your document to take effect.',
    'signing_authority.country.notice.mx': 'By selecting Mexico, you will receive your certificate issued by an authorized PSC.',
    'signing_authority.type.tooltip': 'Selecciona el tipo de firma con que se completará el documento',
    'profile.tab.documents': 'Documents',
    'profile.tab.endorsements': 'Endorsements',
    'profile.tab.massive.signature': 'Massive Signature',
    'profile.tab.massive.signature.endorsements': 'Massive Endorsements',
    'profile.tab.templates': 'Templates',
    'profile.tab.profile': 'Profile',
    'profile.tab.billing': 'Billing',
    'profile.tab.help_and_support': 'Help',
    'profile.tab.validation': 'Validation',
    'profile.tab.users': 'Users',
    'profile.tab.config': 'Settings',
    'profile.tab.invoice': 'invoice',
    'profile.page.signature': 'Signatures',
    'profile.page.personal_information': 'Personal information',
    'profile.page.document': 'Signature Settings',
    'profile.page.UserId.ApiKey': 'User ID & API Key',
    'profile.page.generaqte_ApiKey': 'Generate API Key',
    'profile.page.UserId': 'User ID',
    'profile.page.ApiKey': 'API Key',
    'profile.page.button.save_efirma': 'Save e.signature',
    'profile.page.button.add_signature': 'Save signature',
    'profile.page.button.generate_apikey': 'Generate API Key',
    'profile.page.button.download_apikey': 'Download .CSV',
    'profile.page.button.regenerate_apikey': 'Regenerate API Key',
    'profile.page.signature.miguel_angel_centeno': 'Miguel Angel Centeno',
    'profile.page.signature.edit_signature': 'Modify signature',
    'profile.page.details.birth_date': 'Date of birth',
    'profile.page.details.cell_phone': 'Mobile',
    'profile.page.change_password': 'Change password',
    'profile.page.change_DOB': 'Change DOB',
    'profile.page.change_phone': 'Change Mobile',
    'profile.page.change_firm': 'Change Firm',
    'profile.page.change_industry': 'Change Industry',
    'profile.page.change_activity': 'Change Activity',
    'profile.page.change_mail': 'Change email',
    'profile.page.company': 'Company',
    'profile.page.industry': 'Industry',
    'profile.page.occupation': 'Occupation',
    'profile.page.what_is_this': 'What is this?',
    'profile.page.button.upload_file': 'Upload File',
    'profile.page.button.save': 'Save',
    'profile.page.official_identification': 'Official identification',
    'profile.page.users': 'Users',
    'profile.change_password.title': 'CHANGE PASSWORD',
    'profile.change_password.last_password': 'Last password',
    'profile.change_password.new_password': 'New password',
    'profile.change_password.confirm_password': 'Confirm password',
    'corporate.search_title': 'Search By Email Address',
    'profile.change_password.confirm_new_password': 'Confirm password',
    'profile.button.save': 'Save',
    'profile.companyName': 'Enter Firm Name',
    'profile.activity': 'Enter Activity Name',
    'profile.industry': 'Enter Industry Name',
    'profile.dob': 'Enter DOB',
    'profile.add.signature.limit.error': 'You have reached the limit of signatures.',
    'document.error.limit_max_text': 'The search must have more than 3 characters.',
    'document.page.add_document': 'Add document',
    'document.page.add_document.loading': 'Please wait while we process and upload your document',
    'document.page.add_document.loading_biometric': 'Please wait while we process biometrics and upload your document',
    'document.page.add_document.loading_biometric_background': 'Please wait while we process biometrics,  consult RENAPO, INE, Black lists and upload your document',
    'document.page.heading.all': 'All',
    'document.page.heading.pending_signature': 'Pending signature',
    'document.page.heading.draft': 'Drafts',
    'document.page.heading.completed': 'Completed',
    'document.page.heading.shared': 'Shared',
    'document.status.heading.pending_signature': 'Pending',
    'document.status.heading.draft': 'Draft',
    'document.status.heading.completed': 'Completed',
    'document.status.heading.shared': 'Shared',
    'document.status.heading.pendingToEndorsementValidation': 'Validation pending',
    'document.status.heading.pendingToEndorsement': 'Endorsement pending',
    'document.status.heading.endorsed': 'Endorsed',
    'document.status.heading.refused': 'Refused',
    'document.status.heading.canceled': 'Canceled',
    'document.page.heading.search': 'Search',
    'document.page.dropdown.sign': 'Sign',
    'document.page.dropdown.tags': 'Tags',
    'document.page.dropdown.tags_all': 'All',
    'document.page.dropdown.templates': 'Templates',
    'document.page.dropdown.templates_available': 'Available in',
    'document.page.dropdown.templates_pro': 'PRO',
    'document.page.dropdown.create_tag': 'Create tag',
    'document.page.dropdown.resend': 'Resend',
    'document.page.dropdown.resendWhatsapp': 'Resend whatsapp',
    resend: 'resend',
    'document.page.dropdown.remove': 'Delete',
    'document.page.dropdown.share': 'Share',
    'document.page.dropdown.download': 'Download',
    'document.page.dropdown.download.psc': 'PSC Certificate',
    'document.page.dropdown.download.psc-pdf': 'PSC Constancy',
    'document.page.dropdown.download.ratification': 'Ratified Document',
    'document.page.dropdown.download.ratificationZip': 'Download Ratification',
    'document.page.dropdown.download.ratificationGuide': 'Registration in the RUG',
    'document.page.dropdown.download.blockchain': 'Blockchain Certificate',
    'document.page.dropdown.remind': 'Remind',
    'document.page.dropdown.psc': 'PSC',
    'document.page.dropdown.blockchain': 'Blockchain',
    'document.page.dropdown.cancel': 'Request cancellation',
    'document.page.dropdown.cancel.owner': 'Cancel',
    'document.page.dropdown.endorsement.special': 'Special endorsement',
    'document.page.dropdown.endorsement.cancel': 'Cancel endorsement',
    'document.upload.file': 'Drag and drop or select from your device any .pdf or .docx file to upload',
    'document.page.button.sign': 'Sign',
    'preview.remind.title': 'Do you need reminders on this document?',
    'preview.file.field.sign': 'Signature',
    'preview.file.field.date': 'Date',
    'preview.file.field.text': 'Text',
    'preview.file.field.checkbox': 'Checkmark',
    'preview.file.cancel': 'Cancel',
    'preview.file.button.following': 'Next',
    'preview.button.text.send': 'Send',
    'preview.file.field.request_signature': 'Fix signatures',
    'preview.file.field.ratification': 'Documet for Ratification',
    'preview.file.field.btn.request_signature': 'Request signatures',
    'inbox.list.signer.info.view_document': 'View Result',
    'inbox.list.signer.info.yes': 'Yes',
    'inbox.list.signer.info.no': 'No',
    'preview.file.field.add_signature.box': 'Signatory',
    'preview.file.field.add_signature.box.form': 'Required form',
    'preview.file.field.add_signature': 'Add signatures',
    'preview.file.field.add_observer': 'Add observer',
    'preview.file.field.add_split': 'Split document',
    'document.split.static.sign': 'Dividir un documento requiere que exista al menos un "fija firma" en cada parte del documento',
    'preview.file.field.document': 'Document',
    'preview.file.field.pages': 'Pages',
    'preview.file.field.message': 'Message (optional)',
    'preview.file.field.title_mail': 'Title of the mail',
    'preview.file.field.generate_link': 'Generate link',
    'preview.file.field.signatory_name': 'Signatory name',
    'preview.file.field.signatory_email': 'Email',
    'preview.file.thanks.popup.document_sent': 'Your document has been sent',
    'preview.file.thanks.popup.document_completed': 'Your document has been processed',
    'preview.file.thanks.biometric_message': 'The signing process may take some time due to biometrics or ID validation.',
    'preview.file.thanks.message': 'Thank you!',
    'preview.file.thanks.help.tree': 'By signing with weetrust instead of signing on paper, you are contributing to the conservation of the environment.',
    'preview.file.thanks.signatory': 'When everyone has signed, you will receive a PDF copy in your registered email',
    'preview.file.thanks.signatory.signUp': 'Did you like weetrust?',
    'preview.file.thanks.signatory.signUp.btn': 'Sign up free',
    'preview.file.field.emptyEmailStaticSign': 'Please enter email',
    'preview.file.field.orderSign': 'Order of Signers',
    'preview.file.field.orderSignDetail': 'Emails will be sent in the order assigned',
    'preview.file.document.processing': 'Processing document',
    'preview.file.field.edited': 'Edited',
    'preview.file.field.removed': 'Deleted',
    'preview.file.field.restore': 'Restore',

    'preview.remind.header.add': 'Add',
    'preview.remind.body.readMore': 'Read more',
    'preview.remind.body.readLess': 'Read less',

    'preview.file.no.signatory': 'Please add signatory',
    'preview.file.fixed.signatory.missing.emails': 'Please fixed the signatory for all invitees',
    'preview.file.signatory.name_and_email.missing': 'Please enter signatory name and email',
    'preview.file.signatory.name.missing': 'Please enter signatory name',
    'preview.file.signatory.email.missing': 'Please enter signatory email',
    'preview.file.signatory.email.repeated': 'Please enter an other signatory email, this one already exist',
    'preview.file.signatory.email.invalid': 'Please enter a valid email address.',
    'preview.file.signatory.email.valid': 'Please enter a valid signatory email',
    'preview.file.signatory.whatsapp.missing': 'Please enter signatory whatsapp',
    'preview.file.signatory.whatsapp.invalid': 'Please enter a valid whatsapp',
    'preview.file.same_signatory_and_owner.email': 'signatory and owner email id is same',
    'preview.file.missing.mail.title': 'Please enter title of the mail',
    'preview.file.loading_document': 'Loading document',
    'preview.hint.menu': '*click on menu to sign document',
    'subscription.header': 'Choose a plan',
    'subscription.sub.header': 'Transparent prices without additional costs. ',
    'subscription.tag': 'Quick and secure signature',
    'subscription.per_month': 'Per month',
    'subscription.per_annual': 'Per annual',
    'subscription.free.limited.period': 'Free for limited period',
    'subscription.free': 'Free',
    'subscription.free_plan': 'FREE',
    'subscription.monthly': 'MONTHLY',
    'subscription.annual': 'ANNUAL',
    'subscription.or.symbol': '|',
    'subscription.go_green.advance_signature': 'Advanced electronic signature',
    'subscription.go_green.one_picture': '1 picture',

    'subscription.go_green.ten_per_month': '10 document per month',


    'subscription.officially_green.advance_electronic_signature': 'Advanced electronic signature',
    'subscription.officially_green.picture_voice_notes': 'Picture or voice note',
    'subscription.officially_green.three_user': '3 users',
    'subscription.officially_green.fifty_document': '50 documents per month',

    'subscription.pro.add_log_or_profile': 'Add your logo and customize your profile',
    'subscription.pro.advance_electronic_signature': 'Advanced electronic signature',
    'subscription.pro.picture_voice_notes': 'Picture or voice note',
    'preview.file.add.signatory.sign': 'Click to set signature on the document',

    'subscription.pro.hundred_document': '100 documents per month',
    'subscription.user': ' user',
    'subscription.users': ' users',
    'subscription.documents': ' documents',
    'subscription.e_signature': ' e.signature SAT',
    'subscription.blockchain': ' Blockchain',
    'subscription.official.identification': ' Official Identification',
    'subscription.access.code': ' Access code',
    'subscription.advance_electronic_signature': ' Advance electronic signature',
    'subscription.corporate.more_documents': 'You need more documents?',
    'subscription.corporate.call_us': 'Schedule a call with us to activate a customized package',
    'subscription.button.contact': 'Contact',
    'subscription.button.continue': 'Continue',
    'subscription.button.request_call': 'Request a call',
    'subscription.footer.want_one_document': 'You only want to sign one document?',
    'subscription.footer.try_free': 'TRY FREE',
    'subscription.footer.pay_per_document': 'PAY PER DOCUMENT',
    'subscription.start': 'Start',
    'subscription.month': 'month',
    'subscription.days': 'days',
    'subscription.select.payment.method': 'Select payment method',
    'subscription.promotional.price': 'Promotional price',
    'subscription.month.annual.payment': 'per annual.',
    'subscription.month.monthly.payment': 'per month,',
    'subscription.footer.faq': 'FREQUENTLY ASKED QUESTIONS',
    'subscription.footer.best.plan': 'You don`t know which plan is the best for you? ',
    'subscription.footer.chat.btn': 'CHAT',
    'subscription.footer.chat': ' with us.',
    'signature.popup.button.save': 'Save',
    'signature.popup.button.select': 'Select',
    'signature.poup.no_signature.added': 'No Signature added yet',
    'signature.popup.button.delete': 'Erase',
    'signature.popup.tab.create_signature': 'Create your signature',
    'signature.popup.tab.ID_message': 'To add the identification it is necessary to click on the ID icon',
    'signature.popup.tab.saved_signature': 'Saved Signature ',
    'signature.popup.heading.create_signature': 'Create Signature',
    'signature.popup.heading.accept_legal_signature': 'I accept the legal representation of my signature',
    'signature.popup.heading.select_signature': 'Select Signature',
    'signature.popup.legal.terms': 'I accept the legal terms of my signature',
    'signature.popup.input.name': 'Write your name',
    'signature.popup.camera.photo.capture': 'Now the ',
    'signature.popup.camera.photo.mandatory_text': 'Picture of ID is mandatory',
    'signature.popup.camera.photo.capture.back': 'Now the ',
    'signature.popup.camera.photo.front': 'front',
    'signature.popup.camera.photo.back': 'back',
    'signature.popup.camera.photo.your_official_id': ' of your Official ID',
    'signature.popup.camera.photo.your_official_id.back': ' of the identification',
    'signature.popup.camera.photo': 'Capture the front of your Official ID',
    'signature.popup.camera.another_photo': 'Capture the back of your Official ID',
    'signature.popup.camera.capture_photo': 'Capture photo',
    'signature.popup.identification.title': 'Official Identification',
    'signature.popup.identification.subtitle': 'Capture your official ID',
    'signature.popup.identification.button.capture': 'Capture',
    'signature.popup.identification.button.attach': 'Attached',
    'signature.popup.water.mark': 'Sign here',
    'signature.popup.sign.instruction': '*Your signature must match with your official identification',
    'signUp_resent.alert': 'Email has been resent to your email ID!',
    'signUp.start_for_free': 'Start for free',
    'signUp.password.strength': 'Must contain at least one number, one uppercase, and 8 characters',
    'signatory.signIn.popup.label.welcome': 'WELCOME',
    'signatory.signIn.popup.label.name_of_invited_person': 'NAME OF INVITED PERSON',
    'signatory.signIn.popup.label.you_are_invited_to_sign_this_document': 'You are invited to sign this document',
    'signatory.signIn.popup.label.email': 'Email',
    'signatory.signIn.popup.heading.share': 'Share',
    'signatory.signIn.popup.label.enter_verification_code': 'Enter the verification code',
    'signatory.signIn.popup.email.placeHolder': 'invited@gmail.com',
    'signatory.signIn.popup.button.text': 'Sign now',
    'signatory.signIn.popup.text.by_signing_i_accept_the': 'By signing I accept the ',
    'signatory.signIn.popup.text.terms_and_conditions': 'terms and conditions',
    'signatory.signIn.popup.text.and_the': ' and the ',
    'signatory.signIn.popup.text.privacy_policy': 'privacy policy',
    'signatory.signIn.popup.text.of_weeSign': ' of weetrust ',
    'signatory.text.send_by_email': 'Send by email',
    'signatory.text.vobo_email': 'Document approved by:',
    'signatory.text.shared_email': 'Document shared with:',
    'signatory.text.download': 'Download',
    'signatory.button.text.status': 'Status',
    'signatory.button.text.save_and_send': 'Send',
    'signatory.button.text.sign': 'Finalize',
    'signatory.button.text.save': 'Save',
    'signatory.button.text.save_observer': 'Save observer',
    'signatory.button.text.continue': 'Continue',
    'signatory.button.text.signatory_Url': 'Signature URLs',
    'signatory.button.text.url_text': 'Click show data and urls of the signatories.',
    'signatory.button.text.copy': 'Copy',
    'footer.logout': 'Logout',
    'btn.back': 'Back',
    'signatory.page.loading': 'Please wait, Loading document.',
    'signatory.text.pending_signatures': 'Pending signatures',
    'signatory.text.field.placeholder': 'Enter the value',
    'signatory.checkbox.field.placeholder': 'Enter the value',
    'signatory.text.sign_document': 'Sign document',
    'document_validation.results.document_info.title': 'Document information',
    'document_validation.results.document_info.creator_info': '<strong>{{creatorName}} ({{creatorEmail}})</strong> created the document',
    'document_validation.results.document_info.created_at': 'Created at <strong>{{createdAt}}</strong>',
    'document_validation.results.document_info.sent_at': 'Sent at <strong>{{sentAt}}</strong>',
    'document_validation.results.signatories_info.title': 'Signatories information',
    'document_validation.results.signatories_info.text': '<strong>{{signatoryName}} ({{signatoryEmail}})</strong> signed at <strong>{{signedAt}}</strong>',
    'document_validation.results.PSC_info.title': 'PSC information',
    'document_validation.results.PSC_info.text': 'Document certified by Advantage Security, S de RL de CV as <strong>Certification Service Providers</strong> authorized by the <strong>Secretariat of Finance</strong> to comply with NOM 151',
    'document_validation.results.PSC_info.created_at': 'Date of issue <strong>{{createdAt}}</strong>',
    'validate.upload.document': 'Upload a document',
    'validate.upload.drag_and_drop.text1': 'Now you can drag',
    'validate.upload.drag_and_drop.text2': 'to verify that both are valid',
    'validate.upload.drag_and_drop.text3': 'Documents and Certificate PSC',
    'validate.upload.drag_and_drop': 'or drag and drop',
    'validate.button.validate': 'Validate',
    'validate.upload.title': 'Validate any document signed with weetrust',
    'validate.upload.sub_title': 'Our validation tool allows you to verify the authenticity of every document signed with weetrust.',
    'validate.upload.sub_title.next_line': 'Using the latest Blockchain technology, this tool can detect whether any modification was made to a document signed with weetrust',
    'validate.validating.textInit': 'Document Url Validation',
    'validate.validating.document': 'We are validating your document',
    'validate.validating.success.title': 'Congratulations! Your document',
    'validate.validating.success.asn.title': 'Congratulations! Your ASN',
    'validate.validating.success.status': 'is valid',
    'validate.validating.failure.title': 'Sorry your document',
    'validate.validating.failure.status': 'is not valid',
    'validate.validating.failureUrl': "The document's URL is Expired!",
    'validate.validating.failureUrl.message': 'To be able to access this document again, log in to your weetrust account or ask the owner of the document to share it again',
    'validate.validating.title': 'Your document has been reviewed',
    'validate.validating.pdf.success': 'You document is valid',
    'validate.validating.pdf.failed': 'You document is invalid',
    'validate.validating.asn.success': 'The PSC Certificate is valid',
    'validate.validating.asn.failed': 'The PSC Certificate is invalid',
    'validate.validating.compared.success': 'If there is a relationship between the document and the PSC certificate',
    'validate.validating.compared.failed': 'There is NO relationship between the document and the PSC certificate',
    'validate.validating.error.upload.max': 'You cannot upload more than two documents',
    'validate.validating.error.upload.noAccepted': 'You can only upload a PDF and ASN',
    'validate.revalidate.title': 'Do you want to validate another document?',
    'validate.revalidate.button': 'Yes, validate another',
    'validate.link.return': 'No, I want to return to weetrust.mx ',
    'validate.endorsement.isEndorsement': 'Document is endorsed',
    'validate.endorsement.hasSubsequent': 'This document has a back endorsement',
    'validate.endorsement.hasComparedVersion': 'The versions of the documents are different.',
    'validate.certificate.popup': 'Validating Your certificate !',
    'validate.certificate.signing': 'Signing your document !',
    'validate.invalid.password': 'Incorrect password',
    'validate.invalid.cert': 'Incorrect Certificate',
    'validate.invalid.pair': 'Invalid Key pair',
    'validate.expired.certificate': 'Your certificate has expired',
    'validate.missing.certificate.key': 'Missing Certificate, Private key or Password.',
    'validate.legal.representative': 'Please use the legal representative´s e.firma to sign a document on behalf of a company.',
    'validate.document.signed.success': 'The document has been signed successfully.',
    'signatory.document.signed.success': 'The document has been signed successfully and once it is signed by all participants it will be sent to you via email.',
    'validate.button.save': 'Save',
    'loader.please.wait.text': 'Please wait',
    'rating.rate_service': 'Rate our service',
    'rating.send': 'Send',
    'rating.rate.textarea.placeholder': 'Leave us a comment',

    'sat.file.label': 'Select your file',
    'sat.title': 'Attach files',
    'sat.select_certificate': 'Select your file .cer',
    'sat.select_private_key': 'Select your file .key',
    'sat.enter_your_password': 'Enter your password e.firma',
    'addUser.name': 'Name',
    'addSubUser.name': "Subuser's Complete Name",
    'addSubUser.limit': 'Exceeded Limit User',
    'addUser.password': 'Password',
    'addSubUser.password': 'Temporary Password ',
    'addUser.emailID': 'Email ID',
    'addUser.addUser': 'Add Users',
    addUser: 'Add user',
    'addUser.add_User': 'Add User',
    'editUser.edit.user': 'Edit User',
    'addUser.user.exist': 'This email address is already in use.',
    'editUser.edit.save': 'Save',
    'addUser.invalid_email_address': 'Invalid email address',
    'addUser.password_length': 'Password should be at least 8 characters,a capital letter and special characters.',
    'addUser.number_length_message': 'Number should be 10 digit long.',
    'userList.edit': 'Edit',
    'userList.change.password': 'Change password',
    'userList.remove.user': 'Remove user',
    'confirmation.yes': 'Yes',
    'confirmation.no': 'No',
    'removeUser.confirmation': 'Are you sure you want to remove',
    'document.confirmation': 'Are you sure you want to ',
    'document.saveChanges': 'Are you sure you want to save the changes',
    'userList.user.not.found': 'No user found',
    'user.update.failed': 'Failed to update user details.',
    'document.delete': 'Are you sure to delete the document?',
    'document.delete.confirmation': 'You are going to delete a file, do you want to continue?',
    'document.resend.confirmation': 'Do you want to resend the document?',

    'billing.page.text.plan_information': 'Plan Information',
    'billing.page.text.payment_method': 'Payment Method',
    'billing.page.text.billing_information': 'Billing Information',
    'billing.page.text.plan': 'Plan :',
    'billing.page.text.plan.validity': 'Valid till : ',
    'billing.page.button.Change_plan': 'Change plan',
    'billing.page.button.Cancel_plan': 'Cancel plan',
    'billing.page.button.Update_Payment_Method': 'Update payment method',
    'billing.page.button.Add_Payment_Method': 'Add payment method',
    'billing.page.button.request_invoice': 'Request invoice',
    'billing.page.label.text.name_or_business_name': 'Name or Business Name',
    'billing.page.label.text.RFC': 'RFC',
    'billing.page.label.text.Address': 'Address',
    'billing.page.label.text.State': 'State',
    'billing.page.label.text.City': 'City',
    'billing.page.label.text.ZIP_code': 'ZIP code',
    'billing.page.label.text.country': 'Country',
    'billing.page.label.text.phone': 'Phone',
    'billing.page.field.business_name.placeholder': 'Company',
    'billing.page.field.default_placeholder': 'Type Something',
    'billing.page.field.zipcode.placeholder': '00000',
    'billing.page.field.address.placeholder': 'Address',
    'billing.page.field.phone.placeholder': '+91',
    'billing.page.signature.config.text': 'Active signature with text',

    'billing.business_name': 'Enter Business Name',
    'billing.RFC': 'Enter RFC',
    'billing.address': 'Enter Address',
    'billing.state': 'Enter State',
    'billing.city': 'Enter City',
    'billing.zipCode': 'Enter Zip Code',
    'billing.country': 'Enter Country',
    'billing.phone': 'Enter Phone Number',
    'invalid.number': 'Enter Valid Number',
    'payment.pay.with.card': 'Pay with credit / debit card',
    'payment.btn.pay': 'Pay',
    'payment.card.number': 'Card number',
    'payment.cardholder.name': 'Cardholder name',
    'payment.address': 'Address',
    'payment.card.expires': 'Expires',
    'payment.card.ccv': 'CCV',
    'payment.card.ccv.hint.message': 'The Last 3 digits displayed on the back of your card',
    'payment.confirm.btn': 'The last 3 digits on the back of the card.',
    'payment.confirm.heading': ' Please confirm your payment',
    'payment.saved.card': 'Your saved card',
    'payment.update.card': 'Update card',
    'confirm.payment.btn': 'Confirm payment',
    'confirm.payment.message': 'Your subscription was successful',
    'confirm.payment.message_fail': 'Your subscription was failed',
    'update.payment.heading': 'Update your payment details below',
    'add.payment.heading': 'Add your payment details below',
    'cancel.plan.confirmation': 'Are you sure you want to cancel your subscription plan?',
    'downgrade.plan.confirmation': 'Are you sure you want to downgrade your subscription plan?',
    'payment.update.btn': 'Update',
    'payment.add.btn': 'Add',
    'notification.dialogue.close': 'Close',
    'notification.dialogue.try_again': 'Try Again',
    'notification.dialogue.ok': 'Ok',
    'notification.dialogue.renovate': 'Renew now',
    'notification.dialogue.no': 'No, return',
    'request.invoice.failure': 'Failed to request your invoice. Please try again later.',
    'request.invoice.unauthorized': 'You must be subscribed to request the invoice .',
    'request.invoice.success': 'Your request for invoice is sent.',
    'request.addCard.success': 'Your payment method has been added successfully.',
    'userList.add.user.limit.error': 'You have reached your subscriptions subuser’s limit',
    'document.add.limit.error': 'You have reached your subscriptions document’s limit',
    'document.add.subscription_expire.error': 'Your subscription has expired',
    'document.add.subscription_expire.error.message': 'To continue enjoying the benefits of signing electronically, renew your subscription',
    'document.add.no_subscription.error': 'You need subscription to use weetrust',
    'document.use.camera_feature.error': 'Please purchase a subscription to activate this feature',
    'select.plan.warning': 'Please Select a plan to continue.',
    'document_incomplete.warning': 'We are processing your finalized document, please wait for a few minutes until its gets finalized you cannot download and share it!',
    'document.not_found': 'Document Not Found',
    'document.connection_error': 'Unable to connect to the Server. Please try again.',
    'document.sat.info': 'Deberás de contar con tu .key y .cer de tu e.firma en tu teléfono para poder continuar con el proceso de firma sino te sugerimos ingresar desde tu computadora para completar tu firma',
    'corporate.plan.message': 'Your request has been sent successfully, we will inform you shortly!',
    'corporate.plan.restriction': 'Ensure you have a maximum number of users corresponding to this plan.',
    'document.upload.error': 'We are unable to process your document. Try again!',
    'document.upload.invalid_type.pdf': 'The file must be a PDF',
    'document.upload.maxsize_error': 'The file size cannot exceed 20MB',
    'document.upload.maxsize_error_30': 'The file size cannot exceed 30MB',
    'document.upload.multiple.maxsize_error': 'The total file size cannot exceed 20MB',
    'document.upload.multiple.maxcount': 'The document limit is 10 files',
    'document.upload.multiple.single': 'The document limit is 1 file',
    'document.field_array.error': 'The signature is necessary to continue',
    'document.photo_array.error': 'The ID is necessary to continue',
    'document.signature.error': 'Signature is mandatory to process the document. Try again!',
    'document.geolocation.preview.error': 'The signature requires geolocation. Enter this {url} to activate it in your browser.',
    'document.geolocation.error': 'The signature requires active geolocation in your browser. Enter this {url} to activate it.',
    'document.geolocation.permission.error': 'The signature requires active geolocation in your browser. Update your settings to allow it´s use.',
    'document.geolocation.tooltip': 'You need to enable the geolocation in your browser',
    'document.geolocation.signatory': 'This document requires geolocation activation in the browser',
    'document.page.no_record_found': 'No Record Found',
    'document.button.continue': 'Continue',
    'document.button.confirm': 'Confirm',
    'server.error': 'Server error.',
    'signing_authority.only_me.tooltrip': 'Only your signature is required',
    'signing_authority.me_and_others.tooltrip': 'Yours and others signatures are required',
    'signing_authority.others.tooltrip': 'Others signatures are required',

    landscape_mode_text: 'Landscape mode is not supported yet. Please rotate your device to portrait mode for best experience.',
    'taust.session_expire': 'Your Session has expired',
    'taust.document_sent_failure': 'document sent failure ',
    'taust.document_sent_success': 'document sent successfully. ',
    'taust.email_failure': 'Email failure ',
    'taust.search_document_failure': 'No result found',
    'taust.search_document_empty': 'Enter the document name to search',
    'taust.search_email_failure': 'Unable to search this Email Address',
    'taust.update_subscription_failure': 'Unable to Update Subscription Data',
    'taust.update_subscription_success': 'Subscription Data updated successfully ',
    'taust.document_delete_success': 'document deleted successfully ',
    'taust.generate_apiKey_success': 'Api Key Generated successfully ',
    'taust.generate_apiKey_failure': 'Api Key Generation failure',
    'taust.document_delete_failure': 'document deletion failure ',
    'taust.unable.delete': 'Unable delete document',
    'taust.subscription_cancel_failure': 'subscription Cancel failure ',
    'taust.document_share_success': 'document shared successfully ',
    'taust.document_share_failure': 'document sharing failure',
    'taust.document_resend_success': 'The document has been successfully forwarded to pending signers. ',
    'taust.document_resend_failure': 'document resend failure ',
    'taust.mail_resent_success': 'The mail has been forwarded to your email address!',
    'taust.user_delete_success': 'user deleted successfully ',
    'Title.WeeSign_Pro': 'weetrust PRO',
    'firebase.resend.email.error': 'Please wait a minute before sending the email again',
    invalid_link_message: 'Your link is not valid.',
    expire_link_message: 'Your Link has been expired',
    expire_email_message: 'Request resending of the document to the email: {email}',
    block_by_order_message: 'The previous signer has not yet completed the signing process',
    block_by_vobo_message: 'The approval figure has not yet completed the review process',
    block_by_vobo_refused_message: 'The document has been rejected your link is no longer valid',
    deleted_document_message: 'This document has been deleted',
    canceled_document_message: 'This document has been canceled',
    'corporate.label.statistics': 'Administrative panel',
    'corporate.label.statistics.general_information': 'General information',
    'corporate.label.statistics.sale_information': 'Sale information',
    'corporate.label.statistics.identity_provider': 'Verification Provider',
    'corporate.label.statistics.documents_information': 'Document Information',
    'corporate.label.total_documents': 'Documents:',
    'corporate.label.pending_documents': 'Pending documents:',
    'corporate.label.completed_documents': 'Documents completed:',
    'corporate.label.top_fifteen': 'Users who have signed the most on the platform',
    'corporate.label.most_use_users': 'Users who use the API the most',
    'corporate.label.recently_connected': 'Last connected users',
    'corporate.label.recently_register': 'Last registered users',
    'corporate.label.older_users': 'Users with more time on the platform:',
    'corporate.label.planName': 'Plan:',
    'corporate.label.membership': 'Membership:',
    'corporate.label.email': 'Email ID: ',
    'corporate.label.full_name': 'Full Name: ',
    'corporate.label.company_name': 'Company Name: ',
    'corporate.label.authenticate_url': 'Authenticate URL:',
    'corporate.label.document_limit': 'Total Document Limit ',
    'corporate.label.user_limit': 'Total User Limit ',
    'corporate.label.expiry': 'Expiry Date ',
    'corporate.label.amount': 'Amount ',
    'corporate.label.biometric_credits_id': 'Biometric Credits ID ',
    'corporate.label.biometric_credits_face': 'Biometric Credits Face ',
    'corporate.label.background_check_credits': 'Verification Credits Background Check ',
    'corporate.label.orc_check_credits': 'Address Verification Credits (OCR) ',
    'corporate.label.incode_package1': 'Incode Identity Credits Package 1',
    'corporate.label.incode_package2': 'Incode Identity Credits Package 2',
    'corporate.label.incode_package3': 'Incode Identity Credits Package 3',
    'corporate.label.biometric_enable_id': 'Identification Verification (OCR)',
    'corporate.label.biometric_enable_face': 'Verification with Biometrics (Face ID)',
    'corporate.label.enable_geolocation': 'Enable geolocation',
    'corporate.label.disable_mailing': 'Disable Mailing',
    'corporate.label.biometric_renapo_validation': 'Renapo Validation',
    'corporate.label.biometric_ine_validation': 'INE Validation',
    'corporate.label.biometric_background_check_validation': 'Background Check Validation',
    'corporate.label.enable_white_label': 'Enable White Label',
    'corporate.label.enable_white_label_template': 'Enable template HSBC',
    'corporate.label.massive_signature': 'Massive Signature',
    'corporate.label.format': 'Formats',
    'corporate.label.disabled_url': 'Disable URL in email',
    'corporate.label.authenticate_notification': 'Enable Authentication Notification',
    'corporate.label.enable_order_sign': 'Enable Order of signers',
    'corporate.label.white_label.name': 'name',
    'corporate.label.white_label.subjectEmail': 'subject Email',
    'corporate.label.white_label.color': 'Select Color',
    'corporate.label.white_label.logo': 'Logo',
    'corporate.label.white_label.sign': 'Sign',
    'corporate.label.white_label.biometric': 'Biometrics Sign',
    'corporate.label.white_label.url': 'Url',
    'corporate.label.white_label.templateAttached': 'Template attached',
    'corporate.label.white_label.templateCertificate': 'Template certificate',
    'corporate.label.white_label.templateEfirma': 'Template de Efirma',
    'corporate.label.white_label.templateEfirmaGeo': 'Template de Efirma Geo',
    'corporate.label.white_label.templateRegister': 'Template register',
    s3_upload_error: 'Your system date and time settings are not accurate to your time zone, due to this we are unable to proceed further!',
    your_plan_is: 'Your current plan is ',
    which_is_valid_for: ' which is valid upto ',
    days_then_will_be_migrate_to: ' and then will be automatically migrated to ',
    'document.page.heading.document': 'Documents',
    'document.page.heading.available': 'available',
    'document.page.heading.available_capital': 'Available:',
    'document.page.heading.used': 'Used',
    'document.page.heading.validity': 'Valid until',
    'document.page.heading.biometric_id': 'Biometric Credits ID',
    'document.page.heading.biometric_face': 'Biometric Credits',
    'document.page.heading.biometric_background_check': 'BackgroundCheck Credits',
    'document.preview.geolocation.action.label.default': 'Active Geolocation',
    'document.preview.geolocation.action.label.active': 'Geolocation active',
    'document.preview.geolocation.label': 'Request geolocation',
    'document.preview.biometric_background_check.label': 'Active BackgroundCheck',
    'document.preview.shared_document.label': 'Shared document',
    'document.preview.shared_document.description': 'It allows sharing the document to a user as an observer (unsigned).',
    'document.preview.split.description': 'Al seleccionar el rango 1 a 1, solamente se separa la primera hoja. Al seleccionar el rango 1 a 3, solamente separa las primeras tres paginas.',
    'document.preview.geolocation.description': 'It allows to obtain the geographical coordinates through the browser.',
    'document.preview.vobo.button.title': 'Add VoBo',
    'document.preview.vobo.button.tooltip': 'Add the person you want to approve your document and without whom the signing process cannot be completed. You can modify the order in which you want the signature and approval requests to arrive',
    'document.preview.vobo.name.error': 'Enter a name',
    'document.preview.vobo.email.error': 'Enter a valid email',
    'document.preview.vobo.emailOwner.error': 'It cannot be the same email as the owner',
    'document.preview.vobo.emailRepeat.error': 'The mail is repeated',
    'document.preview.vobo.order.error': 'Select an order from the go-ahead',
    'document.shared.empty.error': 'Add a document to share',
    'document.shared.empty.email': 'Enter observer email',
    'document.shared.duplicate.email': 'Duplicate observer mail',
    'document.shared.format.email': 'Invalid Email in observers.',
    make_payment_button: 'Make Payment',
    failed_subscription_payment_message: 'We are unable to process the payment of the subscription for your current plan, please make the payment to continue using the platform!',
    link: 'link',

    'biometrics.capture.click': 'Click here to take a photo of your document ID',
    'biometrics.capture.front': 'Front',
    'biometrics.capture.back': 'Back',
    'biometrics.capture.selfie': 'Selfie',

    'biometrics.options.photo_id': 'Photo ID',
    'biometrics.options.card_id': 'Identity Verification',
    'biometrics.options.face_id': 'Facial Biometry',

    'biometrics.options.background_check_id': 'Background Check',
    'biometrics.options.background_check_id_description': 'Selecting this option will enable the verification of renapo and INE',

    'biometrics.options.card_id.ine': 'ID',
    'biometrics.options.card_id.cedula': 'Professional ID',
    'biometrics.options.card_id.passport': 'Passport',

    'biometrics.options.photo_id_title': 'Photo ID',
    'biometrics.options.card_id_title': 'Identity Verification',
    'biometrics.options.face_id_title': 'Verification with Facial Biometry',

    'biometrics.options.card_id_description': 'Requires an official ID to be added to validate the signature. (ID Passport, Professional ID, Identity ID, Citizenship ID, Multiple Immigration Form, DNI, VISA).',
    'biometrics.options.face_id_description': 'Requires all signers to take a selfie to validate the signature.',
    'biometrics.options.backgroundcheck_description': 'Enables the query to Renapo,INE and Black Lists.',

    'biometrics.alert.faceId': 'Saving facial biometrics',
    'biometrics.alert.verification': 'Saving ID Verification',
    'biometrics.alert.id_required': 'The photo of your ID on both sides is required.',
    'biometrics.alert.ine_required': 'The photos of your ID on both sides is required.',
    'biometrics.alert.cedula_required': 'The photos of your professional ID on both sides is required.',
    'biometrics.alert.passport_required': 'The photo of your passport is required.',

    'biometrics.options.enable_id_card': 'Add ID',
    'biometrics.options.enable_faceid': 'Add Face Verification',

    'biometrics.alert.selfie_required': 'Facial biometrics is required.',
    'biometrics.alert.errors.camera_required': 'A camera has not been detected, please try another device.',
    'biometrics.alert.errors.allow_camera': 'You must allow access to the camera to capture your document.',
    'biometrics.alert.errors.processing_files': 'A problem occurred while processing the files, Please try again.',
    'biometrics.alert.errors.server_error': 'A problem occurred with the service, Please try again.',
    'biometrics.alert.errors.expired': 'Document expired, Please try another official document.',
    'biometrics.alert.errors.validation_fail': 'We have not been able to validate your document, please read the recommendations and try again',
    'biometrics.alert.errors.attempt.error': 'Biometrics error. Please follow the recommendations and try again.',
    'biometrics.alert.errors.attempt.description': 'Lorem Ipsum is simply dummy text of the printer took a galley of type and scrambled it to make a type specimen book.',
    'biometrics.alert.errors.limited.error': 'Too many failed attempts have occurred.',
    'biometrics.alert.errors.limited.description': 'We were unable to validate your biometrics correctly.',
    'biometrics.alert.errors.limited.instructions': 'Please dial the following number to receive support.',
    'biometrics.alert.errors.limited.instructions_bottom': 'Please follow the recommendations to avoid future failures.',
    'biometrics.alert.errors.limited.credits': 'Identity Verification credits not available.',
    'biometrics.alert.errors.contact.us': 'Please contact us to acquire more credits.',
    'biometrics.alert.continue': 'Continue',
    'biometrics.signatory.processing': 'Your biometrics is being processed.',
    'biometrics.signatory.completed': 'Your biometry has already been processed.',

    'biometric.retry.error': 'Your link has expired or is invalid.',
    'biometric.retry.error_used': 'This link has already been used.',
    'biometric.retry.retry_text': 'The signature request with identity verification has been forwarded.',
    'biometric.retry.skipped_text': 'Signature passed with failed biometrics.',

    'signature.popup.tab.validate_id': 'Capture ID Card',
    'signature.popup.tab.validate_face': 'Take a Selfie',
    'inbox.list.header.name': 'Name',
    'inbox.list.header.tags': 'Tags',
    'inbox.list.header.signers': 'Signatories',
    'inbox.list.header.status': 'Status',
    'inbox.list.header.date': 'Date',
    'inbox.list.pagination.documents': 'Documents',
    'inbox.list.pagination.pages': 'Pages',
    'inbox.list.pagination.from': 'from',
    'inbox.list.pagination.previous': 'Previous',
    'inbox.list.pagination.next': 'Next',
    'inbox.list.signer.info.biometric': 'Biometric',
    'inbox.list.signer.info.biometric_error': 'Biometric Failed',
    'inbox.list.signer.info.photo_id': 'Photo ID',
    'inbox.list.signer.info.is_signed': 'Is signed',
    'inbox.list.signer.info.expiry': 'Expiry',
    'inbox.list.signer.info.request_signatory': 'Request Signature',
    'inbox.list.signer.info.biometric_processing': 'Processing',
    'inbox.list.signer.info.biometric_checking': 'Under review by issuer of the document',
    'inbox.list.signer.info.pending': 'Not signed',
    'inbox.list.vobo.info.is_checked': 'Accepted',
    'inbox.list.vobo.info.is_refused': 'Refused',
    'inbox.request_signatory.text': 'Remember that requesting the signature can use some biometric credits.',
    'inbox.request_signatory.button_no': 'Approve Signature',
    'inbox.request_signatory.button_yes': 'Request Signature',
    'inbox.request_signatory.question': 'Do you want this user to sign again?',

    'inbox.massive_signature.pending.fragment-1': 'You have ',
    'inbox.massive_signature.pending.fragment-2': '{totalPendingDocuments} documents ',
    'inbox.massive_signature.pending.fragment-3': 'to sign ',
    'inbox.massive_signature_now.toogle': 'Sign all at once!',
    'inbox.single_search.input': 'Search document',
    'inbox.advanced_search.toggle_button': 'Advanced Search',
    'inbox.list_signature.pending': 'See your documents pending signature!',
    'inbox.list_notification.text': 'You have new notices',

    'inbox.advanced_search.form.date_range': 'Date range',
    'inbox.advanced_search.form.date_range.today': 'Today',
    'inbox.advanced_search.form.date_range.yesterday': 'Yesterday',
    'inbox.advanced_search.form.date_range.last_seven_days': 'Last 7 days',
    'inbox.advanced_search.form.date_range.last_thirty_days': 'Last 30 days',
    'inbox.advanced_search.form.date_range.this_month': 'This Month',
    'inbox.advanced_search.form.date_range.last_month': 'Last Month',

    'inbox.advanced_search.form.email': 'Signatory email',
    'inbox.advanced_search.form.document_status': 'Document status',
    'inbox.advanced_search.form.biometric': 'Verification ID',
    'inbox.advanced_search.form.signature_type': 'Signature type',
    'inbox.advanced_search.form.pendig.from.singular': 'Missing',
    'inbox.advanced_search.form.pendig.from.plural': 'Missing',
    'inbox.advanced_search.form.pendig.total': 'of',
    'inbox.advanced_search.form.pendig.total.all': 'of',
    'inbox.advanced_search.form.pendig.signers.singular': 'signatory',
    'inbox.advanced_search.form.pendig.signers.plural': 'signatories',
    'inbox.advanced_search.form.pendig.signers.all': 'all',
    'inbox.advanced_search.form.pendig.signers.total': 'total',
    'inbox.advanced_search.form.tags': 'Tags',

    'inbox.advanced_search.search_button': 'Search',
    'inbox.advanced_search.cancel_button': 'Cancel',
    'inbox.advanced_search.clean_button': 'Clean',
    'inbox.advanced_search.report_button': 'Download report for your search',
    'inbox.advanced_search.generate_report': 'Generating excel report',
    'inbox.advanced_search.errors.invalid_report': 'An error occurred while generating the report',
    'inbox.advanced_search.documents_bulk_download_button': 'Download completed documents',
    'inbox.advanced_search.generate_bulk_download_link': 'Generating download link',
    'inbox.advanced_search.errors.bulk_download': 'An error occurred while generating download link',
    'inbox.advanced_search.documents_bulk_download_title': 'Your download request is beign processed',
    'inbox.advanced_search.documents_bulk_download_thanks': 'Thank you for your patience',
    'inbox.advanced_search.documents_bulk_download_link': 'In a few moments you will be receiving an email with the download link',

    'inbox.notification_title.remember': 'Do you remember what you had to do today?',
    'inbox.notification_text.remember': 'Do not  worry, now you can add reminders to your documents',
    'inbox.notification_title.remember_advice': 'You have {nextNotifications} reminders',
    'inbox.notification_text.remember_advice': 'Do not worry when the day arrives, we will notify you with a ringing bell',
    'inbox.notification_text.remember_remove': 'Remove',
    'inbox.notification_text.link_reminder': 'Show document',

    'tag.dialog.content.create_tag': 'Create new tag',
    'tag.dialog.content.assign_tag': 'Assign tag',
    'tag.dialog.content.cancel': 'Cancel',
    'tag.dialog.content.exit': 'Exit',
    'tag.dialog.content.create': 'Create',
    'tag.dialog.content.assign': 'Assign',
    'tag.dialog.content.apply': 'Apply',
    'tag.dialog.content.create_tag_confirm': 'The tag was added to your list',
    'tag.dialog.large.error': 'The tag name must not contain more than 40 characters',
    'tag.dialog.color.text': 'Add tag color',
    'tag.dialog.search.error': 'No matches found for your search',
    'tag.dialog.color.repeat': 'Tag already exist',
    'tag.dialog.selector': 'Tag as',

    'inbox.content.empty.title': 'Documents found: 0',
    'inbox.content.empty.description': 'No matches found for your search',
    'inbox.empty.massive.title': 'Pending documents: 0',
    'inbox.empty.massive.description': 'You have no pending documents to sign',
    'inbox.empty.fail.title': 'Sorry',
    'inbox.empty.fail.description': 'An error occurred try again',
    'inbox.empty.first.title': 'One move and you are done!',
    'inbox.empty.first.description': 'Send your first document to sign',
    'inbox.statistics.completed.title': 'Completed:',
    'inbox.statistics.vid.title': 'Package B ( VId ):',
    'inbox.statistics.background.title': 'Package C ( Background Check ):',
    'inbox.statistics.cutOffDate.title': 'Cutoff date:',
    'inbox.whatsapp.error': 'An error occurred try again',
    'inbox.whatsapp.success': 'Forwarded by whatsapp successfully',

    'signatory.text.ready_signatures': 'Requirements completed',
    'signatory.text.edit_signatures': 'Then click to edit',
    'signatory.text.right.forcedSign': 'Signature',

    'signatory.text.right.forcedPhotoID': 'Photo ID',
    'signatory.text.right.forcedGeolocation': 'Geolocation',
    'signatory.text.right.forcedID': 'Identity Verification',
    'signatory.text.right.forcedFaceID': 'Facial Biometrics',
    'signatory.text.right.forcedAttachment': 'Attachments',
    'document.inbox.header.created': 'Created',
    'document.inbox.header.modified': 'Modified',

    'signatory.dialog.validation.signature': 'Please trace your signature',
    'signatory.dialog.validation.photoID': 'Please upload Photo of your ID',
    'signatory.dialog.validation.photoID-verification': 'Please upload Front and Back of your ID',
    'signatory.dialog.validation.photoID-save': ' Please select a signature with photo ID',
    'signatory.dialog.confirmation.continueSign': 'You have completed your document, click',

    'templates.form.single.instructions': 'Please fill in the fields',
    'templates.preview_of_document': 'CSV preview',
    'templates.feature.photoID': 'Photo ID',
    'templates.feature.geolocation': 'Activate Geolocation',
    'templates.feature.biometric': 'ID Verification',
    'templates.feature.backgroundCheck': 'Background Checks',
    'templates.feature.tag': 'Add #tag name',

    'templates.preview.generate_documents': 'Generate Documents',
    'templates.preview.request_signature.title': 'Signatory',
    'templates.preview.request_signature.button': 'Signatory',
    'templates.preview.request_signature.tooltip': 'Click to set signature on the document',

    'templates.preview.save': 'Save',
    'templates.title': 'Templates',

    'templates.errors.100': 'No information found in the file.',
    'templates.errors.101': 'The document headers are incorrect.',
    'templates.errors.102': 'Make sure all rows in the document have an email address.',
    'templates.errors.103': 'Make sure that all the rows of the document have a name (name).',
    'templates.errors.104': 'The file contains invalid emails.',
    'templates.errors.105': 'The number of emails does not match the names.',
    'templates.errors.106': 'You must set the signature for all signers.',
    'templates.errors.110': 'An error occurred while processing your document, please try again later.',
    'templates.errors.111': 'Make sure all numbers for WhatsApp sending are in the correct format.',

    'biometrics.capture.indications.click': '* To add identification is necessary to click on the image in the box',
    'biometrics.capture.indications': 'Take a selfie',
    'biometrics.capture.indications.1': 'Put on without glasses or mask as close as possible without cutting off part of your face, head or neck',
    'biometrics.capture.indications.2': 'Make sure you are well lit. No shadows or reflections',
    'biometrics.document.capture.indications': 'Capture your identity document',
    'biometrics.document.capture.indications.INE': 'ID',
    'biometrics.document.capture.indications.or': 'or',
    'biometrics.document.capture.indications.passport': 'Passport',
    'biometrics.document.capture.indications.1': 'Make sure to capture the full and focused identification',

    'signatory.confirmation.dialog': 'Signature Submitted',
    'signatory.confirmation.dialog.return': 'Undo',

    'biometrics.advice.resend': 'Remember that this resend option will cost a new credit',
    'inbox.status.more': 'More',

    'remove.tag.dialog.title': 'Remove tag',
    'remove.tag.dialog': 'Are you sure you want to delete {tagsNames}?',

    'signatory.dialog.confirmation.btn': 'Finish signature',
    'signatory.dialog.confirmation': 'Almost all done!',
    'signatory.dialog.confirmation.pleaseClick': 'Please click the end signature button',
    'signatory.dialog.confirmation.pleaseClick.2': 'for your document to be sent',
    'signatory.dialog.confirmation.termsConditions': 'When clicking the "Finish signature" button',
    'signatory.dialog.confirmation.termsConditions.2': 'I accept the ',

    'static.position.dialog': 'Wait!',
    'static.position.dialog.messagge': 'Now position your signature on the document, ',
    'static.position.dialog.btn': 'Got it',
    'static.position.dialog.advice.click': 'please give click ',
    'static.position.dialog.advice.continue': '"Continue" ',
    'static.position.dialog.advice.finish': 'button to finish',

    'status.information.createdBy': 'Created by: ',
    'status.information.nickname': 'Nickname: ',
    'status.information.documentID': 'DocumentID: ',
    'status.information.send.date': 'Send dete: ',
    'status.information.resend': 'N° of resend: ',
    'status.information.resend.date': 'Resend date: ',
    'status.information.resend.dates': 'Resend dates',
    'status.information.number.dates': '° forwarding',
    'status.information.complete.date': 'Completed: ',

    'status.information.sign.date': 'Date signed:',
    'status.information.verificarion.number': 'Number of ID verification attempts:',
    'status.information.verificarion.more': 'View results',
    'status.information.number.verificarion': '° ID Verification attempt failed',
    'status.information.verificarion.result': 'Click to see result',

    'preview.file.nickname': 'Add a nickname to the document',
    'preview.file.nickname.opcional': '(optional)',
    'preview.file.nickname.description': 'Allows you to create a nickname to identify a document in an internal stream',

    'view.file.nickname': 'Document nickname:',

    'signatory.dialog.advice.continueSign': 'It is no longer possible to edit the name or email of the document, there are people who have already signed the document',
    'signatory.dialog.advice.ok': 'Understood',

    'signatory.edit.dialog': 'Edit name or email',
    'signatory.edit.dialog.back': 'Cancel edit',
    'signatory.edit.dialog.confirm': 'Update signatories',

    'signatory.dialog.confirmation.edit': 'You have changed the name and email to',
    'signatory.dialog.confirmation.edit.2': 'and it has been sent successfully',
    'signatory.dialog.confirmation.edit.3': 'Or copy and send the signature URL',

    'signatory.dialog.confirmation.delete': 'You are about to delete mail from',
    'signatory.dialog.confirmation.delete.2': 'Remember that you can edit and send from the document view window',
    'signatory.dialog.confirmation.delete.btn': 'Yes, delete',

    'signatory.dialog.error.delete': 'Unable to delete',
    'signatory.dialog.error2.delete': 'for being the only signer',
    'signatory.dialog.error.delete.instructions': 'You will have to completely delete the document as follows:',
    'signatory.dialog.error.delete.instructions.message': '1- Go to the main section',
    'signatory.dialog.error.delete.instructions.message2': '2- Identify the document',
    'signatory.dialog.error.delete.instructions.message3': '3- In the menu select delete and that is it!',
    'signatory.dialog.error.delete.btn': 'Go to main section',
    'signatory.dialog.error.delete.title': 'Unable to delete all signatories',
    'advance.search.all': 'All',
    'advance.search.yes': 'Yes',
    'advance.search.no': 'No',
    'advance.search.bg_check': 'Background Check',
    'advance.search.electronic.signature': 'Electronic signature',
    'advance.search.e.signature': 'e.firma SAT',
    'advance.search.document.status.completed': 'Completed',
    'advance.search.document.status.completed.failed.biometric': 'Completed with failed biometric',
    'advance.search.document.status.pending': 'Pending',
    'advance.search.document.status.pending.failed.biometric': 'Pending because filed biometric',
    'advance.search.document.status.draft': 'Draft',
    'advance.search.document.status.shared': 'Shared',
    'advance.search.document.status.pending.sign': 'Pending to sign',

    'document.page.add_multiple_document': 'Add one or multiple files',
    'document.page.add_multiple_document.info': 'Highlight and organize entire PDFs as you wish Quick and easy!',
    'document.page.add_multiple_document.btn': 'Select files',
    'document.page.add_multiple_document.drag_and_drop': 'Drag and drop one or multiple files',

    'document.page.add_document.upload': 'Loading file',
    'document.page.add_multiple_document.upload.thanks': 'Thanks for your patience, this process may take a few seconds',

    'document.page.add_multiple_document.icon': 'Activate this icon',
    'document.page.add_multiple_document.order': 'to choose main document',
    'document.page.add_multiple_document.describe.order': 'The order of the documents will be seen from left to right',
    'document.page.add_multiple_document.add_more': 'Add more from',
    'document.page.add_multiple_document.merge_files': 'Merge files',

    'document.page.add_multiple_document.merge': 'Merging files',
    'document.page.add_multiple_document.merge.wait': 'Right now your files are being merged to generate a single PDF',
    'document.page.add_multiple_document.merge.thanks': 'Thanks for your patience, this process may take a few seconds',

    'document.page.add_multiple_document.wrong_document': 'It is not possible to view this document',
    'document.page.add_multiple_document.wrong_document.error': 'It is only possible to preview documents in pdf format',
    'document.page.add_multiple_document.wrong_document.advice': 'We suggest you convert your document to PDF format and upload it again or continue with the process',

    'massive.signature.button.text.save.signatory': 'Use this Signature',
    'massive.signature.signatory': 'Massive Signature',
    'massive.signature.thanks.header.dialog': 'The 10 documents are being signed with the following signature',
    'massive.signature.thanks.body': 'At the moment your signature is being reflected in all the documents on the list',
    'massive.signature.thanks.body.complemet': 'You will be notified by email when your documents are completed',
    'massive.signature.thanks.end': 'Thanks for your patience, this process may take a few minutes',
    'massive.signature.thanks.btn': 'Return to list',
    'massive.signature.page.heading.document': 'Documents in which my signature is required',
    'massive.signature.page.heading.pending.documents': 'Total pending documents',
    'massive.signature.page.dropdown.preview': 'Preview',
    'massive.signature.page.dropdown.remove': 'Remove from the list',
    'massive.signature.button.text.continue': 'Continue',
    'massive.signature.page.heading.pending.endorsements': 'Total pending endorsements: ',
    'massive.signature.detail.pendings.error': 'No document to sign',
    'massive.signature.detail.signature.error': 'An error occurred while processing the signature. Try again',
    'massive.signature.detail.send.error': 'An error occurred while sending to sign the documents. Try again',

    'massive.signature.advanced_search.form.signature.type': 'Signature type',
    'massive.signature.advanced_search.form.document.status': 'Document status',
    'massive.signature.advanced_search.form.verification.status': 'Verification status',
    'massive.signature.advanced_search.form.document.status.only.me': 'I am the only one left',
    'massive.signature.advanced_search.form.document.status.others': 'Several signatories are missing',
    'massive.signature.advanced_search.form.verification.ok': 'Succesfully verification',
    'massive.signature.advanced_search.form.verification.false': 'Failed verification',
    'massive.signature.advanced_search.form.verification.without': 'Without verification',

    'massive.signature.list.page.text.selected.documents': 'You have selected {totalPendingDocuments} documents to sign',
    'massive.signature.list.page.text.remove.document': 'If there is a document that you do not want in bulk signature, you can remove it from the following list',
    'massive.signature.list.page.text.name': 'Name',
    'massive.signature.list.page.text.signatories': 'Signatories',
    'massive.signature.list.page.text.alert.create.signature': 'It is moment to create your signature or use saved one',
    'massive.signature.list.page.text.alert.portray.signature': 'This will be automatically reflected in all the documents on the list',
    'massive.signature.list.page.text.alert.geolocation': 'Some documents require geolocation to be activated',
    'massive.signature.list.page.text.create.signature': 'Sign now',
    'massive.signature.list.page.notification.missing.pendingdocs': 'Undefined document list to realize operation, please, define it',

    'signature.popup.tab.saved_signature.question': 'Do you have signatures saved?',
    'signature.popup.tab.saved_signature.use': 'Use them',
    'signature.popup.tab.saved_signature.logIn': 'logging in',

    'signatory.user.not.match': 'The email does not match the one of the signer of the document',
    'signatory.signature.now': 'Sign now',

    'signatory.signature.desktop.warning.text_1': 'For the service to work properly you must use your mobile device',
    'signatory.signature.desktop.warning.text_3': 'Scan this QR code to start the process',
    'signatory.preconfig.format': 'Save or select a PRE-Configuration',
    'signatory.preconfig.success': 'PRE-Configuration saved',
    'signatory.preconfig.placeholder.input': 'PRE-Configuration name',
    'signatory.preconfig.description': 'Save the current configuration and use it for other documents. If you already have saved PRE-Configurations just select them to apply them to the actual document.',
    'signatory.preconfig.list.title': 'My saved PRE-Configuration',
    'signatory.preconfig.name.missing': 'Name your new PRE-Configuration',
    'signatory.preconfig.name.duplicated': 'This PRE-Configuration name is currently in use',
    'signatory.preconfig.apply.invalid.title': 'Incompatible Pre-Configuration',
    'signatory.preconfig.apply.invalid.question': 'If you continue, it is recommended to verify the position of the signature placeholders.',
    'signatory.preconfig.apply.invalid.continue': 'Continue',
    'signatory.preconfig.apply.invalid.cancel': 'Go Back',
    'signatory.preconfig.apply.invalid.description': 'This pre-configuration may not be compatible with your document because it was originally saved with different page sizes.',

    'signatory.signature.form.update.btn': 'Update my information',
    'signatory.signature.form.update.error': 'You are not allowed to modify the form',

    'signatory.form.preview.hi': 'Great',
    'signatory.form.preview.description': 'You have answered all the questions, check your answers before continuing',
    'signatory.form.preview.update.btn': 'Edit',
    'signatory.form.preview.confirm.btn': 'Continue',

    'signatory.form.questions.hi': 'Hi',
    'signatory.form.questions.description': 'Before signing the document it is necessary to answer the following questions',
    'signatory.form.questions.start': 'Start',

    'forms.step_mode.title': 'Question',
    'forms.step_mode.separator': 'of',
    'forms.full_mode.title': 'Questions',

    'forms.nav.back': 'Previous',
    'forms.nav.next': 'Next',
    'forms.nav.send': 'Send',
    'forms.nav.cancel': 'Back',
    'forms.global.error.message': 'Check your answers',

    'forms.validation.errors.required': 'This field is required',
    'forms.validation.errors.maxLength': 'Maximum characters are ',
    'forms.validation.errors.minLength': 'Minimum characters are ',
    'forms.validation.errors.max': 'Maximum value is ',
    'forms.validation.errors.min': 'Minimum value is',
    'forms.validation.errors.pattern': 'The field format is invalid',
    'forms.validation.errors.mail.invalid': 'Please enter a valid email address',
    'forms.validation.errors.number.invalid': 'Please enter a number',

    'forms.service.error': 'An error occurred try again later',
    'forms.access.error': 'This document does not have a form',
    'forms.no_data.error': 'This document does not have a form created',
    'forms.repeat.error': 'The form has already been answered previously',
    'forms.signer.error': 'You do not have permissions to answer the form',
    'forms.signer.no_exist.error': 'You do not have permissions to sign the document',
    'forms.incomplete.error': 'Some data of the document has not been completed, it is necessary to wait for the previous signatory to fill in the data.',

    'forms.file.update.title': 'Updating document',
    'forms.file.update.thanks': 'Thanks for waiting!',
    'forms.file.update.message': 'This process can take time. In a few moments we will send you an email when the document is ready to sign.',

    'forms.reset.title': 'Reset form',
    'forms.reset.error.message': 'An error occurred while trying to restart the form.',
    'forms.dynamic_table.register.add': 'Add more',

    'email_tracking.show_history': 'Show history',
    'email_tracking.email.sent': 'Email delivered',
    'email_tracking.email.opened': 'Email opened',
    'email_tracking.email.clicked': 'Click on link',
    'email_tracking.email.sent.placeholder': 'Your email is in process to send',
    'email_tracking.email.sent.error.placeholder': 'Your email could not be delivered',
    'email_tracking.email.opened.placeholder': 'Has not been opened',
    'email_tracking.email.clicked.placeholder': 'Has not been clicked on link',

    'login.weetrust.message.title': 'weetrust much more than digital signature',
    'login.weetrust.message.link': 'Check out our website!',
    'login.recovery.password.title': 'Your password has been successfully reset',
    'login.recovery.password.message': 'Login now!',

    'identity.alert.verification_sent': 'Your verification has been sent.',
    'identity.alert.unknown_error': 'Request not found, please try again',
    'identity.alert.verification_canceled': 'Your verification has been canceled.',
    'identity.alert.verification_deleted': 'Your verification has been deleted.',
    'identity.alert.verification_complete': 'Verification Completed',
    'identity.alert.verification_error': 'Failed verification',
    'identity.alert.verification_error_info': 'Please try again later',
    'identity.alert.verification_finish_error': 'Problem to finish your verification',
    'identity.sesion.timeout.title': 'Oops!',
    'identity.sesion.timeout.subtitle': ' Session has expired, don\'t worry you can reload the page to restart the process.',

    'signatory.info.email.label': 'Signatory:',
    'dropzone.message.upload': 'Upload your files by dragging them to this area',
    'generic.error': 'An error occurred, please try again later',

    'expiry.modal.title': 'Your session has expired',
    'expiry.modal.description': 'Your session has ended due to inactivity. Please sign in again to continue.',
    'expiry.modal.button': 'Ok',

    'signatory.legal.btn.label': 'Continue',
    'signatory.legal.title': 'Privacy policies',

    //TODO: Change for english labels
    'freeplan.payment.notice.title': '¡Oh no!',
    'freeplan.payment.notice.title.reminder': '¡Un momento!',
    'freeplan.payment.notice.subtitle.reminder': 'el tiempo se agota',
    'freeplan.payment.notice.subtitle.expired.fragment_1': 'Tu periodo de prueba ',
    'freeplan.payment.notice.subtitle.expired.fragment_2': 'ha terminado',
    'freeplan.payment.notice.subtitle.document_limit': 'Tus documentos de prueba se han agotado',
    'freeplan.payment.notice.description': ' Actualmente solo podrás visualizar tus documentos vigentes',
    'freeplan.payment.notice.suscription.reminder': 'Suscríbete por mxn${productPrice} al mes',
    'freeplan.payment.notice.suscription.expired': '¡Renueva tu plan y disfruta de más beneficios!',
    'freeplan.payment.notice.suscription.document_limit': '¡Activa tu plan y disfruta de más beneficios!',
    'freeplan.payment.notice.timer.fragment_1': 'Te quedan ',
    'freeplan.payment.notice.timer.fragment_2': ' horas para que ',
    'freeplan.payment.notice.timer.fragment_3': 'termine tu prueba',
    'freeplan.payment.suscription.title': 'Adios papel',
    'freeplan.payment.suscription.subtitle': '¡Hola weetrust!',
    'freeplan.payment.suscription.enable.now': 'Sí, activar ahora',
    'freeplan.payment.notice.subuser': 'Informa al titular de la cuenta para realizar los cambios necesarios',

    'payment.promo.new.title': 'Adiós papel',
    'payment.promo.new.subtitle': '¡Hola weetrust!',
    'payment.promo.title': 'Gracias por confiar en nosotros',
    'payment.promo.subtitle': 'En weetrust queremos hacerte la vida aún más facil',
    'payment.promo.discount.percent': '25%',
    'payment.promo.discount.title': 'Regístrate y obtén',
    'payment.promo.discount.new.title': 'Obtén un descuento del',
    'payment.promo.discount.new.subtitle': 'en los primeros ',
    'payment.promo.discount.subtitle': 'de descuento en los primeros ',
    'payment.promo.discount.months': '4 meses',
    'payment.promo.discount.cta': 'sólo registra tu tarjeta de crédito',

    'payment.register.card.loader.title': 'Registrando tarjeta',
    'payment.register.card.success.title': '¡Tarjeta agregada exitosamente!',
    'payment.register.card.error.title': 'La tarjeta no pudo ser registrada',
    'payment.register.card.error.subtitle': 'No te preocupes, actualiza tu método de pago',
    'payment.register.card.continue.button': 'Continuar',

    'payment.process.loader.title.generic': 'Procesando actualización',
    'payment.process.loader.title': 'Procesando tu pago',
    'payment.process.loader.message.generic': 'Mientras ve a wee atrapando su cola',
    'payment.process.success.title.generic': '¡Se ha actualizado su plan!',
    'payment.process.success.title': '¡Pago exitoso!',
    'payment.process.success.message': 'Es momento de disfrutar los beneficios',
    'payment.process.error.title.generic': 'Ocurrió un error en la actualización',
    'payment.process.error.subtitle.generic': 'No te preocupes, intenta de nuevo',
    'payment.process.error.title': 'El pago no pudo ser procesado',
    'payment.process.error.subtitle': 'No te preocupes, actualiza tu método de pago',
    'payment.process.setup.loader.title': 'Procesando tu tarjeta',
    'payment.process.setup.success.title': '¡Tarjeta agregada exitosamente!',
    'payment.process.setup.error.title': 'Tu tarjeta no pudo ser procesada',
    'payment.process.setup.error.subtitle': 'No te preocupes, actualiza tu método de pago',
    'payment.process.continue.button': 'Continuar',
    'payment.suscription.caption': '* Suscríbete ahora para aplicar tu descuento y tu plan se activará automáticamente desde mxn${productPrice} al mes por 5 documentos',
    'payment.method.update.button': 'Actualizar método de pago',
    'payment.retry.link': 'Volver a intentar',
    'payment.reject': 'No, por ahora',
    'payment.card.new': 'Nueva Tarjeta',
    'payment.card.methods': '¿Cómo quieres pagar?',
    'payment.card.setup': 'Registrar tarjeta',
    'payment.card.proceed': 'Pagar',
    'payment.card.proceed.send': 'Pagar y enviar',
    'payment.card.new.option': 'Añadir nuevo método',
    'payment.complete.document': 'Tu documento se está enviando',
    'payment.process.error.persist': 'If the error persists, contact an advisor',
    'payment.add.method.caption': 'Al suministrar tus datos de tarjeta, le permites a weetrust efectuar futuros cargos en tu tarjeta conforme a las condiciones estipuladas.',

    'payment.method.update.title': 'We have updated our collection system.',
    'payment.method.update.description': 'To avoid interruptions, please register your payment method again.',

    'banner.suscription.cta': 'suscríbete desde mxn${productPrice} al mes',
    'banner.timer.hours': 'horas',
    'banner.timer.minutes': 'minutos',
    'banner.timer.seconds': 'segundos',
    'banner.freeplan.expiration.title': 'Sólo te quedan',
    'banner.freeplan.expiration.subtitle': 'horas de tu periodo de prueba, ',
    'banner.freeplan.info.title': 'Estás en tu periodo de prueba gratis. ',
    'banner.freeplan.info.highlight.text1': 'Restan ',
    'banner.freeplan.info.highlight.text2': ' días, ',
    'banner.freeplan.promo.title': 'Aprovecha el ',
    'banner.freeplan.promo.offer': '25% de descuento',
    'banner.freeplan.promo.time': ' en los primeros 4 meses,',
    'banner.frezzedplan.title': 'Para continuar disfrutando los beneficios, ',
    'banner.frezzedplan.action': ' actualiza tu información de pago',
    'banner.zeroplan.title': 'Su periodo de prueba ha expirado ',
    'banner.update.payment.methods.title': 'Update your payment methods to keep your subscription active',
    'banner.update.payment.methods.action': 'Update now',

    'cart.product.title': 'Tu documento requiere',
    'cart.product.vid.subtitle': 'verificaciones de identidad',
    'cart.product.vid.continue': 'o enviar a firma sin ',
    'cart.product.vid.info': 'Quieres saber más sobre verificación de identidad',
    'cart.product.vid.name': 'Verificaciones de Identidad',
    'cart.product.vid.generic.name': 'productos seleccionados',
    'cart.product.total': 'Adeudo Total',
    'cart.product.available.title': 'Disponibles',
    'cart.product.available.of': 'de',
    'cart.product.available.total': 'solicitadas',
    'cart.product.cancel.title': 'Enviar a firma ',
    'cart.product.cancel.vid': 'sin verificación de identidad',
    'cart.product.whatsapp.name': 'Whatsapp sending',
    'cart.product.whatsapp_credits.unique': 'Whatsapp credit',
    'cart.product.whatsapp_credits': 'Whatsapp credits',
    'cart.product.vid_incode_pack_b.unique': 'VID credit',
    'cart.product.vid_incode_pack_b': 'VID credits',
    'cart.product.vid_veridoc_pack_b.unique': 'VID credit',
    'cart.product.vid_veridoc_pack_b': 'VID credits',
    'cart.product.attachment_credits.unique': 'Attachment',
    'cart.product.attachment_credits': 'Attachments',

    'membership.card.title': 'Membresía weetrust',
    'membership.card.subtitle': 'Suscríbete',
    'membership.card.documents': '5 Documentos',
    'membership.card.price.title': 'desde',
    'membership.card.price.period': 'al mes por 5 documentos',
    'membership.card.invoice.price.period': 'al mes',
    'membership.card.invoice.dinamic.documents': 'Consumo ilimitado',
    'membership.card.invoice.calulate.title': 'Paga al corte con base en la ',
    'membership.card.invoice.calulate.link': 'Calculadora Dinámica',
    'membership.card.invoice.register': 'Registra tu tarjeta',
    'membership.card.promo.caption': 'Aprovecha el 25% de descuento en los primeros 4 meses',

    'freezed.notice.title': '¡Tienes un Adeudo!',
    'freezed.notice.message': 'No pudimos procesar el último cobro. Actualiza la información de pago para seguir utilizando weetrust.',
    'freezed.notice.card.title': 'Adeudo Total:',
    'dinamic.membership.title': 'Estás a punto de continuar con tu 6to documento del mes',
    'dinamic.membership.message': 'Al aceptar se te cobrará con base en nuestra',
    'dinamic.membership.message.link': 'Calculadora Dinámica',
    'dinamic.membership.message.date': 'en tu fecha de corte',
    'dinamic.membership.accept.button': 'Estoy de acuerdo',
    'dinamic.membership.info.link': 'Hablar con un experto',
    'subscription.problem.notice.message': 'It is not possible to register documents, there has been a problem with your account',
    'subscription.problem.notice.caption.text1': 'please',
    'subscription.problem.notice.caption.link': 'contact us',
    'subscription.problem.notice.caption.text2': 'to solve it as soon as possible',
    'subscription.problem.notice.button': 'Contact us',

    'billing.list.empty': 'Aún no se han generado facturas',

    'invoice.membership.plan': 'Plan actual',
    'invoice.membership.plan.promo': 'Promoción de 4 meses con 25% de descuento activa',
    'invoice.membership.plan.dynamic': 'A partir del 6to documentos pagas por lo que usas',
    'invoice.membership.date.title': 'Próxima fecha de facturación',
    'invoice.membership.date.expire.title': 'Tu prueba finaliza el',
    'invoice.membership.cancel': 'Si deseas cancelar tu plan, por favor escríbenos',
    'invoice.membership.card.default.label': 'Predeterminada',
    'invoice.membership.card.label': 'Tarjeta de',
    'invoice.membership.card.type.credit.label': 'crédito',
    'invoice.membership.card.type.debit.label': 'débito',
    'invoice.membership.card.number.label': 'con terminación',
    'invoice.status.paid': 'Pagado',
    'invoice.status.pending': 'Pendiente',
    'invoice.status.failed': 'Fallida',
    'invoice.status.refunded': 'Reembolso',

    'plans.trial.alias': 'Periodo de prueba',
    'plans.base.alias': 'Membresía Base',
    'plans.dynamic.alias': 'Membresía Dinámica',
    'plans.zero.alias': 'Periodo de prueba terminado',
    'plans.debtor.alias': 'Membresía congelada',

    'attachments.title.add': 'Add a photo ',
    'attachments.title.type': 'of ',
    'attachments.title.front': 'the front ',
    'attachments.title.back': 'the back ',
    'attachments.title.from': 'of your ',
    'attachments.title.verify': 'Check the photo ',
    'attachments.instructions.title': 'Requirements:',
    'attachments.instructions.title.position': '*Position your camera vertically when taking the photo',
    'attachments.instructions.title.capture': '*Make sure the capture is complete and focused',
    'attachments.instructions.title.light': '*Make sure you are well lit, no shadows or reflections',
    'attachments.instructions.button.continue': 'Add photo',
    'attachments.confirm.button.continue': 'Confirm',
    'attachments.complete.title': '¡Ready!',
    'attachments.complete.single.description': 'your image have been attached to the document',
    'attachments.complete.multiple.description': 'your documents have been attached to the document',
    'attachments.complete.button.continue': 'Continue',
    'attachments.failure.title': '¡Error!',
    'attachments.failure.description': 'An error occurred while uploading the files, please try again later or report the case in our chat',
    'attachments.uploading.label' : 'Saving attachments',
    'attachments.photo.id': 'ID',

    'common.or': 'o',
    'signup.confirm.title': 'Great, almost ready!',
    'signup.confirm.subtitle': 'We\'ve sent you a confirmation email to:',
    'signup.confirm.resend.title': 'Still haven\'t received your confirmation email?',
    'signup.confirm.resend.link': 'Wait 1 minute and let\'s try again',
    'signup.confirm.send.link': 'Let\'s try one more time',
    'signup.confirm.completed.verify.title': 'Your account has been verified, ',
    'signup.confirm.completed.welcome.title': 'welcome to weetrust',
    'signup.confirm.spam': 'Please check your spam folder',
    'signup.verify.fail.btn': 'Sign In',
    'signup.auth.fail.title': 'Error!',
    'signup.auth.fail.subtitle': 'An error occurred while trying to log in',
    'signup.auth.fail.btn': 'Continue',

    'signature.new.sign.stroke.instruction': 'Create your signature',
    'signature.new.sign.stroke.ready': 'Ready',
    'signature.new.sign.stroke.rotate': 'Rotate the screen if you want more space',

    'endorsements.page.title': 'Endorsements',
    'endorsements.inbox.title': 'You have {totalEndorsements} documents ready for endorsements',
    'endorsements.inbox.message.description': 'Validate the list of documents, if you have any comments ',
    'endorsements.inbox.message.cta': 'let us know!',
    'endorsements.inbox.validate.cta': 'Request signatures',
    'endorsements.advice.message.fragment-1': 'You have',
    'endorsements.advice.message.fragment-2': ' {totalEndorsements} documents ',
    'endorsements.advice.message.fragment-3': 'ready for endorsements',
    'endorsements.advice.confirm': 'confirm and submit here!',
    'endorsements.table.title.name': 'Name',
    'endorsements.table.title.signers': 'Signatories',
    'endorsements.table.title.endorser': 'Endorser',
    'endorsements.table.title.endorsee': 'Endorsee',
    'endorsements.table.title.documentId': 'DocumentID',
    'endorsements.table.title.status': 'Status',
    'endorsements.table.title.sending': 'Your documents have been approved for endorsement',
    'endorsements.massive.title': 'Pending endorsements to sign',
    'endorsements.exist.error': 'Document already has a endorsement in process',

    'templates.feature.configuration.title': 'Exclusive functionality',
    'templates.feature.configuration.subtitle': 'Limited in time',
    'templates.upload.title': 'Drag and drop a word file',
    'templates.upload.subtitle': 'to create your template',
    'templates.credits.error': 'Available templates have run out.',
    'templates.credits.error.link': 'Please contact the team',
    'templates.header.usedTemplates': 'Created templates',
    'templates.header.creditsTemplates': 'Template credits',
    'templates.header.newTemplate': 'New template',
    'templates.subheader.preview': 'View document',
    'templates.subheader.form': 'Form',
    'templates.subheader.vars': 'Variables',
    'templates.list.empty.title': 'You are going for the good way',
    'templates.list.empty.subtitle': 'Create your first template',
    'templates.list.empty.caption': 'This document will be the basis to create your form, you must customize it below',
    'templates.config.header.title': 'We found in the document a total of:',
    'templates.config.header.vars':'Variables:',
    'templates.config.header.signers':'Signatories:',
    'templates.config.return':'Back',
    'templates.config.instructions':'Please check the following variables of your template',
    'templates.config.instructions.form':'Personal data request',
    'templates.config.vars.title': 'Variable',
    'templates.config.vars.counter': 'Repeat variable',
    'templates.config.vars.type.placeholder': 'Select type',
    'templates.config.vars.type.text': 'Text',
    'templates.config.vars.type.email': 'Email',
    'templates.config.vars.type.number': 'Numeric',
    'templates.config.vars.type.radio': 'Radio',
    'templates.config.vars.type.checkbox': 'Checkbox',
    'templates.config.vars.type.combo': 'Selector',
    'templates.config.vars.type.combo_multiple': 'Multiple Selector',
    'templates.config.vars.type.date': 'Date',
    'templates.config.vars.type.time': 'Hour',
    'templates.config.vars.type.conditional': 'Conditional',
    'templates.config.vars.type.signature': 'Signature',
    'templates.config.vars.type.dynamic_table': 'Dynamic table',
    'templates.config.vars.label': 'Write your question',
    'templates.config.vars.placeholder': 'Placeholder',
    'templates.config.vars.maxLength': 'Maximum characters',
    'templates.config.vars.isSignatory': 'Form',
    'templates.config.vars.isSignatoryForm': 'Who will fill out the form? ',
    'templates.config.vars.option': 'Enter an option',
    'templates.config.vars.options_table': 'Enter the title for this column',
    'templates.config.vars.required': 'Required',
    'templates.config.vars.help': 'Add extra comment',
    'templates.config.actions.options.add': 'Add option',
    'templates.config.form.notice': 'If any element is not correct you need to correct the template and update it',
    'templates.config.form.error': 'Some fields are not completed correctly, check your data again',
    'templates.config.form.action.cancel': 'Cancel',
    'templates.config.form.action.save': 'Save',
    'templates.config.form.action.continuar': 'Continue',
    'templates.config.form.tooltip.label': 'Question is the text that will request the information to fill out the template',
    'templates.config.form.tooltip.placeholder': 'Text that appears pre-filled in the text field or list selectors',
    'templates.config.form.tooltip.maxLength': 'Number you want to assign for a maximum of characters allowed',
    'templates.config.form.tooltip.isSignatory': 'Indicates the questions that will be asked to a document signer',
    'templates.config.form.tooltip.options': 'Add the list of texts that will be the options available for this question',
    'templates.config.form.tooltip.keys_table': 'Add the title that will appear in the table of this column',
    'templates.config.form.tooltip.help': 'This message will be displayed as a dropdown tooltip to form users',
    'templates.config.form.tooltip.signatoryForm': 'Select the firm that will be requested to fill out the form',
    'templates.config.form.signatory.action.cancel': 'Back to variables',
    'templates.config.form.signatory.action.continue': 'Continue',
    'templates.config.form.signatory.action.save': 'Save',
    'templates.config.form.title': 'This is how your form is currently looking',
    'templates.config.form.caption': '* Indicates that the question is required',
    'templates.edit.error': 'An error occurred while trying to save the template. Try again.',
    'templates.update.success': 'Saved successfully',
    'templates.update.upload.btn': 'Upload new version',
    'templates.update.document.error': 'An error occurred while trying to update the document. Try again.',
    'templates.update.header.title': 'New document',
    'templates.update.header.subtitle':' a total of:',
    'templates.update.header.persistent':'Same variables:',
    'templates.update.header.new':'New variables:',
    'templates.update.header.delete':'Deleted variables:',
    'templates.single.preview.save.error': 'An error occurred while trying to save the preview. Try again.',
    'templates.massive.files.error': 'An error occurred while trying to get the bulk upload file. Try again.',
    'templates.massive.files.upload.error': 'An error occurred while trying to process the bulk upload file. Try again.',
    'templates.massive.documents.available.error': 'You have no documents available to send',
    'templates.massive.config.documentType.alert': 'Please select Who signs?',
    'templates.massive.config.country.alert': 'Please select the Country',
    'templates.massive.config.signatureType.alert': 'Please select the type of signature',
    'templates.massive.config.tag.alert': 'Please create a tag name',
    'templates.welcome.title': 'This functionality is exclusive to you',
    'templates.welcome.message': 'Create templates from a text document!',
    'templates.welcome.caption': 'For a limited time you will have free access',
    'templates.welcome.button': 'Understood',
    'templates.processing.title': 'We are sorting out the paperwork',
    'templates.processing.message': 'Thank you for your patience, this process may take a few seconds.',
    'templates.processing.error.message': 'No variables were found in this document make sure they have the correct format',
    'templates.processing.error.reserved': '(The variables {{EMAIL}} and {{NAME}} are reserved and cannot be used)',
    'templates.processing.error.example': 'example',
    "templates.processing.error.10001": "The document contains errors in closing {{ EXAMPLE } tags.",
    "templates.processing.error.10002": "The document contains errors in opening { EXAMPLE }} tags.",
    "templates.processing.error.10003": "The document contains errors such as unclosed {{ EXAMPLE } tags, incorrectly nested { EXAMPLE }} tags, or reserved variables such as {{ EMAIL }} and {{ NAME }}.",
    "templates.processing.error.10004": "The error in the document could not be identified.",
    "templates.processing.error.10005": "The variables {{EMAIL}} and {{NAME}} are reserved and cannot be used in the document.",
    "templates.processing.error.10006": "Only documents with the .DOCX extension are allowed.",
    "templates.processing.error.10007": "No variables were found in the document. Make sure it has the format {{ VARIABLE }}.",
    'templates.processing.error.button': 'Re-upload file',
    'templates.processing.error.caption': 'Remember that you can download our ',
    'templates.processing.error.defaultTemplate': 'preloaded template',
    'templates.update.title': 'Update document',
    'templates.update.version': 'New version',
    'templates.update.cancel': 'Cancel',
    'templates.update.confirm': 'Update',
    'templates.update.caption': 'This new document will replace the current version of the template',
    'templates.delete.title': 'Delete template',
    'templates.delete.message': 'Are you sure to delete the template ',
    'templates.delete.cancel': 'No, cancel',
    'templates.delete.confirm': 'Yes, delete',
    'templates.delete.success': 'Template has been removed',
    'templates.completed.title': 'Very good!',
    'templates.completed.message': 'your template is available for use',
    'templates.completed.button': 'Continue',
    'templates.card.default.title': 'Preloaded template',
    'templates.card.menu.edit': 'Edit',
    'templates.card.menu.update': 'Update',
    'templates.card.menu.delete': 'Delete',
    'templates.card.menu.preview': 'Preview',
    'templates.card.instructions': 'Choose the type of use you will make with your template',
    'templates.card.massive': 'Massive',
    'templates.card.options.or': 'or',
    'templates.card.single': 'Single',
    'templates.card.massive.title': 'Massive generation',
    'templates.card.massive.subtitle': 'Now it is very easy to generate multiple documents at once',
    'templates.card.massive.instructions.text1': 'Just ',
    'templates.card.massive.instructions.text2': 'download the base format',
    'templates.card.massive.instructions.text3': ' for input of information to the massive process, ',
    'templates.card.massive.instructions.text4': 'upload and send to the signing process!',
    'templates.card.massive.download': 'Download',
    'templates.card.massive.upload': 'Upload',
    'templates.card.default.name': 'Personal data request',
    'templates.single.form.continue': 'Continue',
    'templates.single.error': 'An error occurred while trying to get the template. Try again.',
    'templates.upload.invalid_type': 'Your file must be a .doc or .docx',

    'personal.data.name': 'Name',
    'personal.data.email': 'Email',

    'comment.read.more': 'Read more',
    'signatory.read.more': 'Identity Verification',
    'comment.read.less': 'Read less',

    'vobo.get.service.error': 'The approval document not found',
    'vobo.get.pdf.service.error': 'An error occurred while loading the pdf. Try again.',
    'vobo.button.accept': 'Approve',
    'vobo.button.reject': 'Decline',
    'vobo.button.continue': 'Continue',
    'vobo.button.send': 'Send',
    'vobo.accepted.title': 'This document has been approved',
    'vobo.accepted.description': 'We will send an email notifying you that this document has been approved. Thank you!',
    'vobo.accepted.service.error': 'An error occurred while trying to approve the document. Try again.',
    'vobo.refused.title': 'This document has been rejected',
    'vobo.refused.description': 'We will send an email notifying you that this document has been rejected. Thank you!',
    'vobo.refused.description.commented': 'Once you click "Submit", we\'ll send an email to the creator of the document notifying them that it has been rejected and has comments.',
    'vobo.refused.notification.title': 'Before notifying that you have rejected this document',
    'vobo.refused.notification.description': 'Do you want to add comments?',
    'vobo.refused.notification.simple': 'No',
    'vobo.refused.notification.comments': 'Yes, comment',
    'vobo.refused.service.error': 'An error occurred while trying to reject the document. Try again.',
    'vobo.signatories.title': 'Signatories',
    'vobo.comments.title': 'Comments',
    'vobo.comments.add': 'Add',
    'vobo.comments.add.title': 'Comment',
    'vobo.comments.add.form.label.title': 'Title',
    'vobo.comments.add.form.label.notes': 'Notes',
    'vobo.comments.add.cancel.button': 'Cancel',
    'vobo.comments.add.create.button': 'Create',
    'vobo.comments.add.update.button': 'Update',
    'vobo.comments.add.tooltip': 'Describe a title to indicate where you will make your comment, example: Title: "Adjustments in paragraph 5" and in "Notes" your final comment',
    'vobo.comments.add.error': 'Title and notes are required',
    'vobo.comments.add.service.error': 'An error occurred while trying to create the comment. Try again.',
    'vobo.comments.update.service.error': 'An error occurred while trying to update the comment. Try again.',
    'vobo.comments.delete.service.error': 'An error occurred while trying to delete the comment. Try again.',
    'vobo.comments.delete.title': 'Delete comment',
    'vobo.comments.delete.description': 'Do you want to delete this comment?',
    'vobo.comments.delete.button': 'Confirm',
    'vobo.document.status.title': 'Status',
    'vobo.document.status.pending': 'Pending',
    'vobo.document.status.refused': 'Refused',
    'vobo.document.notify.refused.title': 'Don\'t worry, your document doesn\'t end here',
    'vobo.document.notify.refused.description': 'It will remain pending within the platform with a status of:',
    'vobo.document.notify.refused.status': 'Refused',
    'vobo.document.notify.refused.button': 'OK',
    'vobo.signatory.notify.refused': 'This document has been rejected so this signature link is suspended',
    'document.cancel.request.title': 'You have requested the cancellation of the following document:',
    'document.cancel.request.upload.title': 'Attach any evidence you have to request cancellation',
    'document.cancel.request.upload.subtitle': 'Drag one or multiple files',
    'document.cancel.request.upload.caption': 'The file weight limit is 20 Mb',
    'document.cancel.request.upload.link': 'Add more files',
    'document.cancel.request.cancel': 'Cancel',
    'document.cancel.request.confirm': 'Send',
    'document.cancel.pdf.service.error': 'An error occurred while loading the pdf. Try again.',
    'document.cancel.send.service.error': 'An error occurred while submitting the request. Try again.',
    'document.cancel.send.service.success': 'Your cancellation request has been sent',
    'document.cancel.upload.images.error': 'An error occurred while trying to upload the image',
    'document.cancel.review.title': 'Cancellation request',
    'document.cancel.review.user': 'Petitioner\'s name and email:',
    'document.cancel.review.file': 'File name:',
    'document.cancel.review.evidence.title': 'Evidence',
    'document.cancel.review.evidence.message.total': 'There is a total of',
    'document.cancel.review.evidence.message.documents': 'documents as evidence',
    'document.cancel.review.reject': 'Decline',
    'document.cancel.review.confirm': 'Authorize',
    'document.cancel.review.confirm.dialog.title': 'Request confirmation',
    'document.cancel.review.confirm.dialog.description': 'You have made the decision to authorize and process this cancellation request',
    'document.cancel.review.confirm.dialog.cancel': 'No, cancel',
    'document.cancel.review.confirm.dialog.proceed': 'Yes, confirm',
    'document.cancel.review.reject.dialog.title': 'Request rejection',
    'document.cancel.review.reject.dialog.description': 'You have made the decision to reject and not process this cancellation request',
    'document.cancel.review.reject.dialog.cancel': 'No, cancel',
    'document.cancel.review.reject.dialog.proceed': 'Yes, decline',
    'document.cancel.evidence.maxsize.error': 'The file size cannot exceed 20MB',
    'document.cancel.evidence.type.error': 'The file type must be a .png or .jpeg',
    'document.cancel.evidence.pdf.type.error': 'The file type must be a .pdf',
    'document.cancel.evidence.upload.error': 'We cannot process your file. Try again',
    'document.cancel.not_found': 'Cancellation not found',
    'document.cancel.review.already': 'The cancellation request has already been completed',
    'document.cancel.review.evidence.empty': 'No evidence attached',
    'document.cancel.review.error': 'The cancellation resolution could not be sent. Try again!',
    'document.cancel.review.success.proceed': 'The document has been canceled successfully',
    'document.cancel.review.success.refuse': 'The document has been rejected for cancellation',
    'document.cancel.exist.error': 'A cancelation already exists for this document',
    'document.whatsapp.credits.request.subtitle': 'You have run out of available credits',
    'document.whatsapp.credits.request.description': 'Sorry, it seems that you have exhausted your credits to send signature requests via whatsapp. Don\'t worry, we\'re here to help you recharge and stay connected with your customers.',
    'document.whatsapp.credits.title': 'Messages via ',
    'document.whatsapp.credits.subtitle-1': 'From now on you can request signatures via whatsapp for one ',
    'document.whatsapp.credits.subtitle-2': 'rate of ',
    'document.whatsapp.credits.subtitle-3': ', that will be charged on your next invoice',
    'document.whatsapp.credits.btn': 'OK',
    'document.whatsapp.dynamic.credits.subtitle-1': 'You have requested ',
    'document.whatsapp.dynamic.credits.subtitle-2': ' signature via WhatsApp which has a cost of ',
    'document.whatsapp.dynamic.credits.subtitle-3': ' per request, which will be added to your account at the end of the month',
    'document.whatsapp.dynamic.credits.check': 'I agree, do not show again',
    'document.credits.request.title': '¡Oops!',
    'document.contact.credits.request.btn': 'Contact an advisor',
    'document.attachments.credits.request.subtitle': 'You have run out of available credits',
    'document.attachments.credits.request.description': 'Sorry, it looks like you\'ve exhausted your credits for submitting attachment requests. Don\'t worry, we\'re here to help you recharge and stay connected with your customers.',
    'document.attachments.credits.title': 'Attachments',
    'document.attachments.dynamic.credits.subtitle-1': 'You have requested ',
    'document.attachments.dynamic.credits.subtitle-2': ' attachments which has a cost of ',
    'document.attachments.dynamic.credits.subtitle-3': ' per request, which will be added to your account at the end of the month',
    'document.attachments.dynamic.credits.check': 'I agree, do not show again',
    'document.attachments.credits.btn': 'OK',

    'attachments.config.button': 'Attachments',
    'attachments.config.button.tooltip': 'Request photographs of official document (INE or Driver\'s License) or other',
    'attachments.config.add.button': 'Add other',
    'attachments.config.add.limit': 'Limit {limit} photos',
    'attachments.config.add.placeholder': 'ID, Driver license',

    'document.restricted.dialog.title': 'You do not have permission to request document signing',
    'document.restricted.dialog.description': 'please contact your account administrator',
    'access.restricted.dialog.title': 'You do not have permission to access this module',
    'access.restricted.dialog.description': 'please contact your account administrator',
    'endorsement.special.request.title': 'Has solicitado el endoso especial del siguiente documento:',
    'endorsement.special.request.upload.title': 'Arrastra aquí el archivo del nuevo contrato',
    'endorsement.special.request.page': 'Página de factura',
    'endorsement.special.request.cancel': 'Cancel',
    'endorsement.special.request.continue': 'Continue',
  },
  es: {
    'action.tags.text': 'Etiquetas en documentos seleccionados',
    'action.tags.tipText': 'Agregar o editar',
    'action.tags.tooltip': 'Editar etiquetas en los documentos seleccionados',
    s3_upload_error: 'La configuración de fecha y hora de su sistema no es precisa para su zona horaria, ¡debido a esto no podemos continuar!',
    deleted_document_message: 'Este documento ha sido borrado',
    canceled_document_message: 'Este documento ha sido cancelado',
    invalid_link_message: 'Su enlace no es valido.',
    expire_link_message: 'Su enlace ha caducado',
    expire_email_message: 'Solicite el reenvío del documento al correo: {email}',
    block_by_order_message: 'El firmante anterior aun no completa el proceso de firma',
    block_by_vobo_message: 'La figura del visto bueno aun no completa el proceso de revisión',
    block_by_vobo_refused_message: 'El documento ha sido rechazado por el observador del documento su enlace ya no es válido',
    landscape_mode_text: 'El modo paisaje todavía no es compatible. Por favor gire su dispositivo en modo retrato para la mejor experiencia.',

    'taust.session_expire': 'Tu sesión ha terminado',
    'taust.document_sent_failure': 'error al enviar el documento ',
    'taust.document_sent_success': 'documento enviado con éxito.',
    'taust.email_failure': 'Error de correo electrónico ',
    'taust.search_document_failure': 'No se han encontrado resultados',
    'taust.search_document_empty': 'Ingrese el nombre del documento para buscar',
    'taust.search_email_failure': 'No se puede buscar esta dirección de correo electrónico',
    'taust.update_subscription_failure': 'No se pueden actualizar los datos de suscripción ',
    'taust.update_subscription_success': 'Datos de suscripción actualizados con éxito ',
    'taust.document_delete_success': 'documento eliminado exitosamente ',
    'taust.generate_apiKey_success': 'Api Key generada con éxito',
    'taust.generate_apiKey_failure': 'Falla en la generación de claves API',
    'taust.document_delete_failure': 'error al eliminar el documento',
    'taust.unable.delete': 'Imposible Eliminar documento',
    'taust.subscription_cancel_failure': 'error al cancelar la suscripción ',
    'taust.document_share_success': 'documento compartido con éxito',
    'taust.document_share_failure': 'error al compartir documento',
    'taust.document_resend_success': 'El documento ha sido reenviado con éxito a los firmantes pendientes.',
    'taust.document_resend_failure': 'error al reenviar documento',
    'taust.mail_resent_success': 'El correo se ha reenviado a su dirección de correo electrónico!',
    'taust.user_delete_success': 'usuario eliminado correctamente',
    'firebase.resend.email.error': 'Por favor espera un minuto antes de enviar nuevamente el correo',

    'menu.header.about': 'Acerca de',
    'addUser.add_User': 'Agregar Usuario',
    'menu.header.help': 'Contacto',
    'menu.header.contact': 'Contactar',
    'menu.header.validation': 'Validación',
    'menu.header.know_pro': 'weetrust PRO',
    'menu.footer.privacy': 'Política de privacidad',
    'menu.footer.terms': 'Términos de uso',
    forgot_password: 'Recuperación de contraseña',
    forgot_password_message: 'Ingresar tu correo electrónico registrado para enviarte un correo de recuperación de contraseña.',
    'forgot_password.success.message': 'Hemos enviado un correo electrónico para restablecer su contraseña',
    forgot_password_email_sent: 'Correo enviado',
    'click_here.message': 'inténtalo de nuevo',
    'not_received.message': 'Si aún no ha recibido el correo electrónico de verificación ',
    'userTable.header.name': 'Nombre',
    'userTable.header.email': 'Correo Electrónico',
    'userTable.header.password': 'Contraseña',
    login: 'Iniciar sesión',
    'login.or': 'o',
    'login.email': 'Correo electrónico',
    'profile.companyName': 'Ingrese el nombre de la empresa',
    'profile.activity': 'Ingrese el nombre de la actividad',
    'profile.industry': 'Ingrese el nombre de la industria',
    'profile.dob': 'Ingresar su fecha de nacimiento ',
    'profile.subscription.text': 'Disponible en PRO',
    'login.fullName': 'Nombre de usuario',
    'login.password': 'Contraseña',
    'login.submit': 'Ingresar',
    'login.forgot_password': '¿Olvidaste tu contraseña?',
    'login.no_account': '¿Aún no tienes una cuenta? ',
    'login.sign_up': 'Crear una cuenta',
    'login.recover_password': 'Recuperar contraseña',
    'login.recover_password.send': 'Enviar',
    'login.weesign_pro.already_know': '¿ya conoces?',
    'login.weesign_pro.imporve_experience': 'Mejora tu experiencia en weetrust, únete a PRO',
    'login.weesign_pro.blockchain_integration': '3 usuarios',
    'login.weesign_pro.customize_with_your_logo': '100 documentos',
    'login.weesign_pro.id_validation': 'e.firma (SAT)',
    'login.weesign_pro.unlimited_users': 'Firma Electrónica Avanzada',
    'login.weesign_pro.unlimited_documents': 'Identificación Oficial',
    'login.weesign_pro.blockchain': 'Blockchain',
    'login.weesign_pro.NOM_151': 'NOM 151',
    'login.weesign_pro.more_information': 'Más Información acerca de ',
    'login.weesign_pro.button.i_want_to_join': 'Quiero Unirme',
    'login.wrong.credencials': 'El usuario o contraseña son incorrectos',
    'login.invalid_password': 'La contraseña ingresada es incorrecta',
    'login.user_not_found': 'No hay registro de usuario que corresponda a la información ingresada. El usuario pudo haber sido borrado.',
    'login.email_not_verified': 'Para continuar, primero verifique su correo electrónico. ',
    'login.fill_out_fields': 'Por favor rellene este campo.',
    'login.too_many_request': 'Demasiados intentos fallidos. Reconfirmar que no es un robot o intentar de nuevo en unos minutos.',
    register: 'Crea tu cuenta',
    'register.full_name': 'Nombre completo',
    'register.first_name': 'Nombre(s)',
    'register.last_name': 'Apellido(s)',
    'register.mothers_last_name': 'Apellido materno',
    'register.birth_date': 'Fecha de nacimiento',
    'register.mob': 'Teléfono',
    'register.email': 'Correo electrónico',
    'register.password': 'Contraseña',
    'register.confirm_password': 'Confirmar contraseña',
    'register.submit': 'Crear cuenta',
    'register.i_accept': 'Al registrarme acepto los ',
    'register.terms_and_condition': 'términos de uso ',
    'register.and': 'y ',
    'register.privacy_notice': 'política de privacidad ',
    'register.from_weesign': 'de weetrust',
    'register.already_account': '¿ya tienes una cuenta?',
    'register.password_didnt_match': 'Las contraseñas no coinciden. Por favor asegúrate que sean iguales',
    'register.password_didnt_empty': 'La contraseña no puede estar vacía. Inténtalo de nuevo.',
    'profile.last_and_new_password_not_same': 'La última contraseña y la nueva contraseña no pueden ser las mismas',
    'profile.change_password_fail': 'la contraseña antigua no coincide, por favor intente de nuevo!',
    'profile.fill_field_message': 'Por favor rellene este campo',
    'profile.change_password_error': 'Debe contener al menos un número, una mayúscula y 8 caracteres',
    'login.password_error': 'Ingresa tu contraseña',
    'register.success.heading': 'Bienvenido a weetrust',
    'register.success.message': 'Te enviamos un correo para la activación de tu cuenta,',
    'register.success.message.welcome': 'Confirma tu correo electrónico',
    'register.success.message.welcome.description': 'Te enviamos un correo de confirmación a:',
    'register.verify_email.success.heading': 'Verificación de correo',
    'register.verify_email.success.message': '¡Tu cuenta ha sido activada!',
    'register.verify_email.failure.message': '¡El enlace ha caducado, o ya se ha utilizado!',
    'register.verify_email.resend.message': '¡El enlace se ha enviado nuevamente a su correo electrónico!',
    'register.password.strength.message': 'Debe cumplir con los siguientes requerimientos:',
    'register.password.strength.number': 'Un número',
    'register.password.strength.uppercase': 'Una mayúscula',
    'register.password.strength.lowercase': 'Una minúscula',
    'register.password.strength.min': 'Al menos 8 caracteres',
    'register.title': 'Registro fácil, recompensa inmediata',
    'register.promo': '5 documentos gratis',

    'reset.password.heading': 'Recuperar contraseña',
    'reset.password.subheading': 'Ingresar una nueva',
    'reset.password.success': '¡Felicidades, se ha restablecido tu contraseña!',
    'change.password.success': '¡Felicitaciones, tu contraseña ha sido cambiada!!',
    'reset.payment.password.heading': 'Generar contraseña',
    'reset.payment.password.subheading': 'Ingresar una  contraseña',
    'reset.payment.password.success': '¡Felicidades, se ha establecido tu contraseña!',
    'reset.button': 'Enviar',
    'reset.password.failure': 'Intento fallido. Contactar al Equipo Weetrust',
    'recaptcha.required': 'Por favor marca la casilla',

    'home.know_more': 'Saber Más',
    'home.heading': 'Firma Electrónica Fácil, Rápida y Segura',
    'home.content': 'Con la implementación de blockchain y firma electrónica avanzada, cada documento firmado en weetrust se vuelve único, irrepetible, infalsificable y válido ante tribunales',
    'home.box.select_file': '¿Necesitas firmar algo hoy?',
    'home.box.drag_document': 'Arrastra o selecciona un documento en word o .pdf',
    'home.box.file_ready': '¡Tu archivo está listo!',
    'home.button.sign_now': 'Firmar ahora',

    'home.advice.header': 'Tu navegador no es compatible',
    'home.advice.content': 'Te recomendamos usar',
    'home.advice.content.1': 'weetrust',
    'home.advice.content.2': 'con uno de los siguientes navegadores:',

    'signing_authority.country': 'País',
    'signing_authority.who_sign': '¿Quién firma?',
    'signing_authority.or': 'O',
    'signing_authority.only_me': 'Sólo Yo',
    'signing_authority.me_and_others': 'Otros y yo',
    'signing_authority.others': 'Otros',
    'signing_authority.select_type_of_sign': 'Selecciona el tipo de firma',
    'signing_authority.electronic_signature': 'Firma trazo',
    'signing_authority.e.firma': 'e.firma',
    'signing_authority.country.notice': 'Selecciona el país en el que quieras que surta efectos tu documento.',
    'signing_authority.country.notice.mx': 'Al seleccionar México recibirás tu constancia emitida por un PSC autorizado.',
    'signing_authority.type.tooltip': 'Selecciona el tipo de firma con que se completará el documento',
    'profile.tab.documents': 'Documentos',
    'profile.tab.endorsements': 'Endosos',
    'profile.tab.massive.signature': 'Firma masiva',
    'profile.tab.massive.signature.endorsements': 'Firma masiva de endosos',
    'profile.tab.templates': 'Plantillas',
    'profile.tab.profile': 'Perfil',
    'profile.tab.billing': 'Membresías',
    'profile.tab.help_and_support': 'Preguntas',
    'profile.tab.validation': 'Validación',
    'profile.page.signature': 'Firmas',
    'profile.tab.users': 'Usuarios',
    'profile.tab.config': 'Configuración',
    'profile.tab.invoice': 'Membresía y facturación',
    'profile.page.personal_information': 'Datos personales',
    'profile.page.document': 'Configuración de Firmas',
    'profile.page.UserId.ApiKey': 'ID de usuario y clave API',
    'profile.page.UserId': 'ID de usuario',
    'profile.page.ApiKey': 'Clave API',
    'profile.page.button.save_efirma': 'Guardar e.firma',
    'profile.page.button.add_signature': 'Agregar firma',
    'profile.page.button.generate_apikey': 'Generar clave API',
    'profile.page.generaqte_ApiKey': 'Generar clave API',
    'profile.page.button.download_apikey': 'Descargar .CSV',
    'profile.page.button.regenerate_apikey': 'Regenerar clave API',
    'profile.page.signature.miguel_angel_centeno': 'Miguel Angel Centeno',
    'profile.page.signature.edit_signature': 'Editar Firma',
    'profile.page.details.birth_date': 'Fecha de nacimiento',
    'profile.page.details.cell_phone': 'Teléfono celular',
    'profile.page.change_password': 'Cambiar contraseña',
    'profile.page.change_phone': 'Cambiar Móvil',
    'profile.page.change_DOB': 'Cambiar fecha de nacimiento',
    'profile.page.change_firm': 'Cambiar de firma',
    'profile.page.change_industry': 'Cambiar nombre de industria',
    'profile.page.change_activity': 'Cambiar actividad ',
    'profile.page.change_mail': 'Cambiar Correo',
    'profile.page.company': 'Empresa',
    'profile.page.industry': 'Industria',
    'profile.page.occupation': 'Ocupación',
    'profile.page.what_is_this': '¿Qué es esto?',
    'profile.page.button.upload_file': 'Subir archivo',
    'profile.page.button.save': 'Guardar',
    'profile.page.official_identification': 'Identificación oficial',
    'profile.page.users': 'Usuarios',
    'profile.change_password.title': 'Cambiar contraseña',
    'profile.change_password.last_password': 'Contraseña anterior',
    'profile.change_password.new_password': 'Nueva contraseña',
    'profile.change_password.confirm_password': 'Confirmar contraseña',
    'corporate.search_title': 'Búsqueda por dirección de correo electrónico',
    'profile.change_password.confirm_new_password': 'Confirmar contraseña',
    'profile.button.save': 'Guardar',
    'profile.add.signature.limit.error': 'Has alcanzado el límite de firmas.',
    'document.error.limit_max_text': 'La búsqueda debe de tener más de 3 caracteres.',
    'document.page.add_document': 'Agregar documento',
    'document.page.add_document.loading': 'Por favor espere mientras procesamos y subimos su documento.',
    'document.page.add_document.loading_biometric': 'Por favor espere mientras procesamos la biometría y subimos su documento',
    'document.page.add_document.loading_biometric_background': 'Por favor espere mientras procesamos la biometría, consultamos RENAPO, INE, listas negras y subimos su documento',
    'document.page.heading.all': 'Todos',
    'document.page.heading.pending_signature': 'Pendientes de firma',
    'document.page.heading.draft': 'Borradores',
    'document.page.heading.completed': 'Completados',
    'document.page.heading.shared': 'Compartidos',
    'document.status.heading.pending_signature': 'Pendiente',
    'document.status.heading.draft': 'Borrador',
    'document.status.heading.completed': 'Completado',
    'document.status.heading.shared': 'Compartido',
    'document.status.heading.pendingToEndorsementValidation': 'Pendiente de validación',
    'document.status.heading.pendingToEndorsement': 'Pendiente de endoso',
    'document.status.heading.endorsed': 'Endosado',
    'document.status.heading.refused': 'Rechazado',
    'document.status.heading.canceled': 'Cancelado',
    'document.page.heading.search': 'Buscar',
    'document.page.dropdown.sign': 'Firmar',
    'document.page.dropdown.tags': 'Etiquetas',
    'document.page.dropdown.tags_all': 'Todas',
    'document.page.dropdown.templates': 'Plantillas',
    'document.page.dropdown.templates_available': 'Disponible en',
    'document.page.dropdown.templates_pro': 'PRO',
    'document.page.dropdown.create_tag': 'Crear etiqueta',
    'document.page.dropdown.resend': 'Reenviar',
    'document.page.dropdown.resendWhatsapp': 'Reenviar whatsapp',
    resend: 'reenviar',
    'document.page.dropdown.remove': 'Eliminar',
    'document.page.dropdown.share': 'Compartir',
    'document.page.no_record_found': 'Documento no encontrado',
    'document.button.continue': 'Continuar',
    'document.button.confirm': 'Confirmar',
    'document.page.dropdown.download': 'Descargar',
    'document.page.dropdown.download.psc': 'Constancia PSC (asn)',
    'document.page.dropdown.download.ratification': 'Documento Ratificado',
    'document.page.dropdown.download.ratificationZip': 'Descargar Ratificación',
    'document.page.dropdown.download.ratificationGuide': 'Inscripción en el RUG',
    'document.page.dropdown.download.psc-pdf': 'Validación de Constancia',
    'document.page.dropdown.download.blockchain': 'Certificado Blockchain',
    'document.page.dropdown.psc': 'PSC',
    'document.page.dropdown.blockchain': 'Blockchain',
    'document.page.dropdown.cancel': 'Solicitar cancelación',
    'document.page.dropdown.cancel.owner': 'Cancelar',
    'document.page.dropdown.endorsement.special': 'Endoso especial',
    'document.page.dropdown.endorsement.cancel': 'Cancelar endoso',
    'document.upload.file': 'Arrastra o selecciona tu documento',
    'document.page.button.sign': 'Firmar',
    'document.delete': 'Eliminar archivo',
    'document.delete.confirmation': 'Vas a eliminar un archivo, ¿quieres continuar?',
    'document.resend.confirmation': '¿Quieres reenviar el documento?',

    'preview.file.field.sign': 'Firma',
    'preview.file.field.date': 'Fecha',
    'preview.file.field.text': 'Texto',
    'preview.file.field.checkbox': 'Checkmark',
    'preview.file.cancel': 'Cancelar',
    'preview.file.button.following': 'Siguiente',
    'preview.button.text.send': 'Enviar',
    'preview.file.field.request_signature': 'Fijar Firmas',
    'preview.file.field.ratification': 'Documento para Ratificación',
    'preview.file.field.btn.request_signature': 'Solicitar Firmas',
    'preview.file.field.add_signature.box': 'Firmante',
    'preview.file.field.add_signature.box.form': 'Requiere formulario',
    'preview.file.field.add_signature': 'Agregar firmante',
    'preview.file.field.add_observer': 'Agregar observador',
    'preview.file.field.add_split': 'Dividir documento',
    'document.split.static.sign': 'Dividir un documento requiere que exista al menos un "fija firma" en cada parte del documento',
    'preview.file.field.document': 'Documento',
    'preview.file.field.pages': 'Páginas',
    'preview.file.field.message': 'Mensaje (opcional)',
    'preview.file.field.title_mail': 'Título del correo',
    'preview.file.field.generate_link': 'Generar link',
    'preview.file.field.signatory_name': 'Nombre del firmante',
    'preview.file.field.signatory_email': 'Correo  electrónico',
    'preview.file.thanks.popup.document_sent': 'Tu documento ha sido enviado',
    'preview.file.thanks.popup.document_completed': 'Tu documento ha sido procesado',
    'preview.file.thanks.biometric_message': 'El proceso de firma puede tardar debido a la biometría y/o validación de ID.',
    'preview.file.thanks.message': '¡GRACIAS!',
    'preview.file.thanks.help.tree': 'Al firmar con weetrust en lugar de firmar en papel, estás contribuyendo a la conservación del medio ambiente.',
    'preview.file.thanks.signatory': 'Cuando todos hayan firmado, recibirás en tu correo electrónico registrado una copia en PDF',
    'preview.file.thanks.signatory.signUp': '¿Te gustó weetrust?',
    'preview.file.thanks.signatory.signUp.btn': 'Regístrate gratis',
    'preview.file.field.emptyEmailStaticSign': 'Por favor ingresa el correo',
    'preview.file.field.orderSign': 'Orden de Firmantes',
    'preview.file.field.orderSignDetail': 'Los correos se enviarán en el orden que se asignen',
    'preview.file.document.processing': 'Procesando documento',
    'preview.file.field.edited': 'Modificado',
    'preview.file.field.removed': 'Eliminado',
    'preview.file.field.restore': 'Restaurar',

    'preview.file.no.signatory': 'Favor de añadir firmante',
    'preview.file.fixed.signatory.missing.emails': 'Favor de fijar firma',
    'preview.file.signatory.name_and_email.missing': 'Por favor ingrese el nombre del firmante y correo electrónico',
    'preview.file.signatory.name.missing': 'Por favor ingrese el nombre del firmante',
    'preview.file.signatory.email.missing': 'Por favor ingrese una dirección de correo electrónico para el firmante',
    'preview.file.signatory.email.repeated': 'Por favor ingrese otra dirección de correo electrónico, esta ya existe',
    'preview.file.signatory.email.invalid': 'Por favor ingrese una dirección de correo electrónico válida.',
    'preview.file.signatory.whatsapp.missing': 'Por favor ingrese el whatsapp del firmante',
    'preview.file.signatory.whatsapp.invalid': 'Por favor ingrese un whatsapp válido',
    'preview.file.same_signatory_and_owner.email': 'El correo electrónico de firmante y propietario es el mismo',
    'preview.file.missing.mail.title': 'Por favor ingrese el título del correo.',
    'preview.file.loading_document': 'Cargando Documento',
    'preview.hint.menu': '*clic en menú para firmar el documento',
    'subscription.header': 'Elige un plan.',
    'subscription.sub.header': 'Precios transparentes sin costos adicionales. ',
    'subscription.tag': 'Firma rápido y seguro.',
    'subscription.per_month': 'Por mes',
    'subscription.per_annual': 'Por anual',
    'subscription.free.limited.period': 'Gratis por periodo limitado',
    'subscription.free': 'Gratis',
    'subscription.free_plan': 'GRATIS',
    'subscription.monthly': 'MENSUAL',
    'subscription.annual': 'ANUAL',
    'subscription.go_green.one_picture': '1 Foto',
    'subscription.go_green.ten_per_month': '10 documentos al mes',
    'subscription.officially_green.picture_voice_notes': 'Foto o nota de voz',
    'subscription.pro.add_log_or_profile': 'Añade tu logo y paleta de colores',
    'subscription.pro.picture_voice_notes': 'foto o nota de voz',
    'preview.file.add.signatory.sign': 'Clic para fijar firma en el documento',

    'subscription.user': 'usuario',
    'subscription.users': 'usuarios',
    'subscription.documents': 'documentos',
    'subscription.e_signature': ' e.firma (SAT)',
    'subscription.blockchain': ' Blockchain',
    'subscription.official.identification': ' Identificación Oficial',
    'subscription.access.code': 'Código de acceso',
    'subscription.advance_electronic_signature': ' Firma electrónica avanzada',
    'subscription.corporate.more_documents': '¿Firmas más de 100 documentos al mes? ',
    'subscription.corporate.call_us': 'Agenda una llamada con nosotros para activar un paquete a la medida',
    'subscription.button.contact': 'Contratar',
    'subscription.button.continue': 'Continuar',
    'subscription.button.request_call': 'Agendar',
    'subscription.footer.want_one_document': '¿Sólo quieres firmar un documento?',
    'subscription.footer.try_free': 'PRUEBA GRATIS',
    'subscription.footer.pay_per_document': 'PAGA POR DOCUMENTO',
    'subscription.start': 'Comienza',
    'subscription.month': 'mes',
    'subscription.days': 'días',
    'subscription.select.payment.method': 'Ingresa método de pago',
    'subscription.promotional.price': 'Precio promocional',
    'subscription.month.annual.payment': 'Al mes con pago anual',
    'subscription.month.monthly.payment': 'por mes,',
    'subscription.footer.faq': 'PREGUNTAS FRECUENTES',
    'subscription.footer.best.plan': '¿No sabes cuál es el mejor plan para ti? ',
    'subscription.footer.chat.btn': 'CHATEA',
    'subscription.footer.chat': ' con nosotros',
    'signature.popup.button.save': 'Guardar',
    'signature.popup.button.select': 'Seleccionar',
    'signature.poup.no_signature.added': 'Aún no se ha agregado ninguna firma',
    'signature.popup.button.delete': 'Borrar',
    'signature.popup.tab.create_signature': 'Crea tu firma',
    'signature.popup.tab.ID_message': 'Para agregar la identificación es necesario dar clic en el icono ID',
    'signature.popup.tab.saved_signature': 'Firmas Guardadas ',
    'signature.popup.heading.create_signature': 'Crear Firma',
    'signature.popup.heading.accept_legal_signature': 'Acepto la representación legal de mi firma',
    'signature.popup.heading.select_signature': 'Seleccionar Firma',
    'signature.popup.legal.terms': 'Acepto la representación legal de mi firma',
    'signature.popup.input.name': 'Escribe tu nombre',
    'signature.popup.camera.photo.capture': 'Captura el ',
    'signature.popup.camera.photo.mandatory_text': 'La foto de tu Identificación Oficial es obligatoria',
    'signature.popup.camera.photo.capture.back': 'Ahora el ',
    'signature.popup.camera.photo.front': 'frente',
    'signature.popup.camera.photo.back': 'reverso',
    'signature.popup.camera.photo.your_official_id': ' de tu Identificación Oficial',
    'signature.popup.camera.photo.your_official_id.back': ' de la identificación',
    'signature.popup.camera.photo': 'Captura el frente de tu Identificación Oficial',
    'signature.popup.camera.another_photo': 'Captura el reverso de tu Identificación Oficial',
    'signature.popup.camera.capture_photo': 'Capture photo',
    'signature.popup.identification.title': 'Identificación Oficial',
    'signature.popup.identification.subtitle': 'Captura tu identificación oficial',
    'signature.popup.identification.button.capture': 'Captura',
    'signature.popup.identification.button.attach': 'Adjunta',
    'signature.popup.water.mark': 'Firma aquí',
    'signature.popup.sign.instruction': '*Cuida que tu firma coincida con la de tu identificación oficial',
    'signatory.page.loading': 'Por favor espere mientras cargamos el documento.',
    'signatory.text.pending_signatures': 'Firmas pendientes',
    'footer.logout': 'Cerrar sesión',
    'btn.back': 'Regresar',
    'signatory.text.sign_document': 'Firmar documento',
    'signatory.button.text.status': 'Estatus',
    'signatory.button.text.save_and_send': 'Enviar',
    'signatory.button.text.save': 'Guardar',
    'signatory.button.text.save_observer': 'Guardar observador',
    'signatory.button.text.sign': 'Finalizar',
    'signatory.button.text.continue': 'Continuar',
    'signatory.button.text.signatory_Url': 'URL de firma',
    'signatory.button.text.url_text': 'Clic mostrar datos y urls de los firmantes.',
    'signatory.button.text.copy': 'Copiar',
    'signatory.text.download': 'Descargar',
    'signatory.text.send_by_email': 'Enviar por correo',
    'signatory.text.shared_email': 'Documento compartido con:',
    'signatory.text.vobo_email': 'Documento con visto bueno de:',
    'signatory.signIn.popup.text.of_weeSign': ' de weetrust ',
    'signatory.signIn.popup.text.privacy_policy': 'política de privacidad',
    'signatory.signIn.popup.text.and_the': ' y el ',
    'signatory.signIn.popup.text.terms_and_conditions': 'términos y Condiciones',
    'signatory.signIn.popup.text.by_signing_i_accept_the': 'Al firmar acepto el ',
    'signatory.signIn.popup.button.text': 'Firmar ahora',
    'signatory.signIn.popup.label.enter_verification_code': 'Ingresar el código',
    'signatory.signIn.popup.email.placeHolder': 'invitado@gmail.com',
    'signatory.signIn.popup.label.email': 'Correo electrónico',
    'signatory.signIn.popup.heading.share': 'Compartir',
    'signatory.signIn.popup.label.you_are_invited_to_sign_this_document': 'Te invito a firmar el siguiente documento',
    'signatory.signIn.popup.label.name_of_invited_person': 'Miguel Angel Centeno',
    'signatory.signIn.popup.label.welcome': '¡BIENVENIDO!',
    'signUp_resent.alert': 'Se ha reenviado el correo a la dirección proporcionada!',
    'signUp.start_for_free': 'Empezar gratis',
    'signUp.password.strength': 'Debe contener al menos un número, una mayúscula y 8 caracteres',
    'signatory.text.field.placeholder': 'Introduzca el valor',
    'signatory.checkbox.field.placeholder': 'Introduzca el valor',
    'document_validation.results.document_info.title': 'Información del Documento',
    'document_validation.results.document_info.creator_info': '<strong>{{creatorName}} ({{creatorEmail}})</strong> creó el documento',
    'document_validation.results.document_info.created_at': 'Se creó en la fecha de <strong>{{createdAt}}</strong>',
    'document_validation.results.document_info.sent_at': 'Enviado en la fecha de <strong>{{sentAt}}</strong>',
    'document_validation.results.signatories_info.title': 'Información de los firmantes',
    'document_validation.results.signatories_info.text': '<strong>{{signatoryName}} ({{signatoryEmail}})</strong> firmó el <strong>{{signedAt}}</strong>',
    'document_validation.results.PSC_info.title': 'Información de PSC',
    'document_validation.results.PSC_info.text': 'Documento certificado por Advantage Security, S de RL de CV como <strong>Prestador de Servicios de Certificación</strong> autorizado por la <strong>Secretaría de Economía</strong> en cumplimiento a la <strong>NOM 151</strong>',
    'document_validation.results.PSC_info.created_at': 'Fecha de emisión <strong>{{createdAt}}</strong>',
    'validate.upload.document': 'Carga tu documento',
    'validate.upload.drag_and_drop.text1': 'Ahora puedes arrastrar',
    'validate.upload.drag_and_drop.text2': 'para verificar que ambos sean válidos',
    'validate.upload.drag_and_drop.text3': 'Documentos y Constancia PSC',
    'validate.button.validate': 'Validar',
    'validate.upload.title': 'Valida cualquier documento firmado en',
    'validate.upload.sub_title': 'Con esta herramienta podrás comparar el documento que tienes en tu poder con el que se encuentra guardado en la Blockchain para asegurarte de que no ha sido modificado en forma alguna',
    'validate.validating.textInit': 'Validación de Url de Documento',
    'validate.validating.document': 'We are validating your document',
    'validate.validating.success.title': '¡Felicidades! tu documento',
    'validate.validating.success.asn.title': '¡Felicidades! tu ASN',
    'validate.validating.success.status': 'es válido',
    'validate.validating.failure.title': 'Lo sentimos tu documento',
    'validate.validating.failure.status': 'no es válido',
    'validate.validating.failureUrl': '¡La URL del documento se encuentra Caducada!',
    'validate.validating.failureUrl.message': 'Para poder acceder nuevamente a este documento ingresa a tu cuenta de weetrust o pide al propietario del documento que lo comparta nuevamente',
    'validate.validating.title': 'Tu documento ha sido revisado:',
    'validate.validating.pdf.success': 'El Documento es válido',
    'validate.validating.pdf.failed': 'El Documento es inválido',
    'validate.validating.asn.success': 'La Constancia PSC es válida',
    'validate.validating.asn.failed': 'La Constancia PSC es inválida',
    'validate.validating.compared.success': 'Si hay relación entre el Documento y la Constancia PSC',
    'validate.validating.compared.failed': 'No hay relación entre el Documento y la Constancia PSC',
    'validate.validating.error.upload.max': 'No puedes subir mas de dos documentos',
    'validate.validating.error.upload.noAccepted': 'Solamente puedes subir un PDF y ASN',
    'validate.revalidate.title': '¿Quieres validar otro documento?',
    'validate.revalidate.button': 'Sí, quiero validar uno más',
    'validate.link.return': 'No, deseo regresar a weetrust.mx ',
    'validate.endorsement.isEndorsement': 'Se encuentra endosado',
    'validate.endorsement.hasSubsequent': 'Este documento tiene un endoso posterior',
    'validate.endorsement.hasComparedVersion': 'Las versiones de los documentos son diferentes',
    'validate.certificate.popup': 'Validando sus certificados !',
    'validate.certificate.signing': 'Firmando tu documento !',
    'validate.invalid.password': ' La contraseña es incorrecta ',
    'validate.invalid.cert': ' El Certificado es incorrecto',
    'validate.invalid.pair': 'Par de clave inválidas',
    'validate.expired.certificate': 'Tu certificado ha expirado',
    'validate.missing.certificate.key': 'Certificado faltante, clave privada o contraseña.',
    'validate.legal.representative': 'Para firmar en nombre de persona moral, utiliza la e.firma del representante legal',
    'validate.document.signed.success': 'El documento ha sido firmado con éxito.',
    'signatory.document.signed.success': 'El documento se ha firmado correctamente y, una vez que lo hayan firmado todos los participantes, se le enviará por correo electrónico.',
    'validate.button.save': 'Guardar',
    'loader.please.wait.text': 'Por favor espera',

    'rating.rate_service': 'Califica nuestro servicio',
    'rating.send': 'Enviar',
    'rating.rate.textarea.placeholder': 'Déjanos un comentario',


    'sat.file.label': 'Selecciona tu archivo',
    'sat.title': 'Adjuntar archivos',
    'sat.select_certificate': 'Selecciona tu archivo .cer',
    'sat.select_private_key': 'Selecciona tu archivo .key',
    'sat.enter_your_password': 'Ingresar la contraseña de tu e.firma',
    'addUser.name': 'Escribe tu nombre',
    'addSubUser.name': 'Nombre Completo de Subusuario',
    'addSubUser.limit': 'Excedió el limite de Usuarios',
    'addUser.password': 'Contraseña',
    'addSubUser.password': 'Contraseña Temporal',
    'addUser.emailID': 'Correo electrónico',
    'addUser.addUser': 'Agregar Usuarios',
    'addUser.user.exist': 'La dirección de correo electrónico ya está en uso por otra cuenta.',
    'editUser.edit.save': 'Guardar',
    'addUser.invalid_email_address': 'Dirección de correo electrónico no válida',
    'addUser.password_length': 'La contraseña debe ser de al menos 8 caracteres, contener mayúsculas y caracteres especiales.',
    'addUser.number_length_message': 'El número debe tener 10 dígitos.',
    'userList.edit': 'Editar',
    'userList.change.password': 'Cambia la contraseña',
    'userList.remove.user': 'Eliminar usuario',
    'confirmation.yes': 'Sí',
    'confirmation.no': 'No',
    'removeUser.confirmation': '¿Estás seguro que quieres quitar a',
    'document.confirmation': '¿Estás seguro que quieres a ',
    'document.saveChanges': '¿Estás seguro de que quieres guardar los cambios?',
    'userList.user.not.found': 'Usuario no encontrado',
    'editUser.edit.user': 'Editar Usuario',
    'user.update.failed': 'Error al actualizar los detalles del usuario.',

    'billing.page.text.plan_information': 'Información del plan',
    'billing.page.text.payment_method': 'Método de pago',
    'billing.page.text.billing_information': 'Datos de facturación',
    'billing.page.text.plan': 'Plan : ',
    'billing.page.text.plan.validity': 'Válido hasta : ',
    'billing.page.button.Change_plan': 'Cambiar de plan',
    'billing.page.button.Cancel_plan': 'Cancelar plan',
    'billing.page.button.Update_Payment_Method': 'Actualizar información de pago',
    'billing.page.button.Add_Payment_Method': 'Añadir método de pago',
    'billing.page.button.request_invoice': 'Solicitar Factura',
    'billing.page.label.text.name_or_business_name': 'Nombre o razón social',
    'billing.page.label.text.RFC': 'RFC',
    'billing.page.label.text.Address': 'Dirección',
    'billing.page.label.text.State': 'Estado',
    'billing.page.label.text.City': 'Ciudad',
    'billing.page.label.text.ZIP_code': 'C.P.',
    'billing.page.label.text.country': 'País',
    'billing.page.label.text.phone': 'Teléfono',
    'billing.page.field.default_placeholder': 'Escribe algo',
    'billing.page.field.business_name.placeholder': 'Empresa',
    'billing.page.field.zipcode.placeholder': '00000',
    'billing.page.field.address.placeholder': 'Dirección',
    'billing.page.field.phone.placeholder': '+91',
    'billing.page.signature.config.text': 'Habilitar firma con texto',

    'billing.business_name': 'Ingrese el nombre del negocio',
    'billing.RFC': 'Ingrese RFC',
    'billing.address': 'Ingresar dirección',
    'billing.state': 'Estado',
    'billing.city': 'Ciudad',
    'billing.zipCode': 'Ingresa tu código postal',
    'billing.country': 'Ingresar pais',
    'billing.phone': 'Ingresar número telefónico',
    'invalid.number': 'Ingrese el número válido',
    'payment.pay.with.card': 'Pagar con tarjeta de crédito / débito',
    'payment.btn.pay': 'Pagar',
    'payment.card.number': 'Número de tarjeta',
    'payment.cardholder.name': 'Nombre tarjetahabiente',
    'payment.address': 'Dirección',
    'payment.card.expires': 'Expira',
    'payment.card.ccv': 'CCV',
    'payment.card.ccv.hint.message': 'Los últimos 3 dígitos que se muestran en el reverso de su tarjeta',
    'payment.confirm.heading': 'Por favor confirme su pago',
    'payment.saved.card': 'Tu tarjeta guardada',
    'payment.update.card': 'Actualizar datos de tarjeta',
    'cancel.plan.confirmation': '¿Seguro que quieres cancelar tu plan de suscripción?',
    'downgrade.plan.confirmation': '¿Estás seguro de que deseas degradar tu plan de suscripción?',
    'confirm.payment.btn': 'Confirmar pago',
    'confirm.payment.message': 'Suscripción exitosa',
    'confirm.payment.message_fail': 'Su suscripción ha fallado',
    'update.payment.heading': 'Actualice sus detalles de pago a continuación',
    'add.payment.heading': 'Agregue sus detalles de pago a continuación',
    'payment.update.btn': 'Actualizar',
    'payment.add.btn': 'Añadir',
    'notification.dialogue.close': 'Cerrar',
    'notification.dialogue.try_again': 'Inténtalo de nuevo',
    'notification.dialogue.ok': 'De acuerdo',
    'notification.dialogue.renovate': 'Renovar ahora',
    'notification.dialogue.no': 'No, regresar',
    'request.invoice.failure': 'Error al solicitar su factura. Por favor, inténtelo de nuevo más tarde.',
    'request.invoice.success': 'Tus facturas serán enviadas al correo registrado',
    'request.addCard.success': 'Tu método de pago fue agregado con éxito.',
    'server.error': 'Error del Servidor.',
    'request.invoice.unauthorized': 'Debes estar suscrito para solicitar la factura.',
    'userList.add.user.limit.error': 'Has llegado al límite de subusuarios de tu suscripción',
    'document.add.limit.error': 'Has llegado al límite de documentos de tu suscripción',
    'document.add.subscription_expire.error': 'Tu suscripción ha caducado',
    'document.add.subscription_expire.error.message': 'Para seguir disfrutando las ventajas de firmar electrónicamente renueva tu suscripción',
    'document.add.no_subscription.error': 'Necesita suscripción para usar weetrust',
    'document.use.camera_feature.error': 'Por favor, compre una suscripción para activar esta función',
    'select.plan.warning': 'Por favor, seleccione un plan para continuar.',
    'document_incomplete.warning': 'Estamos procesando su documento finalizado, espere unos minutos hasta que se complete para que pueda descargarlo y compartirlo',
    'document.not_found': 'Documento no encontrado',
    'document.connection_error': 'No es posible conectarse al servidor. Inténtalo de nuevo.',
    'document.sat.info': 'Deberás de contar con tu .key y .cer de tu e.firma en tu teléfono para poder continuar con el proceso de firma sino te sugerimos ingresar desde tu computadora para completar tu firma',
    'corporate.plan.message': 'Su solicitud ha sido enviada con éxito, le informaremos en breve!',
    'corporate.plan.restriction': 'Asegúrese de tener un número máximo de usuarios correspondiente a este plan.',
    'document.upload.error': 'No podemos procesar su documento. ¡Inténtalo de nuevo!',
    'document.upload.invalid_type.pdf': 'El tipo de archivo debe ser un PDF',
    'document.upload.maxsize_error': 'El tamaño del archivo no puede exceder los 20MB',
    'document.upload.maxsize_error_30': 'El tamaño del archivo no puede exceder los 30MB',
    'document.upload.multiple.maxsize_error': 'El tamaño total de los archivos no puede exceder los 20MB',
    'document.upload.multiple.maxcount': 'El limite de documentos es de 10 archivos',
    'document.upload.multiple.single': 'Solo puede validar 1 documento a la vez',
    'document.field_array.error': 'La firma es necesaria para continuar',
    'document.photo_array.error': 'EL documento de identidad es necesario para continuar',
    'document.signature.error': 'La firma es obligatoria para procesar el documento. ¡Inténtalo de nuevo!',
    'document.geolocation.preview.error': 'You activated geolocation. Enter this {url} to activate it in your browser.',
    'document.geolocation.error': 'La firma requiere de la geolocalización activa en tu navegador. Ingresa a este {url} para activarlo.',
    'document.geolocation.permission.error': 'La firma requiere de la geolocalización activa en tu navegador. Actualice su configuración para permitir su uso.',
    'document.geolocation.tooltip': 'Debes habilitar la geolocalización en tu navegador',
    'document.geolocation.signatory': 'Favor de Activar la Geocalización',
    'signing_authority.only_me.tooltrip': 'Solo se requiere tu firma',
    'signing_authority.me_and_others.tooltrip': 'El documento requiere tu firma y la de otras personas',
    'signing_authority.others.tooltrip': 'El documento requiere la firma de otras personas ',
    'Title.WeeSign_Pro': 'weetrust PRO',
    'corporate.label.statistics': 'Panel administrativo',
    'corporate.label.statistics.general_information': 'Información general',
    'corporate.label.statistics.sale_information': 'Información de venta',
    'corporate.label.statistics.identity_provider': 'Proveedor de verificación',
    'corporate.label.statistics.documents_information': 'Información de documentos',
    'corporate.label.total_documents': 'Documentos: ',
    'corporate.label.pending_documents': 'Documentos pendientes: ',
    'corporate.label.completed_documents': 'Documentos completados: ',
    'corporate.label.top_fifteen': 'Usuarios que más han firmado en la plataforma',
    'corporate.label.most_use_users': 'Usuarios que más usan la API',
    'corporate.label.recently_connected': 'Últimos usuarios conectados',
    'corporate.label.recently_register': 'Últimos usuarios registrados',
    'corporate.label.older_users': 'Usuarios con más tiempo en la plataforma: ',
    'corporate.label.planName': 'Plan:',
    'corporate.label.membership': 'Membresía:',
    'corporate.label.email': 'Correo electrónico: ',
    'corporate.label.full_name': 'Nombre completo: ',
    'corporate.label.company_name': 'Nombre de empresa: ',
    'corporate.label.authenticate_url': 'Liga de Autenticación:',
    'corporate.label.document_limit': 'Límite total de documentos ',
    'corporate.label.user_limit': 'Límite total de usuarios ',
    'corporate.label.expiry': 'Fecha de caducidad ',
    'corporate.label.amount': 'Cantidad ',
    'corporate.label.biometric_credits_id': 'Créditos Verificación de Identificación (OCR)',
    'corporate.label.biometric_credits_face': 'Créditos Verificación con Biometria (Face ID)',
    'corporate.label.background_check_credits': 'Créditos de Verificacion Background Check',
    'corporate.label.orc_check_credits': 'Créditos de Comprobación de Domicilio (OCR)',
    'corporate.label.incode_package1': 'Incode Créditos de Identidad Paquete 1',
    'corporate.label.incode_package2': 'Incode Créditos de Identidad Paquete 2',
    'corporate.label.incode_package3': 'Incode Créditos de Identidad Paquete 3',
    'corporate.label.biometric_enable_id': 'Verificación de Identificación (OCR)',
    'corporate.label.biometric_enable_face': 'Verificación con Biometria (Face ID)',
    'corporate.label.biometric_renapo_validation': 'Validación por RENAPO',
    'corporate.label.biometric_ine_validation': 'Validación por INE',
    'corporate.label.biometric_background_check_validation': 'Verificación de Background Check',
    'corporate.label.enable_geolocation': 'Activar Geolocalización',
    'corporate.label.disable_mailing': 'Desactivar correos',
    'corporate.label.enable_white_label': 'Habilitar White Label',
    'corporate.label.enable_white_label_template': 'Habilitar Plantilla HSBC',
    'corporate.label.massive_signature': 'Firma Masiva',
    'corporate.label.format': 'Formatos',
    'corporate.label.disabled_url': 'Deshabilitar URL de Firma en Correo',
    'corporate.label.authenticate_notification': 'Habilitar Notificación de Autenticación',
    'corporate.label.enable_order_sign': 'Habilitar Orden de firmantes',
    'corporate.label.white_label.name': 'Nombre',
    'corporate.label.white_label.subjectEmail': 'Asunto de Correo',
    'corporate.label.white_label.color': 'Seleccionar Color',
    'corporate.label.white_label.logo': 'Logo',
    'corporate.label.white_label.sign': 'Firma',
    'corporate.label.white_label.biometric': 'Logo de biometría',
    'corporate.label.white_label.url': 'Url',
    'corporate.label.white_label.templateAttached': 'Template de Anexo',
    'corporate.label.white_label.templateCertificate': 'Template de Certificado',
    'corporate.label.white_label.templateEfirma': 'Template de Efirma',
    'corporate.label.white_label.templateEfirmaGeo': 'Template de Efirma con Geo',
    'corporate.label.white_label.templateRegister': 'Template de Registro',
    your_plan_is: 'Tu plan actual es ',
    which_is_valid_for: ' que es válido hasta ',
    days_then_will_be_migrate_to: ' y luego se migrará automáticamente a ',
    'document.page.heading.document': 'Documentos',
    'document.page.heading.available': 'disponibles',
    'document.page.heading.available_capital': 'Disponibles:',
    'document.page.heading.used': 'Usados',
    'document.page.heading.validity': 'Vigencia',
    'document.page.heading.biometric_id': 'Créditos de Verificación de Identidad',
    'document.page.heading.biometric_face': 'Verificación de Identidad',
    'document.page.heading.biometric_background_check': 'BackgroundCheck',
    'document.preview.geolocation.action.label.default': 'Activar geolocalización',
    'document.preview.geolocation.action.label.active': 'Geolocalización activada',
    'document.preview.geolocation.label': 'Solicitar geolocalización',
    'document.preview.biometric_background_check.label': 'Activar BackgroundCheck',
    'document.preview.shared_document.label': 'Compartir documento',
    'document.preview.shared_document.description': 'Permite compartir el documento a un usuario como observador (sin firmar).',
    'document.preview.split.description': 'Al seleccionar el rango 1 a 1, solamente se separa la primera hoja. Al seleccionar el rango 1 a 3, solamente separa las primeras tres paginas.',
    'document.preview.geolocation.description': 'Permite obtener las coordenadas geográficas mediante el navegador.',
    'document.preview.vobo.button.title': 'Agregar visto bueno',
    'document.preview.vobo.button.tooltip': 'Añade a la persona que quieras que apruebe tu documento y sin la cual el proceso de firma no podrá concluirse. Puedes modificar el orden en que quieras que lleguen las solicitudes de firma y de visto bueno',
    'document.preview.vobo.name.error': 'Ingresar un nombre',
    'document.preview.vobo.email.error': 'Ingrese un correo válido',
    'document.preview.vobo.emailOwner.error': 'No puede ser el mismo correo del creador',
    'document.preview.vobo.emailRepeat.error': 'El correo está repetido',
    'document.preview.vobo.order.error': 'Seleccione un orden del visto bueno',
    'document.shared.empty.error': 'Agrega un correo para compartir',
    'document.shared.empty.email': 'Ingresa correo de observador',
    'document.shared.duplicate.email': 'Correo del observador duplicado',
    'document.shared.format.email': 'Correo invalido en observador.',
    make_payment_button: 'Hacer el pago',
    failed_subscription_payment_message: 'No podemos procesar el pago de la suscripción para su plan actual, ¡realice el pago para continuar usando la plataforma!',
    link: 'enlace',

    'biometrics.capture.click': 'Clic aquí para tomar la fotografía de su documento de identidad',
    'biometrics.capture.front': 'Frente',
    'biometrics.capture.back': 'Reverso',
    'biometrics.capture.selfie': 'Selfie',

    'biometrics.options.photo_id': 'Foto ID',
    'biometrics.options.card_id': 'Verificación de Identificación',
    'biometrics.options.face_id': 'Biometría Facial',

    'biometrics.options.background_check_id': 'Background Check',
    'biometrics.options.background_check_id_description': 'Habilita la búsqueda en bases de datos de RENAPO, INE y listas negras.',

    'biometrics.options.card_id.ine': 'Documento de Identidad',
    'biometrics.options.card_id.cedula': 'Cédula Profesional',
    'biometrics.options.card_id.passport': 'Pasaporte',

    'biometrics.options.photo_id_title': 'Foto ID',
    'biometrics.options.card_id_title': 'Verificación de Identificación',
    'biometrics.options.face_id_title': 'Verificación con Biometría Facial',

    'biometrics.options.card_id_description': 'Requiere que se agregue una identificación oficial para validar la firma. (INE / IFE, Pasaporte, Cédula profesional, Cédula de Identidad, Cédula de ciudadanía, Forma Migratoria Múltiple (FMM), DNI, VISA)',
    'biometrics.options.face_id_description': 'Requiere que todos los firmantes se tomen una selfie para validar la firma.',
    'biometrics.options.backgroundcheck_description': 'Habilita la consulta a Renapo, INE y Listas Negras.',

    'biometrics.alert.faceId': 'Guardando biometría facial',
    'biometrics.alert.verification': 'Guardando verificación de identidad',
    'biometrics.alert.id_required': 'Favor de subir Frente y Reverso de tu INE ó Frente de tu Pasaporte',
    'biometrics.alert.ine_required': 'Las fotos de tu INE por ambos lados son obligatorias.',
    'biometrics.alert.cedula_required': 'Las fotos de tu Cédula Profesional por ambos lados son obligatorias.',
    'biometrics.alert.passport_required': 'La foto de tu Pasaporte es obligatoria.',

    'biometrics.options.enable_id_card': 'Agregar Identificación Oficial',
    'biometrics.options.enable_faceid': 'Agregar Verificación de Rostro',

    'biometrics.alert.selfie_required': 'Favor de subir Biometría Facial.',
    'biometrics.alert.errors.camera_required': 'No se ha detectado una camara, favor de intentarlo en otro dispositivo.',
    'biometrics.alert.errors.allow_camera': 'Debes permitir el acceso a la cámara para capturar tu documento.',
    'biometrics.alert.errors.processing_files': 'Ocurrió un problema al procesar los archivos, Por favor, intenta de nuevo.',
    'biometrics.alert.errors.server_error': 'Ocurrió un problema con el servicio, Por favor, intenta de nuevo.',
    'biometrics.alert.errors.expired': 'Documento expirado, Por favor, intente con otro documento oficial.',
    'biometrics.alert.errors.validation_fail': 'No hemos podido validar tu documento, por favor lee las recomendaciones e intenta de nuevo',
    'biometrics.alert.errors.attempt.error': ' Error en la biometría. Por favor sigue las recomendaciones e intenta de nuevo.',
    'biometrics.alert.errors.attempt.description': 'Lorem Ipsum is simply dummy text of the printer took a galley of type and scrambled it to make a type specimen book.',
    'biometrics.alert.errors.limited.error': 'Se han producido demasiados intentos fallidos.',
    'biometrics.alert.errors.limited.description': 'No pudimos validar tu biometría correctamente.',
    'biometrics.alert.errors.limited.instructions': 'Por favor marca al siguiente número para recibir apoyo.',
    'biometrics.alert.errors.limited.instructions_bottom': 'Favor de seguir las recomendaciones para evitar fallas futuras.',
    'biometrics.alert.errors.limited.credits': 'No cuentas con créditos disponibles de verificación de identidad.',
    'biometrics.alert.errors.contact.us': 'Por favor contáctanos para adquirir más créditos.',
    'biometrics.alert.continue': 'Continuar',

    'biometrics.signatory.processing': 'Tu biometría está siendo procesada.',
    'biometrics.signatory.completed': 'Tu biometría ya ha sido procesada.',

    'biometric.retry.error': 'Su enlace ha caducado o es invalido.',
    'biometric.retry.error_used': 'Este enlace ya ha sido usado.',
    'biometric.retry.retry_text': 'Se ha reenviado la solicitud de firma con verificación de identidad.',
    'biometric.retry.skipped_text': 'La firma se ha aprobado con biometría fallida.',

    'signature.popup.tab.validate_id': 'Capturar',
    'signature.popup.tab.validate_face': 'Tomar una Selfie',
    'inbox.list.header.name': 'Nombre',
    'inbox.list.header.tags': 'Etiquetas',
    'inbox.list.header.signers': 'Firmantes',
    'inbox.list.header.status': 'Estado',
    'inbox.list.header.date': 'Fecha',
    'inbox.list.pagination.documents': 'Documentos',
    'inbox.list.pagination.pages': 'Páginas',
    'inbox.list.pagination.from': 'de',
    'inbox.list.pagination.previous': 'Anterior',
    'inbox.list.pagination.next': 'Siguiente',
    'inbox.list.signer.info.biometric': 'Verificación de Identidad',
    'inbox.list.signer.info.biometric_error': 'Verificación de Identidad',
    'inbox.list.signer.info.photo_id': 'ID de foto',
    'inbox.list.signer.info.is_signed': 'Ya firmó',
    'inbox.list.signer.info.expiry': 'Expira',
    'inbox.list.signer.info.request_signatory': 'Solicitar Firma',
    'inbox.list.signer.info.view_document': 'Ver resultado',
    'inbox.list.signer.info.yes': 'Si',
    'inbox.list.signer.info.no': 'No',
    'inbox.list.signer.info.biometric_processing': 'Procesando',
    'inbox.list.signer.info.biometric_checking': 'En revisón por el emisor del documento',
    'inbox.list.signer.info.pending': 'Sin firmar',
    'inbox.list.vobo.info.is_checked': 'Aceptado',
    'inbox.list.vobo.info.is_refused': 'Rechazado',
    'inbox.request_signatory.question': '¿Desea que este usuario vuelva a firmar?',
    'inbox.request_signatory.text': 'Recuerda que aceptar puede descontar créditos por biometría',
    'inbox.request_signatory.button_no': 'Aprobar Firma',
    'inbox.request_signatory.button_yes': 'Solicitar Firma',

    'inbox.massive_signature.pending.fragment-1': 'Tienes ',
    'inbox.massive_signature.pending.fragment-2': '{totalPendingDocuments} documentos ',
    'inbox.massive_signature.pending.fragment-3': 'para firmar ',
    'inbox.massive_signature_now.toogle': '¡Firma todos a la vez!',
    'inbox.single_search.input': 'Buscar documento',
    'inbox.advanced_search.toggle_button': 'Búsqueda Avanzada',
    'inbox.list_signature.pending': '¡Ve tus documentos pendientes de firma!',
    'inbox.list_notification.text': 'Tienes Nuevos Avisos',

    'inbox.advanced_search.form.date_range': 'Rango de fechas',
    'inbox.advanced_search.form.date_range.today': 'Hoy',
    'inbox.advanced_search.form.date_range.yesterday': 'Ayer',
    'inbox.advanced_search.form.date_range.last_seven_days': 'Últimos 7 días',
    'inbox.advanced_search.form.date_range.last_thirty_days': 'Últimos 30 días',
    'inbox.advanced_search.form.date_range.this_month': 'Este mes',
    'inbox.advanced_search.form.date_range.last_month': 'Mes anterior',

    'inbox.advanced_search.form.email': 'Email del firmante',
    'inbox.advanced_search.form.document_status': 'Estado del documento',
    'inbox.advanced_search.form.biometric': 'Verificación de ID',
    'inbox.advanced_search.form.signature_type': 'Tipo de firma',
    'inbox.advanced_search.form.pendig.from.singular': 'Falta',
    'inbox.advanced_search.form.pendig.from.plural': 'Faltan',
    'inbox.advanced_search.form.pendig.total': 'de',
    'inbox.advanced_search.form.pendig.total.all': 'del',
    'inbox.advanced_search.form.pendig.signers.singular': 'firmante',
    'inbox.advanced_search.form.pendig.signers.plural': 'firmantes',
    'inbox.advanced_search.form.pendig.signers.all': 'todos',
    'inbox.advanced_search.form.pendig.signers.total': 'total',
    'inbox.advanced_search.form.tags': 'Etiquetas',

    'inbox.advanced_search.search_button': 'Buscar',
    'inbox.advanced_search.cancel_button': 'Cancelar',
    'inbox.advanced_search.clean_button': 'Limpiar',
    'inbox.advanced_search.report_button': 'Descargar reporte de la búsqueda',
    'inbox.advanced_search.generate_report': 'Generando Reporte en Excel',
    'inbox.advanced_search.errors.invalid_report': 'Ocurrió un error al generar el reporte',
    'inbox.advanced_search.documents_bulk_download_button': 'Descargar documentos completados',
    'inbox.advanced_search.generate_bulk_download_link': 'Generando link de descarga',
    'inbox.advanced_search.errors.bulk_download': 'Ocurrió un error al generar el link de descarga',
    'inbox.advanced_search.documents_bulk_download_title': 'Tu solicitud de descarga está siendo procesada',
    'inbox.advanced_search.documents_bulk_download_thanks': 'Gracias por tu paciencia',
    'inbox.advanced_search.documents_bulk_download_link': 'En unos momentos te haremos llegar un correo con el link de descarga',

    'inbox.notification_title.remember': '¿Recuerdas qué tenías que hacer hoy?',
    'inbox.notification_text.remember': 'No te preocupes, ahora puedes agregar recordatorios a tus documentos',
    'inbox.notification_title.remember_advice': 'Tienes {nextNotifications} recordatorios',
    'inbox.notification_text.remember_advice': 'Descuida llegando el día te notificaremos con un campanazo',
    'inbox.notification_text.remember_remove': 'Eliminar',
    'inbox.notification_text.link_reminder': 'Ver documento',

    'tag.dialog.content.create_tag': 'Crear nueva etiqueta',
    'tag.dialog.content.assign_tag': 'Asignar etiqueta',
    'tag.dialog.content.cancel': 'Cancelar',
    'tag.dialog.content.exit': 'Salir',
    'tag.dialog.content.create': 'Crear',
    'tag.dialog.content.assign': 'Asignar',
    'tag.dialog.content.apply': 'Aplicar',
    'tag.dialog.content.create_tag_confirm': 'La etiqueta se añadió a tu lista',
    'tag.dialog.large.error': 'El nombre de etiqueta no debe de contener mas de 40 caracteres',
    'tag.dialog.color.text': 'Añade color de etiqueta',
    'tag.dialog.search.error': 'No se encontró coincidencia',
    'tag.dialog.color.repeat': 'La etiqueta ya existe',
    'tag.dialog.selector': 'Etiquetar como',

    'inbox.content.empty.title': 'Documentos encontrados: 0',
    'inbox.content.empty.description': 'No se encontraron coincidencias de su búsqueda',
    'inbox.empty.massive.title': 'Documentos pendientes: 0',
    'inbox.empty.massive.description': 'No tienes documentos pendientes por firmar',
    'inbox.empty.fail.title': 'Lo sentimos',
    'inbox.empty.fail.description': 'Ocurrió un error intente de nuevo',
    'inbox.empty.first.title': 'Un movimiento y ¡Listo!',
    'inbox.empty.first.description': 'Envía tu primer documento a firmar',
    'inbox.statistics.completed.title': 'Completados:',
    'inbox.statistics.vid.title': 'Paquete B ( VId ):',
    'inbox.statistics.background.title': 'Paquete C ( Background Check ):',
    'inbox.statistics.cutOffDate.title': 'Fecha de corte:',
    'inbox.whatsapp.error': 'Ocurrió un error intente de nuevo',
    'inbox.whatsapp.success': 'Reenviado por whatsapp exitosamente',

    'signatory.text.ready_signatures': 'Requerimientos completados',
    'signatory.text.edit_signatures': 'A continuación haga clic para editar',
    'signatory.text.right.forcedSign': 'Trazo de firma',
    'signatory.text.right.forcedPhotoID': 'Foto ID',
    'signatory.text.right.forcedGeolocation': 'Geolocalización',
    'signatory.text.right.forcedID': 'Verificación de Identidad',
    'signatory.text.right.forcedFaceID': 'Biometría Facial',
    'signatory.text.right.forcedAttachment': 'Adjuntos',

    'signatory.dialog.validation.signature': 'Favor de trazar tu firma',
    'signatory.dialog.validation.photoID': 'Favor de subir Foto de tu Identificación',
    'signatory.dialog.validation.photoID-verification': ' Favor de subir Frente y Reverso de tu Identificación',
    'signatory.dialog.validation.photoID-save': ' Favor de seleccionar una firma con foto ID',
    'signatory.dialog.confirmation.continueSign': 'Ha completado su documento, clic en',

    'document.inbox.header.created': 'Creado',
    'document.inbox.header.modified': 'Modificado',

    'templates.form.single.instructions': 'Favor de llenar los campos',
    'templates.preview_of_document': 'Datos obtenidos de CSV',
    'templates.feature.photoID': 'Foto ID',
    'templates.feature.geolocation': 'Activar Geolocalización',
    'templates.feature.biometric': 'Verificación de Identificación',
    'templates.feature.backgroundCheck': 'Background Checks',
    'templates.feature.tag': 'Agregar nombre de #etiqueta',

    'templates.preview.generate_documents': 'Generar Documentos',
    'templates.preview.request_signature.title': 'Firmante',
    'templates.preview.request_signature.button': 'Firmante',
    'templates.preview.request_signature.tooltip': 'Click para fijar firma en el documento',
    'templates.preview.save': 'Guardar',
    'templates.title': 'Plantillas',

    'templates.errors.100': 'No se encontro información en el archivo.',
    'templates.errors.101': 'Los encabezados del documento son incorrectos.',
    'templates.errors.102': 'Asegurese que todas las filas del documento tengan un correo electronico (email).',
    'templates.errors.103': 'Asegurese que todas las filas del documento tengan un nombre (name).',
    'templates.errors.104': 'El archivo contiene correos electronicos no validos.',
    'templates.errors.105': 'La cantidad de correos no coincide con los nombres.',
    'templates.errors.106': 'Debe fijar la firma para todos los firmantes.',
    'templates.errors.110': 'Ocurrió un error al procesar su documento, intente mas tarde.',
    'templates.errors.111': 'Asegúrese de que todos los números para en envió de whatsapp tienen un formato correcto.',

    'biometrics.capture.indications.click': '* Para agregar la identificación es necesario dar clic en la imagen dentro del recuadro',
    'biometrics.capture.indications': 'Tómate una selfie',
    'biometrics.capture.indications.1': 'Colócate sin lentes o cubrebocas lo más cerca posible sin cortar parte de tu rostro, cabeza o cuello',
    'biometrics.capture.indications.2': 'Asegúrate de estar bien iluminado. Sin sombras ni reflejos',
    'biometrics.document.capture.indications': 'Captura tu documento de identidad',
    'biometrics.document.capture.indications.INE': 'ID',
    'biometrics.document.capture.indications.or': 'ó',
    'biometrics.document.capture.indications.passport': 'Pasaporte',
    'biometrics.document.capture.indications.1': 'Asegúrate de capturar la identificación completa y enfocada',

    'signatory.confirmation.dialog': 'Firma Enviada',
    'signatory.confirmation.dialog.return': 'Deshacer',

    'biometrics.advice.resend': 'Recuerda que esta opción de reenvío costará un nuevo crédito',
    'inbox.status.more': 'Más',

    'remove.tag.dialog.title': 'Quitar etiqueta',
    'remove.tag.dialog': '¿Estás seguro que quieres eliminar {tagsNames}?',

    'signatory.dialog.confirmation.btn': 'Finalizar firma',
    'signatory.dialog.confirmation': '¡Ya casi está todo listo!',
    'signatory.dialog.confirmation.pleaseClick': 'Por favor, haz clic en el botón de finalizar firma',
    'signatory.dialog.confirmation.pleaseClick.2': 'para que tu documento sea enviado',
    'signatory.dialog.confirmation.termsConditions': 'Al hacer clic en el botón "Finalizar firma"',
    'signatory.dialog.confirmation.termsConditions.2': 'yo acepto los ',

    'static.position.dialog': '¡Buen trazo!',
    'static.position.dialog.messagge': 'Ahora posiciona tu firma en el documento, ',
    'static.position.dialog.btn': 'Entendido',
    'static.position.dialog.advice.click': 'y da clic en el botón de ',
    'static.position.dialog.advice.continue': '"continuar" ',
    'static.position.dialog.advice.finish': 'para finalizar',

    'status.information.createdBy': 'Creado por: ',
    'status.information.nickname': 'Nickname: ',
    'status.information.documentID': 'DocumentID: ',
    'status.information.send.date': 'Fecha de envío: ',
    'status.information.resend': 'N° de reenvíos: ',
    'status.information.resend.date': 'Fecha de reenvío: ',
    'status.information.resend.dates': 'Fechas de reenvío ',
    'status.information.number.dates': '°  reenvío ',
    'status.information.complete.date': 'Completado: ',

    'status.information.sign.date': 'Fecha de firma: ',
    'status.information.verificarion.number': 'N° de intentos Verificacion de ID: ',
    'status.information.verificarion.more': 'Ver resultados',
    'status.information.number.verificarion': '° Intento de Verificacion de ID fallida',
    'status.information.verificarion.result': 'Clic para ver resultado',

    'preview.file.nickname': 'Agregar un nickname al documento',
    'preview.file.nickname.opcional': '(opcional)',
    'preview.file.nickname.description': 'Te permite crear un nickname para identificar un documento en un flujo interno',

    'view.file.nickname': 'Nickname del documento: ',

    'signatory.dialog.advice.continueSign': 'Ya no es posible editar el nombre o correo del documento, hay personas que ya firmaron el documento',
    'signatory.dialog.advice.ok': 'Entendido',

    'signatory.edit.dialog': 'Editar nombre o correo',
    'signatory.edit.dialog.back': 'Cancelar edición',
    'signatory.edit.dialog.confirm': 'Actualizar firmantes',

    'signatory.dialog.confirmation.edit': 'Has modificado el nombre y correo electrónico a',
    'signatory.dialog.confirmation.edit.2': 'y ha sido enviado correctamente',
    'signatory.dialog.confirmation.edit.3': 'Ó copia y envía la URL de firma',

    'signatory.dialog.confirmation.delete': 'Estas a punto de eliminar el correo de',
    'signatory.dialog.confirmation.delete.2': 'Recuerda que puedes editar y enviar desde la ventana de vista del documento',
    'signatory.dialog.confirmation.delete.btn': 'Sí, eliminar',

    'signatory.dialog.error.delete': 'No es posible eliminar a',
    'signatory.dialog.error2.delete': 'por ser el único firmante',
    'signatory.dialog.error.delete.instructions': 'Tendrás que eliminar por completo el documento de la siguiente manera:',
    'signatory.dialog.error.delete.instructions.message': '1- Ve a la sección principal',
    'signatory.dialog.error.delete.instructions.message2': '2- Identifica el documento',
    'signatory.dialog.error.delete.instructions.message3': '3- En el menú selecciona eliminar y ¡listo!',
    'signatory.dialog.error.delete.btn': 'Ir a la sección principal',
    'signatory.dialog.error.delete.title': 'No es posible eliminar a todos los firmantes',
    'advance.search.all': 'Todos',
    'advance.search.yes': 'Sí',
    'advance.search.no': 'No',
    'advance.search.bg_check': 'Background Check',
    'advance.search.electronic.signature': 'Firma electrónica avanzada',
    'advance.search.e.signature': 'e.firma SAT',
    'advance.search.document.status.completed': 'Completados',
    'advance.search.document.status.completed.failed.biometric': 'Completado con verificación fallida',
    'advance.search.document.status.pending': 'Pendiente',
    'advance.search.document.status.pending.failed.biometric': 'Pendiente por verificación fallida',
    'advance.search.document.status.draft': 'Borrador',
    'advance.search.document.status.shared': 'Compartido',
    'advance.search.document.status.pending.sign': 'Pendiente por firmar',

    'document.page.add_multiple_document': 'Agrega uno o múltiples archivos',
    'document.page.add_multiple_document.info': 'Destaca y organiza PDFs completos como desees ¡Rápido y fácil!',
    'document.page.add_multiple_document.btn': 'Seleccionar archivos',
    'document.page.add_multiple_document.drag_and_drop': 'Arrastra uno o múltiples archivos',

    'document.page.add_document.upload': 'Cargando archivo',
    'document.page.add_multiple_document.upload.thanks': 'Gracias por tu paciencia, este proceso puede demorar unos segundos',

    'document.page.add_multiple_document.icon': 'Activa este icono ',
    'document.page.add_multiple_document.order': ' para elegir el documento principal',
    'document.page.add_multiple_document.describe.order': 'El orden de los documentos se contemplará de izquierda a derecha',
    'document.page.add_multiple_document.add_more': 'Añadir más desde ',
    'document.page.add_multiple_document.merge_files': 'Fusionar archivos',

    'document.page.add_multiple_document.merge': 'Fusionando archivos',
    'document.page.add_multiple_document.merge.wait': 'En estos momentos tus archivos están siendo fusionados para generar un solo PDF',
    'document.page.add_multiple_document.merge.thanks': 'Gracias por tu paciencia, este proceso puede demorar unos segundos',

    'document.page.add_multiple_document.wrong_document': 'No es posible visualizar este documento',
    'document.page.add_multiple_document.wrong_document.error': 'Sólo es posible previsualizar documentos en formato pdf',
    'document.page.add_multiple_document.wrong_document.advice': 'Te sugerimos convertir tu documento a formato PDF y volverlo a subirlo o continuar con el proceso',

    'static_sign_dialog.title': 'Preconfiguración V1',
    'static_sign_dialog.signer1': 'Cliente',
    'static_sign_dialog.signer2': 'Obligado Solidario',
    'static_sign_dialog.signer3': 'Mesa de Validación',
    'static_sign_dialog.signer4': 'Representante Legal',
    'static_sign_dialog.endorsement': 'Activar Endoso',
    'static_sign_dialog.error': 'Necesitas colocar un cliente, una persona de la mesa de validación y un representante legal.',
    'static_sign_dialog.error_email': 'Email no valido.',
    'static_sign_dialog.btn': 'Colocar',
    'static_sign_dialog.v2_1.title': 'Preconfiguración V2.1',
    'static_sign_dialog.v2_2.title': 'Preconfiguración V2.2',
    'static_sign_dialog.v3_1.title': 'Preconfiguración V3.1',
    'static_sign_dialog.signer5': 'Testigo',

    'massive.signature.button.text.save.signatory': 'Utilizar esta Firma',
    'massive.signature.signatory': 'Firma masiva',
    'massive.signature.thanks.header.dialog': 'Se van a firmar {totalPendingDocuments} documentos con la siguiente firma',
    'massive.signature.thanks.body': 'En estos momentos tu firma esta siendo plasmada en todos los documentos de la lista',
    'massive.signature.thanks.body.complemet': 'Se te iran notificando por correo cuando se completen tus documentos',
    'massive.signature.thanks.end': 'Gracias por tu paciencia, este proceso puede demorar unos minutos',
    'massive.signature.thanks.btn': 'Regresar al listado',
    'massive.signature.page.heading.document': 'Documentos en los que se requiere mi firma',
    'massive.signature.page.heading.pending.documents': 'Total de documentos pendientes: ',
    'massive.signature.page.dropdown.preview': 'Vista previa',
    'massive.signature.page.dropdown.remove': 'Quitar de la lista',
    'massive.signature.button.text.continue': 'Continuar',
    'massive.signature.page.heading.pending.endorsements': 'Total de endosos pendientes: ',
    'massive.signature.detail.pendings.error': 'No se encontraron documentos por firmar',
    'massive.signature.detail.signature.error': 'Ocurrió un error al procesar la firma. Intente de nuevo',
    'massive.signature.detail.send.error': 'Ocurrió un error al enviar a firmar los documentos. Intente de nuevo',

    'massive.signature.advanced_search.form.signature.type': 'Tipo de firma',
    'massive.signature.advanced_search.form.document.status': 'Estado del documento',
    'massive.signature.advanced_search.form.verification.status': 'Estatus de verificación',
    'massive.signature.advanced_search.form.document.status.only.me': 'Sólo falto yo',
    'massive.signature.advanced_search.form.document.status.others': 'Faltan varios firmantes',
    'massive.signature.advanced_search.form.verification.ok': 'Verificación satisfactoria',
    'massive.signature.advanced_search.form.verification.false': 'Verificación fallida',
    'massive.signature.advanced_search.form.verification.without': 'Sin verificación',

    'massive.signature.list.page.text.selected.documents': 'Has seleccionado {totalPendingDocuments} documentos para firmar',
    'massive.signature.list.page.text.remove.document': 'Si hay un documento que no deseas en firma masiva, puedes eliminarlo de la siguiente lista',
    'massive.signature.list.page.text.name': 'Nombre',
    'massive.signature.list.page.text.signatories': 'Firmantes',
    'massive.signature.list.page.text.alert.create.signature': 'Es momento de crear o utilizar tu firma guardada',
    'massive.signature.list.page.text.alert.portray.signature': 'Está se plasmará automáticamente en todos los documentos de la lista',
    'massive.signature.list.page.text.alert.geolocation': 'Algunos documentos requieren activar geolocalización',
    'massive.signature.list.page.text.create.signature': 'Firma ahora',
    'massive.signature.list.page.notification.missing.pendingdocs': 'No se han seleccionado nuevos documentos para firmar, favor de hacerlo',

    'signature.popup.tab.saved_signature.question': '¿Tienes firmas guardadas?',
    'signature.popup.tab.saved_signature.use': 'Utilízalas ',
    'signature.popup.tab.saved_signature.logIn': 'iniciando sesión',

    'signatory.user.not.match': 'El correo no coincide con el del firmante del documento',
    'signatory.signature.now': 'Firmar ahora',

    'signatory.signature.desktop.warning.text_1': 'Para que el servicio funcione correctamente es necesario usar tu dispositivo móvil',
    'signatory.signature.desktop.warning.text_3': 'Escanea este código QR para iniciar el proceso',

    'signatory.preconfig.format': 'Guardar o seleccionar una PRE-Configuración',
    'signatory.preconfig.success': 'PRE-Configuración guardada',
    'signatory.preconfig.placeholder.input': 'Nombre de la PRE-Configuración',
    'signatory.preconfig.description': 'Guarda la configuración actual y podrás usarla en otros documentos. Si ya tienes PRE-Configuraciones guardadas al seleccionarlas se aplicarán al documento actual.',
    'signatory.preconfig.list.title': 'Mis PRE-Configuraciones guardadas',
    'signatory.preconfig.name.missing': 'Asigna un nombre a tu nueva PRE-Configuración',
    'signatory.preconfig.name.duplicated': 'Este nombre de PRE-Configuración ya está en uso',
    'signatory.preconfig.apply.invalid.title': 'PRE-Configuración incompatible',
    'signatory.preconfig.apply.invalid.question': 'Si continuas, es recomendado verificar la posición de los fija-firmas.',
    'signatory.preconfig.apply.invalid.continue': 'Continuar',
    'signatory.preconfig.apply.invalid.cancel': 'Regresar',
    'signatory.preconfig.apply.invalid.description': 'Puede que esta pre-configuración no sea compatible con tu documento debido a que se guardo originalmente con diferente tamaños de de pagina.',

    'signatory.signature.form.update.btn': 'Modificar mis datos',
    'signatory.signature.form.update.error': 'No tienes permitido modificar el formulario',

    'signatory.form.preview.hi': 'Genial',
    'signatory.form.preview.description': 'Has respondido a todas las preguntas, revisa tus respuestas antes de continuar',
    'signatory.form.preview.update.btn': 'Editar',
    'signatory.form.preview.confirm.btn': 'Continuar',

    'signatory.form.questions.hi': 'Hola',
    'signatory.form.questions.description': 'Antes de firmar el documento es necesario responder las siguientes preguntas',
    'signatory.form.questions.start': 'Comenzar',

    'forms.step_mode.title': 'Pregunta',
    'forms.step_mode.separator': 'de',
    'forms.full_mode.title': 'Preguntas',

    'forms.nav.back': 'Anterior',
    'forms.nav.next': 'Siguiente',
    'forms.nav.send': 'Enviar',
    'forms.nav.cancel': 'Regresar',
    'forms.global.error.message': 'Verifica tus respuestas',

    'forms.validation.errors.required': 'Este campo es requerido',
    'forms.validation.errors.maxLength': 'El máximo de caracteres debe ser ',
    'forms.validation.errors.minLength': 'El mínimo de caracteres debe ser ',
    'forms.validation.errors.max': 'El valor máximo debe ser ',
    'forms.validation.errors.min': 'El valor mínimo debe ser',
    'forms.validation.errors.pattern': 'El formato del campo es inválido',
    'forms.validation.errors.mail.invalid': 'Ingrese una dirección de correo electrónico válida',
    'forms.validation.errors.number.invalid': 'Ingrese un número',

    'forms.service.error': 'Ocurrió un error al consultar el formulario',
    'forms.access.error': 'Este documento no tiene un formulario',
    'forms.no_data.error': 'Este documento no tiene un formulario creado',
    'forms.repeat.error': 'El formulario ya fue contestado previamente',
    'forms.signer.error': 'No tiene permisos para responder el formulario',
    'forms.signer.no_exist.error': 'No tiene permisos para firmar el documento',
    'forms.incomplete.error': 'Algunos datos del documento no se han completado es necesario esperar a que el firmante anterior llene los datos.',

    'forms.file.update.title': 'Enviando Formulario',
    'forms.file.update.message_wait': 'Por favor ten paciencia, este proceso puede demorar unos segundos',
    'forms.file.update.message': 'En estos momentos tus respuestas están siendo procesadas para actualizar el documento de firma.',

    'forms.reset.title': 'Reiniciar Formulario',
    'forms.reset.error.message': 'Ocurrió un error al intentar reiniciar el formulario.',
    'forms.dynamic_table.register.add': 'Añadir otro',

    'email_tracking.show_history': 'Ver historial',
    'email_tracking.email.sent': 'Correo entregado',
    'email_tracking.email.opened': 'Correo abierto',
    'email_tracking.email.clicked': 'Click en enlace de firma',
    'email_tracking.email.sent.placeholder': 'Tu correo esta en proceso de envío',
    'email_tracking.email.sent.error.placeholder': 'Tu correo no pudo ser entregado',
    'email_tracking.email.opened.placeholder': 'No ha sido abierto',
    'email_tracking.email.clicked.placeholder': 'No ha hecho click en el enlace',

    'login.weetrust.message.title': 'En weetrust somos mucho más que firma digital',
    'login.weetrust.message.link': '¡Visítanos!',
    'login.recovery.password.title': 'Se ha restablecido correctamente tu contraseña',
    'login.recovery.password.message': '¡Ingresa ahora!',

    'identity.alert.verification_sent': 'Tu verificación ha sido enviada.',
    'identity.alert.unknown_error': 'Solicitud no encontrada. Por favor, intenta de nuevo.',
    'identity.alert.verification_canceled': 'Tu verificación ha sido cancelada',
    'identity.alert.verification_deleted': 'Tu verificación ha sido eliminada',
    'identity.alert.verification_complete': 'Identidad Verificada',
    'identity.alert.verification_error': 'Verificación Fallida',
    'identity.alert.verification_error_info': 'Intente de nuevo',
    'identity.alert.verification_finish_error': 'Problema para finalizar la verificación',
    'identity.sesion.timeout.title': '¡Oops!',
    'identity.sesion.timeout.subtitle': 'La sesión ha expirado, no te preocupes puedes recargar la página para reanudar el proceso de firma',

    'signatory.info.email.label': 'Firmante:',
    'dropzone.message.upload': 'Sube tus archivos arrastrándolos a esta ventana',
    'generic.error': 'Ocurrió un error, intente de nuevo más tarde',

    'expiry.modal.title': 'Tu sesión ha expirado',
    'expiry.modal.description': 'Tu sesión ha terminado por inactivad. Por favor, vuelve a iniciar sesión para continuar.',
    'expiry.modal.button': 'Aceptar',

    'signatory.legal.btn.label': 'Continuar',
    'signatory.legal.title': 'Políticas de privacidad',
    'document.page.remind.text_1': 'No te preocupes, nosotros te recordaremos',
    'document.page.dropdown.remind': 'Recordatorio',
    'preview.remind.title': '¿Necesitas recordatorios en este documento?',
    'document.page.remind.text_2': '+ Agregar Recordatorio',
    'preview.remind.title.list': 'Recordatorios',
    'preview.remind.header.add': 'Agregar',
    'preview.remind.body.readMore': 'Leer más',
    'preview.remind.body.readLess': 'Leer menos',
    'preview.remind.next.title': 'Próximos Recordatorios',
    'preview.remind.next': 'Al parecer tienes todo en orden ¡Genial!',
    'remind.add.title': 'Crear recordatorio',
    'remind.add.from.date': 'Fecha',
    'remind.add.from.title': 'Título',
    'remind.add.from.body': 'Notas',
    'remind.add.from.exit': 'Salir',
    'remind.add.from.create': 'Crear',
    'remind.add.from.edit': 'Continuar',
    'remind.action.edit': 'Editar',
    'remind.action.delete': 'Eliminar',
    'remind.action.notify': '* Puedes editar, eliminar o esperar 24 hrs para que los recordatorios de hoy se eliminen automáticamente',
    'remind.edit.title': 'Editar Recordatorio',
    'reminder.deleteModal.title': 'Eliminar recordatorio',
    'reminder.deleteModal.bodyText': '¿Desea eliminar este recordatorio?',
    'reminder.deleteModal.confirmButtonText': 'Confirmar',

    'removeTags.confirmationModal.title': 'Eliminar etiquetas',
    'removeTags.confirmationModal.bodyText1': '¿Desea eliminar estas etiquetas ',
    'removeTags.confirmationModal.bodyText2': ' de los documentos',
    'removeTags.confirmationModal.confirmButtonText': 'Eliminar',
    'removeTags.confirmationModal.cancelButtonText': 'Cancelar',

    'freeplan.payment.notice.title': '¡Oh no!',
    'freeplan.payment.notice.title.reminder': '¡Un momento!',
    'freeplan.payment.notice.subtitle.reminder': 'el tiempo se agota',
    'freeplan.payment.notice.subtitle.expired.fragment_1': 'Tu periodo de prueba ',
    'freeplan.payment.notice.subtitle.expired.fragment_2': 'ha terminado',
    'freeplan.payment.notice.subtitle.document_limit': 'Tus documentos de prueba se han agotado',
    'freeplan.payment.notice.description': ' Actualmente solo podrás visualizar tus documentos vigentes',
    'freeplan.payment.notice.suscription.reminder': 'Suscríbete por mxn${productPrice} al mes',
    'freeplan.payment.notice.suscription.expired': '¡Renueva tu plan y disfruta de más beneficios!',
    'freeplan.payment.notice.suscription.document_limit': '¡Activa tu plan y disfruta de más beneficios!',
    'freeplan.payment.notice.timer.fragment_1': 'Te quedan ',
    'freeplan.payment.notice.timer.fragment_2': ' horas para que ',
    'freeplan.payment.notice.timer.fragment_3': 'termine tu prueba',
    'freeplan.payment.suscription.title': 'Adios papel',
    'freeplan.payment.suscription.subtitle': '¡Hola weetrust!',
    'freeplan.payment.suscription.enable.now': 'Sí, activar ahora',
    'freeplan.payment.notice.subuser': 'Informa al titular de la cuenta para realizar los cambios necesarios',

    'payment.promo.new.title': 'Adiós papel',
    'payment.promo.new.subtitle': '¡Hola weetrust!',
    'payment.promo.title': 'Gracias por confiar en nosotros',
    'payment.promo.subtitle': 'En weetrust queremos hacerte la vida aún más facil',
    'payment.promo.discount.percent': '25%',
    'payment.promo.discount.title': 'Regístrate y obtén',
    'payment.promo.discount.new.title': 'Obtén un descuento del',
    'payment.promo.discount.new.subtitle': 'en los primeros ',
    'payment.promo.discount.subtitle': 'de descuento en los primeros ',
    'payment.promo.discount.months': '4 meses',
    'payment.promo.discount.cta': 'sólo registra tu tarjeta de crédito',

    'payment.register.card.loader.title': 'Registrando tarjeta',
    'payment.register.card.success.title': '¡Tarjeta agregada exitosamente!',
    'payment.register.card.error.title': 'La tarjeta no pudo ser registrada',
    'payment.register.card.error.subtitle': 'No te preocupes, actualiza tu método de pago',
    'payment.register.card.continue.button': 'Continuar',

    'payment.process.loader.title.generic': 'Procesando actualización',
    'payment.process.loader.title': 'Procesando tu pago',
    'payment.process.loader.message.generic': 'Mientras ve a wee atrapando su cola',
    'payment.process.success.title.generic': '¡Se ha actualizado su plan!',
    'payment.process.success.title': '¡Pago exitoso!',
    'payment.process.success.message': 'Es momento de disfrutar los beneficios',
    'payment.process.error.title.generic': 'Ocurrió un error en la actualización',
    'payment.process.error.subtitle.generic': 'No te preocupes, intenta de nuevo',
    'payment.process.error.title': 'El pago no pudo ser procesado',
    'payment.process.error.subtitle': 'No te preocupes, actualiza tu método de pago',
    'payment.process.setup.loader.title': 'Procesando tu tarjeta',
    'payment.process.setup.success.title': '¡Tarjeta agregada exitosamente!',
    'payment.process.setup.error.title': 'Tu tarjeta no pudo ser procesada',
    'payment.process.setup.error.subtitle': 'No te preocupes, actualiza tu método de pago',
    'payment.process.continue.button': 'Continuar',
    'payment.suscription.caption': '* Suscríbete ahora para aplicar tu descuento y tu plan se activará automáticamente desde mxn${productPrice} al mes por 5 documentos',
    'payment.method.update.button': 'Actualizar método de pago',
    'payment.retry.link': 'Volver a intentar',
    'payment.reject': 'No, por ahora',
    'payment.card.new': 'Nueva Tarjeta',
    'payment.card.methods': '¿Cómo quieres pagar?',
    'payment.card.setup': 'Registrar tarjeta',
    'payment.card.proceed': 'Pagar',
    'payment.card.proceed.send': 'Pagar y enviar',
    'payment.card.new.option': 'Añadir nuevo método',
    'payment.complete.document': 'Tu documento se está enviando',
    'payment.process.error.persist': 'Si el error persiste contactar a un asesor',
    'payment.add.method.caption': 'Al suministrar tus datos de tarjeta, le permites a weetrust efectuar futuros cargos en tu tarjeta conforme a las condiciones estipuladas.',

    'payment.method.update.title': 'Hemos actualizado nuestro sistema de cobranza.',
    'payment.method.update.description': 'Para evitar interrupciones, por favor registra de nuevo tu método de pago.',

    'banner.suscription.cta': 'suscríbete desde mxn${productPrice} al mes',
    'banner.timer.hours': 'horas',
    'banner.timer.minutes': 'minutos',
    'banner.timer.seconds': 'segundos',
    'banner.freeplan.expiration.title': 'Sólo te quedan',
    'banner.freeplan.expiration.subtitle': 'horas de tu periodo de prueba, ',
    'banner.freeplan.info.title': 'Estás en tu periodo de prueba gratis. ',
    'banner.freeplan.info.highlight.text1': 'Restan ',
    'banner.freeplan.info.highlight.text2': ' días, ',
    'banner.freeplan.promo.title': 'Aprovecha el ',
    'banner.freeplan.promo.offer': '25% de descuento',
    'banner.freeplan.promo.time': ' en los primeros 4 meses,',
    'banner.frezzedplan.title': 'Para continuar disfrutando los beneficios, ',
    'banner.frezzedplan.action': ' actualiza tu información de pago',
    'banner.zeroplan.title': 'Su periodo de prueba ha expirado ',
    'banner.update.payment.methods.title': 'Actualiza tus métodos de pago para mantener activa tu subscripción',
    'banner.update.payment.methods.action': 'Actualizar ahora',

    'cart.product.title': 'Tu documento requiere',
    'cart.product.vid.subtitle': 'verificaciones de identidad',
    'cart.product.vid.continue': 'o enviar a firma sin ',
    'cart.product.vid.info': 'Quieres saber más sobre verificación de identidad',
    'cart.product.vid.name': 'Verificaciones de Identidad',
    'cart.product.vid.generic.name': 'productos seleccionados',
    'cart.product.total': 'Adeudo Total',
    'cart.product.available.title': 'Disponibles',
    'cart.product.available.of': 'de',
    'cart.product.available.total': 'solicitadas',
    'cart.product.cancel.title': 'Enviar a firma ',
    'cart.product.cancel.vid': 'sin verificación de identidad',
    'cart.product.whatsapp.name': 'Envío de whatsapp',
    'cart.product.whatsapp_credits.unique': 'Envío de whatsapp',
    'cart.product.whatsapp_credits': 'Envíos de whatsapp',
    'cart.product.vid_incode_pack_b.unique': 'Verificación de identidad',
    'cart.product.vid_incode_pack_b': 'Verificaciones de identidad',
    'cart.product.vid_veridoc_pack_b.unique': 'Verificación de identidad',
    'cart.product.vid_veridoc_pack_b': 'Verificaciones de identidad',
    'cart.product.attachment_credits.unique': 'Foto adjunta',
    'cart.product.attachment_credits': 'Fotos adjuntas',

    'membership.card.title': 'Membresía weetrust',
    'membership.card.subtitle': 'Suscríbete',
    'membership.card.documents': '5 Documentos',
    'membership.card.price.title': 'desde',
    'membership.card.price.period': 'al mes por 5 documentos',
    'membership.card.invoice.price.period': 'al mes',
    'membership.card.invoice.dinamic.documents': 'Consumo ilimitado',
    'membership.card.invoice.calulate.title': 'Paga al corte con base en la ',
    'membership.card.invoice.calulate.link': 'Calculadora Dinámica',
    'membership.card.invoice.register': 'Registra tu tarjeta',
    'membership.card.promo.caption': 'Aprovecha el 25% de descuento en los primeros 4 meses',

    'freezed.notice.title': '¡Tienes un Adeudo!',
    'freezed.notice.message': 'No pudimos procesar el último cobro. Actualiza la información de pago para seguir utilizando weetrust.',
    'freezed.notice.card.title': 'Adeudo Total:',
    'dinamic.membership.title': 'Estás a punto de continuar con tu 6to documento del mes',
    'dinamic.membership.message': 'Al aceptar se te cobrará con base en nuestra',
    'dinamic.membership.message.link': 'Calculadora Dinámica',
    'dinamic.membership.message.date': 'en tu fecha de corte',
    'dinamic.membership.accept.button': 'Estoy de acuerdo',
    'dinamic.membership.info.link': 'Hablar con un experto',
    'subscription.problem.notice.message': 'No es posible realizar el registro de documentos, ha habido un problema con tu cuenta',
    'subscription.problem.notice.caption.text1': 'por favor',
    'subscription.problem.notice.caption.link': 'comunícate con nosotros',
    'subscription.problem.notice.caption.text2': 'para resolverlo a la brevedad posible',
    'subscription.problem.notice.button': 'Contactar',

    'billing.list.empty': 'Aún no se han generado facturas',

    'invoice.membership.plan': 'Plan actual',
    'invoice.membership.plan.promo': 'Promoción de 4 meses con 25% de descuento activa',
    'invoice.membership.plan.dynamic': 'A partir del 6to documentos pagas por lo que usas',
    'invoice.membership.date.title': 'Próxima fecha de facturación',
    'invoice.membership.date.expire.title': 'Tu prueba finaliza el',
    'invoice.membership.cancel': 'Si deseas cancelar tu plan, por favor escríbenos',
    'invoice.membership.card.default.label': 'Predeterminada',
    'invoice.membership.card.label': 'Tarjeta de',
    'invoice.membership.card.type.credit.label': 'crédito',
    'invoice.membership.card.type.debit.label': 'débito',
    'invoice.membership.card.number.label': 'con terminación',
    'invoice.status.paid': 'Pagado',
    'invoice.status.pending': 'Pendiente',
    'invoice.status.failed': 'Fallida',
    'invoice.status.refunded': 'Reembolso',

    'plans.trial.alias': 'Periodo de prueba',
    'plans.base.alias': 'Membresía Base',
    'plans.dynamic.alias': 'Membresía Dinámica',
    'plans.zero.alias': 'Periodo de prueba terminado',
    'plans.debtor.alias': 'Membresía congelada',

    'attachments.title.add': 'Agrega una foto ',
    'attachments.title.type': 'del ',
    'attachments.title.front': 'frente ',
    'attachments.title.back': 'reverso ',
    'attachments.title.from': 'de tu ',
    'attachments.title.verify': 'Verifica la foto ',
    'attachments.instructions.title': 'Requerimientos:',
    'attachments.instructions.title.position': '*Posiciona tu cámara de manera vertical al tomar la foto',
    'attachments.instructions.title.capture': '*Asegúrate de capturar la fotografía completa y enfocada',
    'attachments.instructions.title.light': '*Asegúrate de estar bien iluminado Sin sombras ni reflejos',
    'attachments.instructions.button.continue': 'Agregar foto',
    'attachments.confirm.button.continue': 'Confirmar',
    'attachments.complete.title': '¡Listo!',
    'attachments.complete.single.description': 'tu imagen han sido adjuntada al documento',
    'attachments.complete.multiple.description': 'tus documentos han sido adjuntados al documento',
    'attachments.complete.button.continue': 'Continuar',
    'attachments.failure.title': '¡Error!',
    'attachments.failure.description': 'Ocurrio un error al subir los archivos intente de nuevo más tarde o reporte el caso en nuestro chat',
    'attachments.uploading.label' : 'Guardando adjuntos',
    'attachments.photo.id': 'documento de identidad',

    'common.or': 'o',
    'signup.confirm.title': '¡Genial, ya casi está!',
    'signup.confirm.subtitle': 'Te enviamos un correo de confirmación a:',
    'signup.confirm.resend.title': '¿Aún no recibes tu correo de confirmación? ',
    'signup.confirm.resend.link': 'Vamos a intentarlo una vez más',
    'signup.confirm.send.link': 'Espera 1 minuto y volvamos a intentarlo',
    'signup.confirm.completed.verify.title': 'Tu cuenta ha sido verificada, ',
    'signup.confirm.completed.welcome.title': 'bienvenido a weetrust',
    'signup.confirm.spam': 'Revisa que no este en la bandeja de spam',
    'signup.verify.fail.btn': 'Ingresar',
    'signup.auth.fail.title': '¡Error!',
    'signup.auth.fail.subtitle': 'Ocurrió un error al intentar ingresar',
    'signup.auth.fail.btn': 'Continuar',

    'signature.new.sign.stroke.instruction': 'Dibuja tu trazo',
    'signature.new.sign.stroke.ready':'Listo',
    'signature.new.sign.stroke.rotate': 'Gire la pantalla si desea un mayor espacio',

    'endorsements.page.title': 'Endosos',
    'endorsements.inbox.title': 'Tienes {totalEndorsements} documentos listos para endosar',
    'endorsements.inbox.message.description': 'Valida el listado de documentos, si tienes algún comentario ',
    'endorsements.inbox.message.cta': '¡háznoslo saber!',
    'endorsements.inbox.validate.cta': 'Solicitar firmas',
    'endorsements.advice.message.fragment-1': 'Tienes',
    'endorsements.advice.message.fragment-2': ' {totalEndorsements} documentos ',
    'endorsements.advice.message.fragment-3': 'listos para endosar',
    'endorsements.advice.confirm': '¡confirma y envía aquí!',
    'endorsements.table.title.name': 'Nombre',
    'endorsements.table.title.signers': 'Firmantes',
    'endorsements.table.title.endorser': 'Endosante',
    'endorsements.table.title.endorsee': 'Endosatario',
    'endorsements.table.title.documentId': 'DocumentID',
    'endorsements.table.title.status': 'Estatus',
    'endorsements.table.title.sending': 'Se han aprobado sus documentos para endoso',
    'endorsements.massive.title': 'Endosos pendientes por firmar',
    'endorsements.exist.error': 'Este documento tiene un endoso en proceso',

    'templates.feature.configuration.title': 'Funcionalidad exclusiva',
    'templates.feature.configuration.subtitle': 'Por tiempo limitado',
    'templates.upload.title': 'Arrastra un archivo word',
    'templates.upload.subtitle': 'para crear tu plantilla',
    'templates.credits.error': 'Se han agotado las plantillas disponibles.',
    'templates.credits.error.link': 'Favor de contactar al equipo.',
    'templates.header.usedTemplates': 'Plantillas creadas',
    'templates.header.creditsTemplates': 'Créditos de plantillas',
    'templates.header.newTemplate': 'Nueva plantilla',
    'templates.subheader.preview': 'Ver documento',
    'templates.subheader.form': 'Formulario',
    'templates.subheader.vars': 'Variables',
    'templates.list.empty.title': 'Vas por buen camino',
    'templates.list.empty.subtitle': 'Crea tu primer plantilla',
    'templates.list.empty.caption': 'Este documento será la base para crear tu formulario debes de personalizarla a continuación',
    'templates.config.header.title': 'Detectamos en el documento un total de:',
    'templates.config.header.vars':'Variables:',
    'templates.config.header.signers':'Firmantes:',
    'templates.config.return':'Regresar',
    'templates.config.instructions':'Por favor verifica las siguientes variables de tu plantilla',
    'templates.config.instructions.form':'Solicitud de datos personales',
    'templates.config.vars.title': 'Variable',
    'templates.config.vars.counter': 'Variable repetida',
    'templates.config.vars.type.placeholder': 'Seleccionar tipo',
    'templates.config.vars.type.text': 'Texto',
    'templates.config.vars.type.email': 'Email',
    'templates.config.vars.type.number': 'Numérico',
    'templates.config.vars.type.radio': 'Selección única',
    'templates.config.vars.type.checkbox': 'Multiselección',
    'templates.config.vars.type.combo': 'Selección única con dropdown',
    'templates.config.vars.type.combo_multiple': 'Selector múltiple',
    'templates.config.vars.type.date': 'Fecha',
    'templates.config.vars.type.time': 'Hora',
    'templates.config.vars.type.conditional': 'Condicional',
    'templates.config.vars.type.signature': 'Firma',
    'templates.config.vars.type.dynamic_table': 'Tabla dinámica',
    'templates.config.vars.label': 'Escribe tu pregunta',
    'templates.config.vars.placeholder': 'Placeholder',
    'templates.config.vars.maxLength': 'Máximo de caracteres',
    'templates.config.vars.isSignatory': 'Formulario',
    'templates.config.vars.isSignatoryForm': '¿Es quien llenará el formulario? ',
    'templates.config.vars.option': 'Ingresa una opción',
    'templates.config.vars.options_table': 'Ingresa el título para esta columna',
    'templates.config.vars.required': 'Obligatorio',
    'templates.config.vars.help': 'Añadir comentario extra',
    'templates.config.actions.options.add': 'Añadir opción',
    'templates.config.form.notice': 'Si algún elemento no es correcto necesitas corregir la plantilla y actualizarla',
    'templates.config.form.error': 'Algunos campos no están completados correctamente revise nuevamente sus datos',
    'templates.config.form.action.cancel': 'Cancelar',
    'templates.config.form.action.save': 'Guardar',
    'templates.config.form.action.continuar': 'Continuar',
    'templates.config.form.tooltip.label': 'Pregunta es el texto que solicitará la información para el llenado de la plantilla',
    'templates.config.form.tooltip.placeholder': 'Texto que aparece precargado en el campo de texto o selectores de lista',
    'templates.config.form.tooltip.maxLength': 'Número que deseas asignar para un máximo de caracteres permitidos',
    'templates.config.form.tooltip.isSignatory': 'Señala las preguntas que se harán a un firmante del documento',
    'templates.config.form.tooltip.options': 'Añade la lista de textos que serán las opciones disponibles para esta pregunta',
    'templates.config.form.tooltip.keys_table': 'Añade el título que aparecerá en la tabla de esta columna',
    'templates.config.form.tooltip.help': 'Este mensaje se mostrará como una ayuda informativa desplegable a los usuarios del formulario',
    'templates.config.form.tooltip.signatoryForm': 'Selecciona la firma a la que se solicitará llenar el formulario',
    'templates.config.form.signatory.action.cancel': 'Regresar a variables',
    'templates.config.form.signatory.action.continue': 'Continuar',
    'templates.config.form.signatory.action.save': 'Guardar',
    'templates.config.form.title': 'Actualmente así va quedando tu formulario',
    'templates.config.form.caption': '* Indica que la pregunta es obligatoria',
    'templates.edit.error': 'Ocurrió un error al intentar guardar el template. Intente de nuevo.',
    'templates.update.success': 'Se ha guardado correctamente',
    'templates.update.upload.btn': 'Subir nueva versión',
    'templates.update.document.error': 'Ocurrió un error al intentar actualizar el documento. Intente de nuevo.',
    'templates.update.header.title': 'Documento nuevo',
    'templates.update.header.subtitle':' un total de:',
    'templates.update.header.persistent':'Mismas variables:',
    'templates.update.header.new':'Variables nuevas:',
    'templates.update.header.delete':'Variables eliminadas:',
    'templates.single.preview.save.error': 'Ocurrió un error al intentar guardar el preview. Intente de nuevo.',
    'templates.massive.files.error': 'Ocurrió un error al intentar obtener el archivo de carga masiva. Intente de nuevo.',
    'templates.massive.files.upload.error': 'Ocurrió un error al intentar procesar el archivo de carga masiva. Intente de nuevo.',
    'templates.massive.documents.available.error': 'No tiene documentos disponibles para envíar',
    'templates.massive.config.documentType.alert': 'Favor de seleccionar ¿Quién firma?',
    'templates.massive.config.country.alert': 'Favor de seleccionar el País',
    'templates.massive.config.signatureType.alert': 'Favor de seleccionar el tipo de firma',
    'templates.massive.config.tag.alert': 'Favor de crear un nombre de etiqueta',
    'templates.welcome.title': 'Esta funcionalidad es exclusiva para ti',
    'templates.welcome.message': '¡Crea plantillas a partir de un documento de texto!',
    'templates.welcome.caption': 'Por tiempo limitado tendrás acceso gratuito',
    'templates.welcome.button': 'Entendido',
    'templates.processing.title': 'Estamos ordenando el papeleo',
    'templates.processing.message': 'Gracias por tu paciencia, este proceso puede demorar unos segundos',
    'templates.processing.error.message': 'Se han encontrado los siguientes errores en el Documento ',
    'templates.processing.error.example': 'ejemplo',
    'templates.processing.error.10001': 'Contiene errores al cerrar etiquetas {{ VARIABLE }',
    'templates.processing.error.10002': 'Contiene errores al abrir etiquetas { VARIABLE }}',
    'templates.processing.error.10003': 'Contiene errores como { VARIABLE }} {{ VARIABLE } o variables reservadas como {{ EMAIL }} {{ NAME }}',
    'templates.processing.error.10004': 'No se identificó el error en el documento',
    'templates.processing.error.10005': 'Las variables {{ EMAIL }} y {{ NAME }} son reservadas y no pueden utilizarse en el documento',
    'templates.processing.error.10006': 'Solamente se permite subir documentos con extensión .DOCX',
    'templates.processing.error.10007': 'No se encontraron variables en el documento, asegúrate que tenga el formato  {{ VARIABLE }}',
    'templates.processing.error.reserved': '(Las variables {{ EMAIL }} y {{ NAME }} son reservadas y no pueden utilizarse)',
    'templates.processing.error.button': 'Volver a subir archivo',
    'templates.processing.error.caption': 'Recuerda que puedes descargar nuestra ',
    'templates.processing.error.defaultTemplate': 'plantilla precargada',
    'templates.update.title': 'Actualizar documento',
    'templates.update.version': 'Versión nueva',
    'templates.update.cancel': 'Cancelar',
    'templates.update.confirm': 'Actualizar',
    'templates.update.caption': 'Este nuevo documento sustituirá la versión actual de la plantilla',
    'templates.delete.title': 'Eliminar plantilla',
    'templates.delete.message': '¿Estás seguro de eliminar la plantilla ',
    'templates.delete.cancel': 'No, cancelar',
    'templates.delete.confirm': 'Sí, eliminar',
    'templates.delete.success': 'Se ha eliminado la plantilla',
    'templates.completed.title': '¡Muy bien!',
    'templates.completed.message': 'tu plantilla esta disponible para usarse',
    'templates.completed.button': 'Continuar',
    'templates.card.default.title': 'Plantilla precargada',
    'templates.card.menu.edit': 'Editar variables',
    'templates.card.menu.update': 'Actualizar documento',
    'templates.card.menu.delete': 'Eliminar',
    'templates.card.menu.preview': 'Preview',
    'templates.card.instructions': 'Elige el tipo de envío que realizarás con tu plantilla',
    'templates.card.massive': 'Masivo',
    'templates.card.options.or': 'ó',
    'templates.card.single': 'Individual',
    'templates.card.massive.title': 'Generación masiva',
    'templates.card.massive.subtitle': 'Ahora es muy fácil generar múltiples documentos a la vez',
    'templates.card.massive.instructions.text1': 'Sólo ',
    'templates.card.massive.instructions.text2': 'descarga el formato base',
    'templates.card.massive.instructions.text3': ' para ingreso de información al proceso masivo, ',
    'templates.card.massive.instructions.text4': 'sube y ¡envía al proceso de firma!',
    'templates.card.massive.download': 'Descargar',
    'templates.card.massive.upload': 'Subir',
    'templates.card.default.name': 'Solicitud de datos personales',
    'templates.single.form.continue': 'Continuar',
    'templates.single.error': 'Ocurrió un error al intentar obtener el template. Intente de nuevo.',
    'templates.upload.invalid_type': 'El tipo de archivo deber ser un .doc o .docx',

    'personal.data.name': 'Nombre',
    'personal.data.email': 'Email',

    'comment.read.more': 'Leer más',
    'signatory.read.more': 'Ver Verificación de Identidad',
    'comment.read.less': 'Leer menos',

    'vobo.get.service.error': 'No se encontró el documento de visto bueno',
    'vobo.get.pdf.service.error': 'Ocurrió un error al cargar el pdf. Intente de nuevo.',
    'vobo.button.accept': 'Aprobar',
    'vobo.button.reject': 'Rechazar',
    'vobo.button.continue': 'Continuar',
    'vobo.button.send': 'Enviar',
    'vobo.accepted.title': 'Este documento ha sido aprobado',
    'vobo.accepted.description': 'Enviaremos un correo electrónico notificando que este documento ha sido aprobado ¡Gracias!',
    'vobo.accepted.service.error': 'Ocurrió un error al intentar aprobar el documento. Intente de nuevo.',
    'vobo.refused.title': 'Este documento ha sido rechazado',
    'vobo.refused.description': 'Enviaremos un correo electrónico notificando que este documento ha sido rechazado ¡Gracias!',
    'vobo.refused.description.commented': 'Una vez que des clic en "Enviar", enviaremos un correo electrónico al creador del documento notificándole que ha sido rechazado y tiene comentarios.',
    'vobo.refused.notification.title': 'Antes de notificar que has rechazado este documento',
    'vobo.refused.notification.description': '¿Deseas añadir comentarios?',
    'vobo.refused.notification.simple': 'No',
    'vobo.refused.notification.comments': 'Sí, comentar',
    'vobo.refused.service.error': 'Ocurrió un error al intentar rechazar el documento. Intente de nuevo.',
    'vobo.signatories.title': 'Firmantes',
    'vobo.comments.title': 'Comentarios',
    'vobo.comments.add': 'Agregar',
    'vobo.comments.add.title': 'Comentario',
    'vobo.comments.add.form.label.title': 'Título',
    'vobo.comments.add.form.label.notes': 'Notas',
    'vobo.comments.add.cancel.button': 'Cancelar',
    'vobo.comments.add.create.button': 'Crear',
    'vobo.comments.add.update.button': 'Actualizar',
    'vobo.comments.add.tooltip': 'Describe un título para señalar donde harás tu comentario, ejemplo: Título: "Ajustes en párrafo 5to" y en "Notas" tu comentario final',
    'vobo.comments.add.error': 'El título y notas son requeridas',
    'vobo.comments.add.service.error': 'Ocurrió un error al intentar crear el comentario. Intente de nuevo.',
    'vobo.comments.update.service.error': 'Ocurrió un error al intentar actualizar el comentario. Intente de nuevo.',
    'vobo.comments.delete.service.error': 'Ocurrió un error al intentar eliminar el comentario. Intente de nuevo.',
    'vobo.comments.delete.title': 'Eliminar comentario',
    'vobo.comments.delete.description': '¿Deseas eliminar este comentario?',
    'vobo.comments.delete.button': 'Confirmar',
    'vobo.document.status.title': 'Estado',
    'vobo.document.status.pending': 'Pendiente',
    'vobo.document.status.refused': 'Rechazado',
    'vobo.document.notify.refused.title': 'No te preocupes, tu documento aquí no termina',
    'vobo.document.notify.refused.description': 'Se mantendrá en pendiente dentro de la plataforma con estado de:',
    'vobo.document.notify.refused.status': 'Rechazado',
    'vobo.document.notify.refused.button': 'Entendido',
    'vobo.signatory.notify.refused': 'Este documento ha sido rechazado por lo que este enlace de firma se encuentra suspendido',

    'document.cancel.request.title': 'Has solicitado la cancelación del siguiente documento:',
    'document.cancel.request.upload.title': 'Adjunta cualquier evidencia que tengas para solicitar la cancelación',
    'document.cancel.request.upload.subtitle': 'Arrastra uno o múltiples archivos',
    'document.cancel.request.upload.caption': 'El límite de peso del archivo es de 20 Mb',
    'document.cancel.request.upload.link': 'Añade más archivos',
    'document.cancel.request.cancel': 'Cancelar',
    'document.cancel.request.confirm': 'Enviar',
    'document.cancel.pdf.service.error': 'Ocurrió un error al cargar el pdf. Intente de nuevo.',
    'document.cancel.send.service.error': 'Ocurrió un error al enviar la solicitud. Intente de nuevo.',
    'document.cancel.send.empty.error': 'Se tiene que subir un documento antes de continuar.',
    'document.cancel.send.service.success': 'La solicitud de cancelación ha sido enviada correctamente',
    'document.cancel.upload.images.error': 'Ocurrió un error al intentar subir la imagen',
    'document.cancel.review.title': 'Solicitud de cancelación',
    'document.cancel.review.user': 'Nombre y correo del solicitante:',
    'document.cancel.review.file': 'Nombre del archivo:',
    'document.cancel.review.evidence.title': 'Evidencias',
    'document.cancel.review.evidence.message.total': 'Hay un total de',
    'document.cancel.review.evidence.message.documents': 'documentos como evidencia',
    'document.cancel.review.reject': 'Rechazar',
    'document.cancel.review.confirm': 'Autorizar',
    'document.cancel.review.confirm.dialog.title': 'Confirmación de solicitud',
    'document.cancel.review.confirm.dialog.description': 'Has tomado la decisión de autorizar y procesar esta solicitud de cancelación',
    'document.cancel.review.confirm.dialog.cancel': 'No, cancelar',
    'document.cancel.review.confirm.dialog.proceed': 'Sí, confirmar',
    'document.cancel.review.reject.dialog.title': 'Rechazo de solicitud',
    'document.cancel.review.reject.dialog.description': 'Has tomado la decisión de rechazar y no procesar esta solicitud de cancelación',
    'document.cancel.review.reject.dialog.cancel': 'No, cancelar',
    'document.cancel.review.reject.dialog.proceed': 'Sí, rechazar',
    'document.cancel.evidence.maxsize.error': 'El tamaño del archivo no puede exceder los 20MB',
    'document.cancel.evidence.type.error': 'El tipo de archivo deber ser un .png o .jpeg',
    'document.cancel.evidence.pdf.type.error': 'El tipo de archivo deber ser un .pdf',
    'document.cancel.evidence.upload.error': 'No podemos procesar tu archivo. ¡Inténtalo de nuevo!',
    'document.cancel.not_found': 'No se encontró información de la cancelación',
    'document.cancel.review.already': 'La solicitud de cancelación ya fue respondida',
    'document.cancel.review.evidence.empty': 'No se encontró evidencia para esta cancelación',
    'document.cancel.review.error': 'No se logró enviar la resolución de cancelación. ¡Inténtalo de nuevo!',
    'document.cancel.review.success.proceed': 'El documento se ha cancelado correctamente',
    'document.cancel.review.success.refuse': 'El documento se ha rechazado para su cancelación',
    'document.cancel.exist.error': 'Ya existe una cancelación en proceso para este documento',

    'document.whatsapp.credits.request.subtitle': 'Te has quedado sin créditos disponibles',
    'document.whatsapp.credits.request.description': 'Lo sentimos, parece que has agotado tus créditos para enviar solicitudes de firma vía whatsapp. No te preocupes, estamos aquí para ayudarte a recargar y seguir conectado con tus clientes.',
    'document.whatsapp.credits.title': 'Mensajes vía ',
    'document.whatsapp.credits.subtitle-1': 'A partir de ahora puedes solicitar firmas vía whatsapp por una ',
    'document.whatsapp.credits.subtitle-2': 'tarifa de ',
    'document.whatsapp.credits.subtitle-3': ', que se cobrara en tu siguiente factura',
    'document.whatsapp.credits.btn': 'Entendido',
    'document.whatsapp.dynamic.credits.subtitle-1': 'Has solicitado ',
    'document.whatsapp.dynamic.credits.subtitle-2': ' firma vía WhatsApp la cual tiene un costo de ',
    'document.whatsapp.dynamic.credits.subtitle-3': ' por solicitud, que serán agregados en tu cuenta a final de mes',
    'document.whatsapp.dynamic.credits.check': 'Estoy de acuerdo, no mostrar nuevamente',
    'document.credits.request.title': '¡Oops!',
    'document.contact.credits.request.btn': 'Contactar a un asesor',
    'document.attachments.credits.request.subtitle': 'Te has quedado sin créditos disponibles',
    'document.attachments.credits.request.description': 'Lo sentimos, parece que has agotado tus créditos para enviar solicitudes de adjuntos. No te preocupes, estamos aquí para ayudarte a recargar y seguir conectado con tus clientes.',
    'document.attachments.credits.title': 'Fotos adjuntas',
    'document.attachments.dynamic.credits.subtitle-1': 'Has solicitado ',
    'document.attachments.dynamic.credits.subtitle-2': ' adjuntos con un costo de ',
    'document.attachments.dynamic.credits.subtitle-3': ' por solicitud, que serán agregados en tu cuenta a final de mes',
    'document.attachments.dynamic.credits.check': 'Estoy de acuerdo, no mostrar nuevamente',
    'document.attachments.credits.btn': 'Entendido',

    'attachments.config.button': 'Fotos adjuntas',
    'attachments.config.button.tooltip': 'Solicita fotografías de documento oficial (INE o Licencia de conducir), de Comprobante de domicilio u otro.',
    'attachments.config.add.button': 'Solicitar otra',
    'attachments.config.add.limit': 'Máximo {limit} fotos',
    'attachments.config.add.placeholder': 'INE, Comprobante de ..., Licencia de ...',

    'document.restricted.dialog.title': 'No tienes permisos para solicitar firma de documentos',
    'document.restricted.dialog.description': 'por favor contacta al administrador de tu cuenta',
    'access.restricted.dialog.title': 'No tienes permisos para acceder a este módulo',
    'access.restricted.dialog.description': 'por favor contacta al administrador de tu cuenta',

    'endorsement.special.request.title': 'Has solicitado el endoso especial del siguiente documento:',
    'endorsement.special.request.upload.title': 'Arrastra aquí el archivo del nuevo contrato',
    'endorsement.special.request.page': 'Página de factura',
    'endorsement.special.request.cancel': 'Cancelar',
    'endorsement.special.request.continue': 'Continuar',
  },
};
